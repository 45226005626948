/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Cookies from 'universal-cookie';

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import Login from './Login';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import Admin from './Admin';
import MySnack from './MySnack';
import MyLoading from './MyLoading';
import ComplaintForm from './Complaint/ComplaintForm';
import ComplaintComplete from './Complaint/ComplaintComplete';

const cookies = new Cookies();
const PrvRoute = prop => (
  <div>
    <Route
      path="/"
      render={props =>
        cookies.get('token') ? (
          <Admin {...prop} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  </div>
);

export default function MyRouter(greaterProps) {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/login"
          render={props => (
            <Login query={greaterProps.client.query} {...props} />
          )}
        />
        <Route
          exact
          path="/signup"
          render={props => (
            <SignUp mutate={greaterProps.client.mutate} {...props} />
          )}
        />
        <Route
          exact
          path="/forgotpassword"
          render={props => (
            <ForgotPassword
              mutate={greaterProps.client.mutate}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/complaint"
          render={props => (
            <ComplaintForm
              mutate={greaterProps.client.mutate}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/complaint/thankyou"
          render={props => <ComplaintComplete {...props} />}
        />
        <PrvRoute
          query={greaterProps.client.query}
          mutate={greaterProps.client.mutate}
          title={greaterProps.title}
          language={greaterProps.language}
          isDetailPage={greaterProps.isDetailPage}
        />
      </Switch>
      <MySnack {...greaterProps.snack} />
      {greaterProps.globalLoading && <MyLoading />}
    </Router>
  );
}
