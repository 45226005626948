/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useContext, useState } from 'react';
import {
  Grid,
  Paper,
  TextField,
  // Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Tooltip,
  Button,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AsyncSelect from 'react-select/async';

import { globalStyles, monitoringPage } from '../../styles';
import ErrorText from '../ErrorText';
import GlobalContext from '../../globalContext';
import {
  SEARCH_USERS,
  GET_USER_DETAIL,
} from '../../graphql/UserQuery';
import { CREATE_MONITORING } from '../../graphql/MonitoringQuery';
import TpsDialog from '../Custom/TpsDialog';

const MonitoringForm = props => {
  const classes = monitoringPage();
  const globalClasses = globalStyles();
  const { history, query, mutate, language } = props;
  const { setSnack, setGlobalLoading, setTitle } = useContext(
    GlobalContext,
  );
  const [userDetail, setUserDetail] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTitle(language.monitoring.form.title);
  }, [setTitle, language.monitoring.form.title]);

  const handleUserDetail = () => {
    setOpen(true);
  };

  const getUsers = async ({ name }) => {
    const {
      data: { searchUsers },
    } = await query({
      query: SEARCH_USERS,
      variables: { name, position: 'CUSTOMER' },
      fetchPolicy: 'no-cache',
    });

    const options = searchUsers.map(l => ({
      value: l.id,
      label: l.name,
    }));

    return options;
  };

  const getMonitors = async ({ name }) => {
    const {
      data: { searchUsers },
    } = await query({
      query: SEARCH_USERS,
      variables: { name, position: 'MONITOR' },
      fetchPolicy: 'no-cache',
    });

    const options = searchUsers.map(l => ({
      value: l.id,
      label: `${l.name} - ${l.position}`,
    }));

    return options;
  };

  const getDetailUser = async id => {
    const {
      data: { findUserById },
    } = await query({
      query: GET_USER_DETAIL,
      variables: { id },
      fetchPolicy: 'no-cache',
    });
    setUserDetail(findUserById);
  };

  const loadOptions = async (inputValue, type) => {
    if (type === 'customer') {
      return getUsers({
        name: inputValue,
      });
    }

    if (type === 'monitor') {
      return getMonitors({
        name: inputValue,
      });
    }

    return null;
  };

  const handleInputChange = (newValue, type) => {
    if (type === 'customer') {
      const inputValue = newValue.replace(/\W/g, '');
      return inputValue;
    }

    if (type === 'monitor') {
      const inputValue = newValue.replace(/\W/g, '');
      return inputValue;
    }

    return null;
  };

  return (
    <div>
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Formik
            initialValues={{
              customer: '',
              monitorBy: '',
              lane: '',
              otherLane: '',
              quality: '',
              organic: '',
              recycle: '',
              residue: '',
              intervention: '',
              notes: '',
              seperationDate: new Date().toISOString().slice(0, 10),
              currentDate: new Date().toISOString().slice(0, 10),
            }}
            validationSchema={Yup.object().shape({
              customer: Yup.string().required(
                language.validate.required,
              ),
              monitorBy: Yup.string().required(
                language.validate.required,
              ),
              lane: Yup.string().required(language.validate.required),
              otherLane: Yup.string().when('lane', {
                is: lane => lane === '99',
                then: Yup.string().required(
                  language.validate.required,
                ),
              }),
              quality: Yup.string().required(
                language.validate.required,
              ),
              organic: Yup.string().required(
                language.validate.required,
              ),
              recycle: Yup.string().required(
                language.validate.required,
              ),
              residue: Yup.string().required(
                language.validate.required,
              ),
              seperationDate: Yup.string().required(
                language.validate.required,
              ),
            })}
            onSubmit={async (
              {
                customer,
                monitorBy,
                quality,
                organic,
                recycle,
                residue,
                lane,
                otherLane,
                intervention,
                notes,
                seperationDate,
              },
              { resetForm },
            ) => {
              try {
                const data = {
                  user_id: parseInt(customer.value, 10),
                  worker_id: parseInt(monitorBy.value, 10),
                  quality: parseInt(quality, 10),
                  organic: parseInt(organic, 10),
                  recycle: parseInt(recycle, 10),
                  residu: parseInt(residue, 10),
                  lane:
                    lane !== '99'
                      ? parseInt(lane, 10)
                      : parseInt(otherLane, 10),
                  intervention,
                  notes,
                  seperation_date: seperationDate,
                };

                await mutate({
                  mutation: CREATE_MONITORING,
                  variables: { data },
                  fetchPolicy: 'no-cache',
                  onError: error => {
                    console.log('==> Error execute mutation', error);
                  },
                });
                setGlobalLoading(true);
                setSnack({
                  variant: 'success',
                  message: `${language.monitoring.form.title} ${customer.label} ${language.alert.successSave}`,
                  opened: true,
                });
                resetForm();
                setGlobalLoading(false);
                history.replace('/monitoring/new');
              } catch (error) {
                setGlobalLoading(false);
                setSnack({
                  variant: 'error',
                  message: `${
                    language.alert.errorSave
                  } ${language.monitoring.form.title.toLowerCase()}`,
                  opened: true,
                });
              }
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              values,
              handleChange,
              setFieldValue,
            }) => (
              <form noValidate>
                <Paper className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <TextField
                        margin="normal"
                        fullWidth
                        required
                        id="date"
                        label={
                          language.monitoring.form.seperationDate
                        }
                        type="date"
                        name="seperationDate"
                        autoComplete="seperationDate"
                        onChange={handleChange}
                        value={values.seperationDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {errors.seperationDate &&
                        touched.seperationDate && (
                          <ErrorText text={errors.seperationDate} />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <TextField
                        margin="normal"
                        fullWidth
                        required
                        id="currentDate"
                        label={language.monitoring.form.currentDate}
                        type="date"
                        name="currentDate"
                        value={values.currentDate}
                        InputLabelProps={{
                          shrink: true,
                          disabled: true,
                        }}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel id="lane">
                          {language.monitoring.form.lane}
                        </InputLabel>
                        <Select
                          margin="normal"
                          fullWidth
                          required
                          labelId="lane"
                          id="lane"
                          name="lane"
                          value={values.lane}
                          onChange={e => {
                            setFieldValue('lane', e.target.value);
                          }}
                        >
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                          <MenuItem value="4">4</MenuItem>
                          <MenuItem value="5">5</MenuItem>
                          <MenuItem value="6">6</MenuItem>
                          <MenuItem value="7">7</MenuItem>
                          <MenuItem value="8">8</MenuItem>
                          <MenuItem value="9">9</MenuItem>
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="99">Lainnya</MenuItem>
                        </Select>
                      </FormControl>
                      {errors.lane && touched.lane && (
                        <ErrorText text={errors.lane} />
                      )}
                    </Grid>
                    {values.lane === '99' && (
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="otherLane"
                          label={language.customer.form.lane.other}
                          name="otherLane"
                          onChange={handleChange}
                          value={values.otherLane}
                        />
                        {errors.otherLane && touched.otherLane && (
                          <ErrorText text={errors.otherLane} />
                        )}
                      </Grid>
                    )}
                    <Grid item xs={10} sm={10} md={10} lg={10}>
                      <InputLabel
                        shrink
                        className={classes.labelAsyncSelect}
                      >
                        {language.monitoring.form.customerName} *
                      </InputLabel>

                      <AsyncSelect
                        id="customer"
                        margin="normal"
                        name="customer"
                        placeholder={
                          language.monitoring.form.customerName
                        }
                        value={values.customer}
                        loadOptions={e => loadOptions(e, 'customer')}
                        defaultOptions
                        onInputChange={e =>
                          handleInputChange(e, 'customer')
                        }
                        onChange={o => {
                          setFieldValue('customer', o);
                          getDetailUser(o.value);
                        }}
                      />
                      {errors.customer && touched.customer && (
                        <ErrorText text={errors.customer} />
                      )}
                    </Grid>
                    {userDetail && userDetail.name ? (
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        style={{
                          marginTop: '8px',
                          textAlign: 'center',
                        }}
                      >
                        <Tooltip
                          title={language.income.customer.detail}
                        >
                          <IconButton
                            aria-label="delete"
                            onClick={handleUserDetail}
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    ) : null}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <InputLabel
                        shrink
                        className={classes.labelAsyncSelect}
                      >
                        {language.monitoring.form.monitorBy} *
                      </InputLabel>

                      <AsyncSelect
                        id="monitorBy"
                        margin="normal"
                        name="monitorBy"
                        placeholder={
                          language.monitoring.form.monitorBy
                        }
                        value={values.monitorBy}
                        loadOptions={e => loadOptions(e, 'monitor')}
                        defaultOptions
                        onInputChange={e =>
                          handleInputChange(e, 'monitor')
                        }
                        onChange={o => {
                          setFieldValue('monitorBy', o);
                        }}
                      />
                      {errors.monitorBy && touched.monitorBy && (
                        <ErrorText text={errors.monitorBy} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel id="quality">
                          {language.monitoring.form.quality}
                        </InputLabel>
                        <Select
                          margin="dense"
                          fullWidth
                          required
                          labelId="Quality"
                          id="quality"
                          name="quality"
                          value={values.quality}
                          onChange={handleChange}
                        >
                          <MenuItem value="0">
                            0: Tidak ada sampah
                          </MenuItem>
                          <MenuItem value="1">
                            1: Sampah tidak terpilah
                          </MenuItem>
                          <MenuItem value="2">
                            2: Mencoba memilah
                          </MenuItem>
                          <MenuItem value="3">
                            3: Sampah sudah terpilah sesuai ekspetasi
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {errors.quality && touched.quality && (
                        <ErrorText text={errors.quality} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel id="organic">
                          {language.monitoring.form.organic}
                        </InputLabel>
                        <Select
                          margin="dense"
                          fullWidth
                          required
                          labelId="Organic"
                          id="organic"
                          name="organic"
                          value={values.organic}
                          onChange={handleChange}
                        >
                          <MenuItem value="0">0: None</MenuItem>
                          <MenuItem value="1">1: Mix</MenuItem>
                          <MenuItem value="2">2: Try</MenuItem>
                          <MenuItem value="3">3: Succeed</MenuItem>
                        </Select>
                      </FormControl>
                      {errors.organic && touched.organic && (
                        <ErrorText text={errors.organic} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel id="recycle">
                          {language.monitoring.form.recycle}
                        </InputLabel>
                        <Select
                          margin="dense"
                          fullWidth
                          required
                          labelId="Recycle"
                          id="recycle"
                          name="recycle"
                          value={values.recycle}
                          onChange={handleChange}
                        >
                          <MenuItem value="0">0: None</MenuItem>
                          <MenuItem value="1">1: Mix</MenuItem>
                          <MenuItem value="2">2: Try</MenuItem>
                          <MenuItem value="3">3: Succeed</MenuItem>
                        </Select>
                      </FormControl>
                      {errors.recycle && touched.recycle && (
                        <ErrorText text={errors.recycle} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel id="recycle">
                          {language.monitoring.form.residue}
                        </InputLabel>
                        <Select
                          margin="dense"
                          fullWidth
                          required
                          labelId="Residue"
                          id="residue"
                          name="residue"
                          value={values.residue}
                          onChange={handleChange}
                        >
                          <MenuItem value="0">0: None</MenuItem>
                          <MenuItem value="1">1: Mix</MenuItem>
                          <MenuItem value="2">2: Try</MenuItem>
                          <MenuItem value="3">3: Succeed</MenuItem>
                        </Select>
                      </FormControl>
                      {errors.residue && touched.residue && (
                        <ErrorText text={errors.residue} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="intervention"
                        label={language.monitoring.form.intervention}
                        name="intervention"
                        onChange={handleChange}
                        value={values.intervention}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        margin="normal"
                        fullWidth
                        multiline
                        rows={5}
                        id="notes"
                        label={language.monitoring.form.notes}
                        type="notes"
                        name="notes"
                        onChange={handleChange}
                        value={values.notes}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="baseline"
                  >
                    <Button
                      className={globalClasses.saveButton}
                      onClick={handleSubmit}
                      variant="contained"
                    >
                      {language.action.save}
                    </Button>
                  </Grid>
                </Paper>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <TpsDialog
        data={userDetail}
        open={open}
        language={language}
        onClose={() => {
          setOpen(false);
        }}
        type="monitoringForm"
      />
    </div>
  );
};

export default MonitoringForm;
