import gql from 'graphql-tag';

export const GET_BILLINGS = gql`
  query billings {
    billings {
      id
      uuid
      user {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
      worker {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
      periode
      payment_total
      image
      notes
      payment_at
      created_at
      updated_at
    }
  }
`;

export const CREATE_BILLING = gql`
  mutation createBilling($data: InBilling!) {
    createBilling(data: $data) {
      id
      uuid
      user {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
      worker {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
      periode
      payment_total
      image
      notes
      payment_at
      created_at
      updated_at
    }
  }
`;

export const ADD_IMAGE_TO_BILLING = gql`
  mutation addImageToBilling(
    $id: ID!
    $image: Upload!
    $file_name: String!
  ) {
    addImageToBilling(id: $id, image: $image, file_name: $file_name) {
      id
      uuid
      user {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
      worker {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
      periode
      payment_total
      image
      notes
      payment_at
      created_at
      updated_at
    }
  }
`;

export const GET_BILLINGS_USER = gql`
  query findBillingByUser($user_id: ID!) {
    findBillingByUser(user_id: $user_id) {
      id
      uuid
      user {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        lane
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
      worker {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
      periode
      payment_total
      image
      notes
      payment_at
      created_at
      updated_at
    }
  }
`;

export const GET_TOTAL_BILLINGS_USER = gql`
  query totalBillingUser($user_id: ID!) {
    totalBillingUser(user_id: $user_id) {
      count
    }
  }
`;
