import React, { useState } from 'react';
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  Box,
  Tabs,
  Tab,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import moment from 'moment-timezone';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

const CustomerPaymentDialog = props => {
  const { open, onClose, data, language } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ width: 500 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h6">
                {language.customer.detail.payment.detail}
              </Typography>
              <Typography variant="subtitle2">
                #{data.uuid}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.payment.customer}
                  </TableCell>
                  <TableCell>
                    {data.user ? data.user.name : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.payment.worker}
                  </TableCell>
                  <TableCell>
                    {data.worker ? data.worker.name : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.summary.lane}
                  </TableCell>
                  <TableCell>
                    {data.user ? data.user.lane : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.payment.periode}
                  </TableCell>
                  <TableCell>{data.periode}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.payment.paymentTotal}
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={data.payment_total}
                      thousandSeparator
                      displayType="text"
                      prefix="Rp. "
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.payment.paymentAt}
                  </TableCell>
                  <TableCell>
                    {moment(data.payment_at).format(
                      'dddd, DD-MM-YYYY',
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.payment.createdAt}
                  </TableCell>
                  <TableCell>
                    {moment(data.created_at).format(
                      'dddd, DD-MM-YYYY',
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.payment.notes}
                  </TableCell>
                  <TableCell>{data.notes}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {language.action.back}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const CustomerMonitoringDialog = props => {
  const { open, onClose, data, language } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ width: 500 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h6">
                {language.customer.detail.monitoring.detail}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.monitoring.customer}
                  </TableCell>
                  <TableCell>
                    {data.user ? data.user.name : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.monitoring.worker}
                  </TableCell>
                  <TableCell>
                    {data.worker ? data.worker.name : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.monitoring.quality}
                  </TableCell>
                  <TableCell>
                    {data.quality}
                    {' : '}
                    {data.quality === 0
                      ? 'Tidak ada sampah'
                      : data.quality === 1
                      ? 'Sampah tidak terpilah'
                      : data.quality === 2
                      ? 'Mencoba memilah'
                      : data.quality === 3
                      ? 'Sampah sudah terpilah sesuai ekspektasi'
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.monitoring.organic}
                  </TableCell>
                  <TableCell>
                    {data.organic}
                    {' : '}
                    {data.organic === 0
                      ? 'None'
                      : data.organic === 1
                      ? 'Mix'
                      : data.organic === 2
                      ? 'Try'
                      : data.organic === 3
                      ? 'Succeed'
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.monitoring.recycle}
                  </TableCell>
                  <TableCell>
                    {data.recycle}
                    {' : '}
                    {data.recycle === 0
                      ? 'None'
                      : data.recycle === 1
                      ? 'Mix'
                      : data.recycle === 2
                      ? 'Try'
                      : data.recycle === 3
                      ? 'Succeed'
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.monitoring.residue}
                  </TableCell>
                  <TableCell>
                    {data.residu}
                    {' : '}
                    {data.residu === 0
                      ? 'None'
                      : data.residu === 1
                      ? 'Mix'
                      : data.residu === 2
                      ? 'Try'
                      : data.residu === 3
                      ? 'Succeed'
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.monitoring.intervention}
                  </TableCell>
                  <TableCell>{data.intervention}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.monitoring.notes}
                  </TableCell>
                  <TableCell>{data.notes}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {
                      language.customer.detail.monitoring
                        .seperationDate
                    }
                  </TableCell>
                  <TableCell>
                    {moment(data.seperation_date).format(
                      'dddd, DD-MM-YYYY',
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.monitoring.createdAt}
                  </TableCell>
                  <TableCell>
                    {moment(data.created_at).format(
                      'dddd, DD-MM-YYYY',
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {language.action.back}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const CustomerPaymentFormDialog = props => {
  const {
    open,
    onClose,
    data,
    language,
    value,
    handleChange,
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ width: 500 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h6">
                {language.income.customer.modalDialog.title}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
              style={{ borderBottom: '1px solid #e8e8e8' }}
            >
              <Tab
                label={language.income.customer.modalDialog.userInfo}
              />
              <Tab
                label={
                  language.income.customer.modalDialog.paymentInfo
                }
              />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {language.income.customer.modalDialog.name}
                    </TableCell>
                    <TableCell>{data.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {language.income.customer.modalDialog.uuid}
                    </TableCell>
                    <TableCell>{data.uuid}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {language.income.customer.modalDialog.email}
                    </TableCell>
                    <TableCell>{data.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {language.income.customer.modalDialog.category}
                    </TableCell>
                    <TableCell>{data.category}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {language.income.customer.modalDialog.size}
                    </TableCell>
                    <TableCell>{data.size}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {language.income.customer.modalDialog.lane}
                    </TableCell>
                    <TableCell>{data.lane}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {
                        language.income.customer.modalDialog
                          .serviceFee
                      }
                    </TableCell>
                    <TableCell>
                      <NumberFormat
                        value={data.service_fee}
                        thousandSeparator
                        displayType="text"
                        prefix="Rp. "
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {
                        language.income.customer.modalDialog
                          .registrationDate
                      }
                    </TableCell>
                    <TableCell>
                      {moment(data.registration_date).format(
                        'dddd, DD-MM-YYYY',
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {language.income.customer.modalDialog.createdAt}
                    </TableCell>
                    <TableCell>
                      {moment(data.created_at).format(
                        'dddd, DD-MM-YYYY',
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {language.customer.detail.payment.notes}
                    </TableCell>
                    <TableCell>{data.notes}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {
                        language.income.customer.modalDialog.payment
                          .periode
                      }
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {
                        language.income.customer.modalDialog.payment
                          .total
                      }
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {
                        language.income.customer.modalDialog.payment
                          .paymentAt
                      }
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {
                        language.income.customer.modalDialog.payment
                          .createdAt
                      }
                    </TableCell>
                  </TableRow>
                  {data.billing && data.billing.length ? (
                    data.billing.map(x => (
                      <TableRow>
                        <TableCell key={x.id}>{x.periode}</TableCell>
                        <TableCell key={x.id}>
                          <NumberFormat
                            value={x.payment_total}
                            thousandSeparator
                            displayType="text"
                            prefix="Rp. "
                          />
                        </TableCell>
                        <TableCell key={x.id}>
                          {moment(x.payment_at).format(
                            'dddd, DD-MM-YYYY',
                          )}
                        </TableCell>
                        <TableCell key={x.id}>
                          {moment(x.created_at).format(
                            'dddd, DD-MM-YYYY',
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <h6>{language.data.notFound}</h6>
                  )}
                </TableBody>
              </Table>
            </TabPanel>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {language.action.back}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const MonitoringPaymentFormDialog = props => {
  const { open, onClose, data, language } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ width: 500 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h6">
                {language.income.customer.modalDialog.title}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.income.customer.modalDialog.name}
                  </TableCell>
                  <TableCell>{data.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.income.customer.modalDialog.uuid}
                  </TableCell>
                  <TableCell>{data.uuid}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.income.customer.modalDialog.email}
                  </TableCell>
                  <TableCell>{data.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.income.customer.modalDialog.category}
                  </TableCell>
                  <TableCell>{data.category}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.income.customer.modalDialog.size}
                  </TableCell>
                  <TableCell>{data.size}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.income.customer.modalDialog.lane}
                  </TableCell>
                  <TableCell>{data.lane}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.income.customer.modalDialog.serviceFee}
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={data.service_fee}
                      thousandSeparator
                      displayType="text"
                      prefix="Rp. "
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {
                      language.income.customer.modalDialog
                        .registrationDate
                    }
                  </TableCell>
                  <TableCell>
                    {moment(data.registration_date).format(
                      'dddd, DD-MM-YYYY',
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.income.customer.modalDialog.createdAt}
                  </TableCell>
                  <TableCell>
                    {moment(data.created_at).format(
                      'dddd, DD-MM-YYYY',
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.customer.detail.payment.notes}
                  </TableCell>
                  <TableCell>{data.notes}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {language.action.back}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DefaultDialog = props => {
  const { open, onClose, data, language } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ width: 500 }}>
          <Grid container spacing={2}>
            <Grid item xs={8} />
            <Grid item xs={4}>
              <Chip
                color="primary"
                label="Detail Pembayaran"
                style={{ float: 'right' }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table
              // className={classes.table}
              aria-label="simple table"
            >
              <TableBody>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.income.customer.modalDialog.name}
                  </TableCell>
                  <TableCell>{data.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.income.customer.modalDialog.category}
                  </TableCell>
                  <TableCell>{data.category}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.income.customer.modalDialog.size}
                  </TableCell>
                  <TableCell>{data.size}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.income.customer.modalDialog.lane}
                  </TableCell>
                  <TableCell>{data.lane}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.income.customer.modalDialog.serviceFee}
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={data.service_fee}
                      thousandSeparator
                      displayType="text"
                      prefix="Rp. "
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {language.income.customer.modalDialog.notes}
                  </TableCell>
                  <TableCell>{data.notes}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContentText>
          <div>
            <img
              src="https://picsum.photos/200"
              alt="ktp"
              style={{
                width: 80,
                height: 120,
                borderRadius: 20,
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Kembali
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DetailBarchartRecyclable = props => {
  const { open, onClose, data } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ width: 500 }}>
          Detail
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              style={{
                paddingBottom: '56.25%' /* 16:9 */,
                position: 'relative',
                height: 0,
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                }}
              >
                <ResponsiveContainer>
                  <BarChart
                    width={800}
                    height={300}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip
                      formatter={value =>
                        `Rp. ${new Intl.NumberFormat('en').format(
                          value,
                        )}`
                      }
                    />
                    <Legend />
                    <Bar dataKey="total" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Kembali
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const PaymentInfoDialog = props => {
  const { open, onClose, data, language, type } = props;
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return type === 'customerPayment' ? (
    <CustomerPaymentDialog
      open={open}
      onClose={onClose}
      data={data}
      language={language}
    />
  ) : type === 'customerMonitoring' ? (
    <CustomerMonitoringDialog
      open={open}
      onClose={onClose}
      data={data}
      language={language}
    />
  ) : type === 'customerPaymentForm' ? (
    <CustomerPaymentFormDialog
      open={open}
      onClose={onClose}
      data={data}
      language={language}
      handleChange={handleChange}
      value={value}
    />
  ) : type === 'monitoringForm' ? (
    <MonitoringPaymentFormDialog
      open={open}
      onClose={onClose}
      data={data}
      language={language}
      handleChange={handleChange}
      value={value}
    />
  ) : type === 'detailBarchartRecyclable' ? (
    <DetailBarchartRecyclable
      open={open}
      onClose={onClose}
      data={data}
      language={language}
    />
  ) : (
    <DefaultDialog
      open={open}
      onClose={onClose}
      data={data}
      language={language}
    />
  );
};

export default PaymentInfoDialog;
