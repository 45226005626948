import React, { useState, useEffect } from 'react';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';

const TOKEN =
  'pk.eyJ1IjoiYWdpdGEiLCJhIjoiY2szNXdsMXMyMDVyZTNjcXAzZHN5bnFybiJ9.3chrUBURe7z-68MlbuF6XA';

const Map = props => {
  const { setLongLat, location, editable, overrideViewport } = props;
  const [viewport, setViewport] = useState({
    latitude: -8.669278,
    longitude: 115.213285,
    zoom: 12,
    bearing: 0,
    pitch: 0,
  });
  const navigationStyle = {
    position: 'absolute',
    left: 0,
    padding: 10,
  };

  useEffect(() => {
    if (overrideViewport) {
      setViewport({ ...viewport, ...overrideViewport });
    }
  }, [overrideViewport]);

  const handleMarkerDragEnd = e => {
    setLongLat({
      longitude: e.lngLat[0],
      latitude: e.lngLat[1],
    });
  };

  const handleClickMap = e => {
    setLongLat({
      longitude: e.lngLat[0],
      latitude: e.lngLat[1],
    });
  };

  // eslint-disable-next-line no-underscore-dangle
  const _updateViewport = viewport => {
    setViewport({ ...viewport });
  };

  const markerPointIsValid = () => {
    return (
      location.longitude !== '' &&
      location.longitude >= -180 &&
      location.longitude <= 180 &&
      location.latitude !== '' &&
      location.latitude >= -90 &&
      location.latitude <= 90
    );
  };

  return (
    <div style={{ margin: '0 auto' }}>
      <ReactMapGL
        mapboxApiAccessToken={TOKEN}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...viewport}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        style={{
          minWidth: '100%',
          width: '100%',
          minHeight: 'calc(100vh - 140px)',
          height: 'calc(100vh - 140px)',
        }}
        scrollZoom
        onViewportChange={_updateViewport}
        onClick={editable ? handleClickMap : null}
      >
        <div style={navigationStyle}>
          <NavigationControl />
        </div>

        {location && markerPointIsValid() && (
          <Marker
            longitude={parseFloat(location.longitude)}
            latitude={parseFloat(location.latitude)}
            offsetTop={-20}
            offsetLeft={-10}
            onDragEnd={handleMarkerDragEnd}
            draggable={!!editable}
          >
            <svg
              style={{ width: '24px', height: '24px' }}
              viewBox="0 0 24 24"
            >
              <path
                fill="#F50057"
                d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
              />
            </svg>
          </Marker>
        )}
      </ReactMapGL>
    </div>
  );
};

export default Map;
