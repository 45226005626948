import gql from 'graphql-tag';

export const EXPENDITURES = gql`
  query expenditures($options: Options) {
    expenditures(options: $options) {
      id
      uuid
      worker_id
      item
      item_type
      quantity
      unit_price
      total
      payment_at
      created_at
      updated_at
      worker {
        id
        uuid
        user_id
        role_id
        position
        name
      }
    }
  }
`;

export const EXPENDITURE = gql`
  query expenditure($uuid: String!) {
    expenditure(uuid: $uuid) {
      id
      uuid
      worker_id
      item
      item_type
      quantity
      unit_price
      total
      payment_at
      created_at
      updated_at
      worker {
        id
        uuid
        user_id
        role_id
        position
        name
      }
    }
  }
`;

export const CREATE_EXPENDITURE = gql`
  mutation createExpenditure($data: InExpenditure!) {
    createExpenditure(data: $data) {
      id
      uuid
      worker_id
      item
      item_type
      quantity
      unit_price
      total
      payment_at
      created_at
      updated_at
      worker {
        id
        uuid
        user_id
        role_id
        position
        name
      }
    }
  }
`;
