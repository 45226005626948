import React from 'react';
import { Typography, Container } from '@material-ui/core';

const ComplaintComplete = () => {
  return (
    <Container maxWidth="sm">
      <div>
        <img
          alt="logo"
          src="https://www.stickpng.com/assets/images/584830f5cef1014c0b5e4aa1.png"
          style={{
            display: 'block',
            margin: '15vh auto 10vh auto',
            width: '50%',
          }}
        />
        <Typography align="center" variant="subtitle2">
          Thank you for taking the time to share your feedback. We
          will forward your comments to the appropriate team. Click{' '}
          <a href="/login">here</a> to back to login page. Or{' '}
          <a href="/signup">sign up</a> if you dont have account
          TPS3R.
        </Typography>
      </div>
    </Container>
  );
};

export default ComplaintComplete;
