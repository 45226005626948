import React, { useEffect, useContext, useState } from 'react';
import {
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import Cookies from 'universal-cookie/cjs';
import moment from 'moment';
import randomstring from 'randomstring';
import { rupia } from '../../util';
import * as Yup from 'yup';
import { Formik } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import ErrorText from '../ErrorText';
import SubmitCancel from '../Custom/SubmitCancelBtn';
import GlobalContext from '../../globalContext';
import {
  CREATE_SALES_COMPOST,
  GET_CATALOGUES,
  GET_SALES_COMPOST,
} from '../../graphql/ProcessingQuery';
import { globalStyles } from '../../styles';

const OrganicForm = props => {
  const classes = globalStyles();
  const { language, history, mutate, query } = props;
  const { setSnack, setGlobalLoading, setTitle } = useContext(
    GlobalContext,
  );

  const materialFroms = [
    { value: 'Pengoleksian Sampah', label: 'Pengoleksian Sampah' },
    { value: 'Bank Sampah', label: 'Bank Sampah' },
  ];

  const weightUnits = [
    { value: 'Ton', label: 'Ton' },
    { value: 'Kwintal', label: 'Kwintal' },
    { value: 'Kilogram', label: 'Kilogram' },
    { value: 'Gram', label: 'Gram' },
  ];

  const quantityUnits = [
    { value: 'Karung', label: 'Karung' },
    { value: 'Sak', label: 'Sak' },
    { value: 'Ikat', label: 'Ikat' },
    { value: 'Ember', label: 'Ember' },
    { value: 'Lembar', label: 'Lembar' },
    { value: 'Botol', label: 'Botol' },
    { value: 'Kaleng', label: 'Kaleng' },
    { value: 'Karton', label: 'Karton' },
  ];

  const [fixedCatalogues, setFixedCatalogues] = useState([]);

  useEffect(() => {
    setTitle(language.processing.addProcessing);
    // setGlobalLoading(true);
    setGlobalLoading(false);
    const getCatalogues = async () => {
      const {
        data: { catalogues },
      } = await query({
        query: GET_CATALOGUES,
        fetchPolicy: 'no-cache',
      });
      setFixedCatalogues(catalogues);
    };

    getCatalogues();
  }, [
    language.processing.addProcessing,
    setGlobalLoading,
    setTitle,
    query,
  ]);

  return (
    <Grid
      container
      direction="row"
      justify="flex-end"
      alignItems="baseline"
    >
      {fixedCatalogues.length > 0 && (
        <Grid item xs={12} sm={12} md={12} lg={7}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Formik
              initialValues={{
                payment_at: moment().format('YYYY MM DD'),
                material_from: '',
                weight: 0,
                weight_unit: 'Kilogram',
                quantity: 0,
                quantity_unit: 'Karung',
                price: 0,
                type: 'COMPOST',
                sell_to: '',
              }}
              validationSchema={Yup.object().shape({
                sell_to: Yup.string().required(
                  language.validate.required,
                ),
                payment_at: Yup.date().required(
                  language.validate.required,
                ),
                weight: Yup.number()
                  .min(1, language.processing.form.minError1)
                  .required(language.validate.required),
                weight_unit: Yup.string().required(
                  language.validate.required,
                ),
                quantity: Yup.number()
                  .min(1, language.processing.form.minError1)
                  .required(language.validate.required),
                quantity_unit: Yup.string().required(
                  language.validate.required,
                ),
                price: Yup.number()
                  .min(100, language.processing.form.minError100)
                  .required(language.validate.required),
              })}
              onSubmit={async (
                {
                  payment_at,
                  material_from,
                  weight,
                  weight_unit,
                  quantity,
                  quantity_unit,
                  price,
                  type,
                  sell_to,
                },
                { resetForm },
              ) => {
                try {
                  const cookie = new Cookies();
                  setGlobalLoading(true);
                  const data = {
                    worker_id: cookie.get('id'),
                    material_from,
                    sell_to,
                    type,
                    total: parseFloat(weight * quantity * price),
                    payment_at: moment(payment_at).format(
                      'YYYY-MM-DD',
                    ),
                    sales_details: [
                      {
                        catalogue_id: 1,
                        weight: parseFloat(weight),
                        weight_unit: weight_unit,
                        quantity: parseFloat(quantity),
                        quantity_unit: quantity_unit,
                        price: parseInt(price),
                        sub_total: parseFloat(
                          weight * quantity * price,
                        ),
                      },
                    ],
                  };

                  await mutate({
                    mutation: CREATE_SALES_COMPOST,
                    variables: { data },
                    fetchPolicy: 'no-cache',
                    onError: error => {
                      console.log(
                        '==> Error execute mutation',
                        error,
                      );
                    },
                    update: (proxy, { data: { createSale } }) => {
                      const olddata = proxy.readQuery({
                        query: GET_SALES_COMPOST,
                        variables: { type: 'COMPOST' },
                      });
                      proxy.writeQuery({
                        query: GET_SALES_COMPOST,
                        variables: { type: 'COMPOST' },
                        data: {
                          ...olddata,
                          sales: [...olddata.sales, createSale],
                        },
                      });
                    },
                  });
                  setSnack({
                    variant: 'success',
                    message: `Penjualan kompos berhasil ditambahkan!`,
                    opened: true,
                  });
                  resetForm();
                  history.replace('/income/compost');
                } catch (error) {
                  console.log('Error create User', error);
                  setSnack({
                    variant: 'error',
                    message: 'Gagal menambahkan penjualan kompos!',
                    opened: true,
                  });
                  setGlobalLoading(false);
                }
              }}
            >
              {({
                errors,
                touched,
                handleSubmit,
                values,
                // handleChange,
                setFieldValue,
              }) => (
                <form noValidate>
                  <Paper className={classes.root}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <KeyboardDatePicker
                          margin="dense"
                          // id="date-picker-dialog"
                          label={language.processing.form.date}
                          format="MM/dd/yyyy"
                          value={values.payment_at}
                          onChange={e => {
                            const dateval = moment(e).format(
                              'YYYY MM DD',
                            );
                            setFieldValue(
                              'payment_at',
                              dateval,
                              false,
                            );
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormControl margin="dense" fullWidth>
                          <InputLabel id="material_from">
                            {language.processing.form.unit}
                          </InputLabel>
                          <Select
                            margin="dense"
                            fullWidth
                            required
                            labelId="material_from"
                            id="material_from"
                            name="material_from"
                            value={values.material_from}
                            onChange={e => {
                              setFieldValue(
                                'material_from',
                                e.target.value,
                                false,
                              );
                            }}
                          >
                            <MenuItem value="Default" disabled>
                              --- Pilih ---
                            </MenuItem>
                            {materialFroms.map(z => (
                              <MenuItem
                                key={randomstring.generate(3)}
                                value={z.value}
                              >
                                <Typography variant="body2">
                                  {z.label}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.material_from &&
                            touched.material_from && (
                              <ErrorText
                                text={errors.material_from}
                              />
                            )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} lg={3}>
                        <TextField
                          autoFocus
                          margin="dense"
                          required
                          fullWidth
                          id="weight"
                          label={language.processing.form.weight}
                          name="weight"
                          autoComplete="weight"
                          onChange={e =>
                            setFieldValue(
                              'weight',
                              e.target.value,
                              false,
                            )
                          }
                          value={values.weight}
                        />
                        {errors.weight && touched.weight && (
                          <ErrorText text={errors.weight} />
                        )}
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} lg={3}>
                        <FormControl margin="dense" fullWidth>
                          <InputLabel id="weight_unit">
                            {language.processing.form.unit}
                          </InputLabel>
                          <Select
                            margin="dense"
                            fullWidth
                            required
                            labelId="weight_unit"
                            id="weight_unit"
                            name="weight_unit"
                            value={values.weight_unit}
                            onChange={e => {
                              setFieldValue(
                                'weight_unit',
                                e.target.value,
                                false,
                              );
                            }}
                          >
                            <MenuItem value="Default" disabled>
                              --- Pilih ---
                            </MenuItem>
                            {weightUnits.map(z => (
                              <MenuItem
                                key={randomstring.generate(3)}
                                value={z.value}
                              >
                                <Typography variant="body2">
                                  {z.label}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.weight_unit &&
                            touched.weight_unit && (
                              <ErrorText text={errors.weight_unit} />
                            )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} lg={3}>
                        <TextField
                          autoFocus
                          margin="dense"
                          required
                          fullWidth
                          id="quantity"
                          label={language.processing.form.quantity}
                          name="quantity"
                          autoComplete="quantity"
                          onChange={e =>
                            setFieldValue(
                              'quantity',
                              e.target.value,
                              false,
                            )
                          }
                          value={values.quantity}
                        />
                        {errors.quantity && touched.quantity && (
                          <ErrorText text={errors.quantity} />
                        )}
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} lg={3}>
                        <FormControl margin="dense" fullWidth>
                          <InputLabel id="quantity_unit">
                            {language.processing.form.unit}
                          </InputLabel>
                          <Select
                            margin="dense"
                            fullWidth
                            required
                            labelId="quantity_unit"
                            id="quantity_unit"
                            name="quantity_unit"
                            value={values.quantity_unit}
                            onChange={e => {
                              setFieldValue(
                                'quantity_unit',
                                e.target.value,
                                false,
                              );
                            }}
                          >
                            <MenuItem value="Default" disabled>
                              --- Pilih ---
                            </MenuItem>
                            {quantityUnits.map(z => (
                              <MenuItem
                                key={randomstring.generate(3)}
                                value={z.value}
                              >
                                <Typography variant="body2">
                                  {z.label}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.quantity_unit &&
                            touched.quantity_unit && (
                              <ErrorText
                                text={errors.quantity_unit}
                              />
                            )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          autoFocus
                          margin="dense"
                          required
                          fullWidth
                          id="price"
                          label={language.processing.form.price}
                          name="price"
                          autoComplete="price"
                          onChange={e =>
                            setFieldValue(
                              'price',
                              e.target.value,
                              false,
                            )
                          }
                          value={values.price}
                        />
                        {errors.price && touched.price && (
                          <ErrorText text={errors.price} />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          autoFocus
                          margin="dense"
                          required
                          fullWidth
                          id="sell_to"
                          label={language.processing.form.sell_to}
                          name="sell_to"
                          value={values.sell_to}
                          onChange={e =>
                            setFieldValue(
                              'sell_to',
                              e.target.value,
                              false,
                            )
                          }
                        />
                        {errors.sell_to && touched.sell_to && (
                          <ErrorText text={errors.sell_to} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          autoFocus
                          margin="dense"
                          required
                          fullWidth
                          id="total"
                          label={language.processing.form.total}
                          name="total"
                          disabled
                          value={rupia(
                            values.weight *
                              values.price *
                              values.quantity,
                          )}
                        />
                      </Grid>
                      {/* {userDetail && userDetail.name ? (
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        style={{
                          marginTop: '8px',
                          textAlign: 'center',
                        }}
                      >
                        <Tooltip
                          title={language.income.customer.detail}
                        >
                          <IconButton
                            aria-label="delete"
                            onClick={handleUserDetail}
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    ) : null} */}

                      <SubmitCancel
                        submitClass={classes.saveButton}
                        submitClick={handleSubmit}
                        submitTxt={language.action.save}
                        cancelTxt={language.action.back}
                        isCancelLink
                        cancelTo="/income/compost"
                      />
                    </Grid>
                  </Paper>
                </form>
              )}
            </Formik>
          </MuiPickersUtilsProvider>
        </Grid>
      )}
    </Grid>
  );
};

export default OrganicForm;
