/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useContext, useState } from 'react';
import {
  Grid,
  Paper,
  InputLabel,
  IconButton,
  Tooltip,
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AsyncSelect from 'react-select/async';

import ErrorText from '../ErrorText';
import { globalStyles, monitoringPage } from '../../styles';
import GlobalContext from '../../globalContext';
import { CREATE_BILLING } from '../../graphql/BillingQuery';
import {
  SEARCH_USERS,
  GET_USER_DETAIL,
} from '../../graphql/UserQuery';
import TpsDialog from '../Custom/TpsDialog';

const PaymentForm = props => {
  const { history, query, mutate, language } = props;
  const { setSnack, setGlobalLoading, setTitle } = useContext(
    GlobalContext,
  );
  const classes = monitoringPage();
  const globalClasses = globalStyles();

  const [userDetail, setUserDetail] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTitle(language.income.customer.base);
  }, [setTitle, language.income.customer.base]);

  const getUsers = async ({ name }) => {
    const {
      data: { searchUsers },
    } = await query({
      query: SEARCH_USERS,
      variables: { name, position: 'CUSTOMER' },
      fetchPolicy: 'no-cache',
    });

    const options = searchUsers.map(l => ({
      value: l.id,
      label: l.name,
    }));

    return options;
  };

  const getMonitors = async ({ name }) => {
    const {
      data: { searchUsers },
    } = await query({
      query: SEARCH_USERS,
      variables: { name, position: 'MONITOR' },
      fetchPolicy: 'no-cache',
    });

    const options = searchUsers.map(l => ({
      value: l.id,
      label: `${l.name} - ${l.position}`,
    }));

    return options;
  };

  const getDetailUser = async id => {
    const {
      data: { findUserById },
    } = await query({
      query: GET_USER_DETAIL,
      variables: { id },
      fetchPolicy: 'no-cache',
    });
    setUserDetail(findUserById);
  };

  const loadOptions = async (inputValue, type) => {
    if (type === 'customer') {
      return getUsers({
        name: inputValue,
      });
    }

    if (type === 'monitor') {
      return getMonitors({
        name: inputValue,
      });
    }

    return null;
  };

  const handleInputChange = (newValue, type) => {
    if (type === 'customer') {
      const inputValue = newValue.replace(/\W/g, '');
      return inputValue;
    }

    if (type === 'monitor') {
      const inputValue = newValue.replace(/\W/g, '');
      return inputValue;
    }

    return null;
  };

  const handleUserDetail = () => {
    setOpen(true);
  };

  const NumberFormatCustom = props => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="Rp. "
      />
    );
  };

  return (
    <div>
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Formik
            initialValues={{
              customer: '',
              worker: '',
              lane: '',
              otherLane: '',
              periode: '',
              paymentAt: new Date().toISOString().slice(0, 10),
              totalPayment: 0,
              notes: '',
            }}
            validationSchema={Yup.object().shape({
              customer: Yup.string().required(
                language.validate.required,
              ),
              worker: Yup.string().required(
                language.validate.required,
              ),
              lane: Yup.number().required(language.validate.required),
              otherLane: Yup.string().when('lane', {
                is: lane => lane === 99,
                then: Yup.string().required(
                  language.validate.required,
                ),
              }),
              periode: Yup.string().required(
                language.validate.required,
              ),
              // lane: Yup.string().required(language.validate.required),
              totalPayment: Yup.string().required(
                language.validate.required,
              ),
            })}
            onSubmit={async (
              {
                customer,
                worker,
                periode,
                paymentAt,
                totalPayment,
                notes,
                lane,
                otherLane,
              },
              { resetForm },
            ) => {
              try {
                const data = {
                  user_id: parseInt(customer.value, 10),
                  worker_id: parseInt(worker.value, 10),
                  periode,
                  payment_total: totalPayment,
                  payment_at: paymentAt,
                  notes,
                  lane:
                    lane !== '99'
                      ? parseInt(lane, 10)
                      : parseInt(otherLane, 10),
                };

                await mutate({
                  mutation: CREATE_BILLING,
                  variables: { data },
                  fetchPolicy: 'no-cache',
                  onError: error => {
                    console.log('==> Error execute mutation', error);
                  },
                });
                setGlobalLoading(true);
                setSnack({
                  variant: 'success',
                  message: `Pembayaran ${customer.label} berhasil ditambahkan!`,
                  opened: true,
                });
                resetForm();
                history.replace('/income/payment');
              } catch (error) {
                console.log('Error create payment', error);
                setGlobalLoading(false);
                setSnack({
                  variant: 'error',
                  message: `${
                    language.alert.errorSave
                  } ${language.payment.form.title.toLowerCase()}`,
                  opened: true,
                });
              }
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              values,
              handleChange,
              setFieldValue,
            }) => (
              <form noValidate>
                <Paper className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel id="lane">
                          {language.monitoring.form.lane}
                        </InputLabel>
                        <Select
                          margin="normal"
                          fullWidth
                          required
                          labelId="lane"
                          id="lane"
                          name="lane"
                          value={values.lane}
                          onChange={handleChange}
                        >
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                          <MenuItem value="4">4</MenuItem>
                          <MenuItem value="5">5</MenuItem>
                          <MenuItem value="6">6</MenuItem>
                          <MenuItem value="7">7</MenuItem>
                          <MenuItem value="8">8</MenuItem>
                          <MenuItem value="9">9</MenuItem>
                          <MenuItem value="10">10</MenuItem>
                        </Select>
                      </FormControl>
                      {errors.lane && touched.lane && (
                        <ErrorText text={errors.lane} />
                      )}
                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={10}>
                      <InputLabel
                        shrink
                        className={classes.labelAsyncSelect}
                      >
                        {language.income.customer.name} *
                      </InputLabel>

                      <AsyncSelect
                        id="customer"
                        margin="normal"
                        name="customer"
                        placeholder={language.income.customer.name}
                        value={values.customer}
                        loadOptions={e => loadOptions(e, 'customer')}
                        defaultOptions
                        onInputChange={e =>
                          handleInputChange(e, 'customer')
                        }
                        onChange={o => {
                          setFieldValue('customer', o);
                          getDetailUser(o.value);
                        }}
                      />
                      {errors.customer && touched.customer && (
                        <ErrorText text={errors.customer} />
                      )}
                    </Grid>
                    {userDetail && userDetail.name ? (
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        style={{
                          marginTop: '8px',
                          textAlign: 'center',
                        }}
                      >
                        <Tooltip
                          title={language.income.customer.detail}
                        >
                          <IconButton
                            aria-label="delete"
                            onClick={handleUserDetail}
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    ) : null}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <InputLabel
                        shrink
                        className={classes.labelAsyncSelect}
                      >
                        {language.payment.form.paidTo} *
                      </InputLabel>

                      <AsyncSelect
                        id="worker"
                        margin="normal"
                        name="worker"
                        placeholder={language.payment.form.paidTo}
                        value={values.worker}
                        loadOptions={e => loadOptions(e, 'monitor')}
                        defaultOptions
                        onInputChange={e =>
                          handleInputChange(e, 'monitor')
                        }
                        onChange={o => {
                          setFieldValue('worker', o);
                        }}
                      />
                      {errors.worker && touched.worker && (
                        <ErrorText text={errors.worker} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="periode"
                        label={language.income.customer.periode}
                        placeholder="Format: MM-YYYY"
                        type="text"
                        name="periode"
                        onChange={handleChange}
                        value={values.periode}
                      />
                      {errors.periode && touched.periode && (
                        <ErrorText text={errors.periode} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        margin="normal"
                        fullWidth
                        required
                        id="date"
                        label={language.income.customer.paymentAt}
                        type="date"
                        name="paymentAt"
                        autoComplete="paymentAt"
                        onChange={handleChange}
                        value={values.paymentAt}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {errors.paymentAt && touched.paymentAt && (
                        <ErrorText text={errors.paymentAt} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel id="lane">
                          {language.monitoring.form.lane}
                        </InputLabel>
                        <Select
                          margin="normal"
                          fullWidth
                          required
                          labelId="lane"
                          id="lane"
                          name="lane"
                          value={values.lane}
                          onChange={e => {
                            setFieldValue('lane', e.target.value);
                          }}
                        >
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                          <MenuItem value="4">4</MenuItem>
                          <MenuItem value="5">5</MenuItem>
                          <MenuItem value="6">6</MenuItem>
                          <MenuItem value="7">7</MenuItem>
                          <MenuItem value="8">8</MenuItem>
                          <MenuItem value="9">9</MenuItem>
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="99">Lainnya</MenuItem>
                        </Select>
                      </FormControl>
                      {errors.lane && touched.lane && (
                        <ErrorText text={errors.lane} />
                      )}
                    </Grid>
                    {values.lane === '99' && (
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="otherLane"
                          label={language.customer.form.lane.other}
                          name="otherLane"
                          onChange={handleChange}
                          value={values.otherLane}
                        />
                        {errors.otherLane && touched.otherLane && (
                          <ErrorText text={errors.otherLane} />
                        )}
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        margin="normal"
                        fullWidth
                        required
                        label={language.income.customer.total}
                        value={values.totalPayment}
                        id="totalPayment"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                      {errors.totalPayment &&
                        touched.totalPayment && (
                          <ErrorText text={errors.totalPayment} />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        rows={5}
                        id="notes"
                        label={language.income.customer.notes}
                        type="notes"
                        name="notes"
                        autoComplete="notes"
                        onChange={handleChange}
                        value={values.notes}
                      />
                      {errors.notes && touched.notes && (
                        <ErrorText text={errors.notes} />
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="baseline"
                  >
                    <Button
                      className={globalClasses.saveButton}
                      onClick={handleSubmit}
                      variant="contained"
                    >
                      {language.action.save}
                    </Button>
                  </Grid>
                </Paper>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <TpsDialog
        data={userDetail}
        open={open}
        language={language}
        onClose={() => {
          setOpen(false);
        }}
        type="customerPaymentForm"
      />
    </div>
  );
};

export default PaymentForm;
