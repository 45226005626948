import gql from 'graphql-tag';

export const GET_TPSES = gql`
  query tpses($options: Options) {
    tpses(options: $options) {
      id
      uuid
      name
      jalan
      dusun
      banjar
      desa_adat
      kelurahan
      kecamatan
      kabupaten
      provinsi
      location {
        longitude
        latitude
      }
      photo
      capacity
      capacity_unit
      created_at
      updated_at
      user {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        phone
        jabatan
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
    }
  }
`;

export const CREATE_TPS = gql`
  mutation createTps($data: InTps!) {
    createTps(data: $data) {
      id
      uuid
      name
      jalan
      dusun
      banjar
      desa_adat
      kelurahan
      kecamatan
      kabupaten
      provinsi
      location {
        longitude
        latitude
      }
      photo
      capacity
      capacity_unit
      created_at
      updated_at
      user {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        phone
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
    }
  }
`;

export const GET_TPS = gql`
  query tps($uuid: String!) {
    tps(uuid: $uuid) {
      id
      uuid
      name
      jalan
      dusun
      banjar
      desa_adat
      kelurahan
      kecamatan
      kabupaten
      provinsi
      location {
        longitude
        latitude
      }
      photo
      capacity
      capacity_unit
      created_at
      updated_at
      user {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        phone
        jabatan
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
        created_at
        registration_date
      }
    }
  }
`;

export const SEARCH_TPS = gql`
  query searchTps($name: String!) {
    searchTps(name: $name) {
      id
      uuid
      name
      jalan
      dusun
      banjar
      desa_adat
      kelurahan
      kecamatan
      kabupaten
      provinsi
      location {
        longitude
        latitude
      }
      photo
      capacity
      capacity_unit
      created_at
      updated_at
      user {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        phone
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
    }
  }
`;

export const UPDATE_TPS = gql`
  mutation updateTps($uuid: String!, $data: InTps!) {
    updateTps(uuid: $uuid, data: $data) {
      id
      uuid
      name
      jalan
      dusun
      banjar
      desa_adat
      kelurahan
      kecamatan
      kabupaten
      provinsi
      location {
        longitude
        latitude
      }
      photo
      capacity
      capacity_unit
      created_at
      updated_at
      user {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        phone
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
    }
  }
`;

export const TOTAL_TPS = gql`
  query totalTps {
    totalTps {
      count
    }
  }
`;
