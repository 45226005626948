import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function MyLoading() {
  return (
    <div
      style={{
        backgroundColor: 'rgba(0,0,0,0.4)',
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 10000,
      }}
    >
      <CircularProgress
        size={80}
        style={{
          color: '#99cfe0',
          position: 'absolute',
          left: -40,
          top: -40,
          marginLeft: '50vw',
          marginTop: '50vh',
        }}
      />
    </div>
  );
}
