import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { blue, red, green } from '@material-ui/core/colors';
import GlobalContext from '../../globalContext';
import SubmitCancel from '../Custom/SubmitCancelBtn';
import { rupia } from '../../util';
import {
  GET_SALES_OTHER,
  DELETE_SALE_OTHER,
} from '../../graphql/ProcessingQuery';

// import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  greenClass: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
  },
  redClass: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
  },
  blueClass: {
    color: theme.palette.getContrastText(blue[600]),
    backgroundColor: blue[600],
  },
}));

export default function OtherIncomeDetail(props) {
  const classes = useStyles();
  const { query, history, language, match, mutate } = props;
  const { uuid } = match.params;
  const [saler, setSale] = useState(null);
  const { setGlobalLoading, setTitle } = useContext(GlobalContext);

  useEffect(() => {
    const getInitial = async () => {
      setGlobalLoading(true);
      setTitle(language.processing.otherDetail);
      const {
        data: { salesOther },
      } = await query({
        query: GET_SALES_OTHER,
        variables: { uuid },
        fetchPolicy: 'no-cache',
      });
      setSale(salesOther);
      setTimeout(() => setGlobalLoading(false), 1500);
    };
    getInitial();
  }, [
    language.processing.otherDetail,
    query,
    setGlobalLoading,
    setTitle,
    uuid,
  ]);

  const confirmDelete = async () => {
    if (
      window.confirm('Are you sure want to delete this other income?')
    ) {
      setGlobalLoading(true);
      await mutate({
        mutation: DELETE_SALE_OTHER,
        variables: { uuid: saler.uuid },
        fetchPolicy: 'no-cache',
      });
      setTimeout(() => setGlobalLoading(false), 1000);
      history.replace('/income/other');
    }
  };

  return (
    <Grid container spacing={2}>
      {saler && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h5">Detail Pendapatan</Typography>
            <Typography variant="body1">
              Sumber Dana: {saler.source_fund}
            </Typography>
            <Typography variant="body1">
              Keterangan: {saler.notes}
            </Typography>
            <Typography variant="body1">
              Dicatat: {saler.worker.name}
            </Typography>
            <Typography variant="body1">
              Tanggal: {moment(saler.payment_at).format('LL')}
            </Typography>
            <Typography variant="body1">
              Total: {rupia(saler.total)}
            </Typography>
          </Grid>
          {/* <Button
            component={Link}
            to="/income/recyclable"
            style={{
              marginTop: 20,
            }}
            variant="outlined"
            color="default"
          >
            {language.action.back}
          </Button> */}
          <SubmitCancel
            submitClass={classes.redClass}
            submitClick={confirmDelete}
            submitTxt={language.action.delete}
            cancelTxt={language.action.back}
            isCancelLink
            cancelTo="/income/other"
          />
        </>
      )}
    </Grid>
  );
}
