import React from 'react';
import { Grid, Paper, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';

import TpsMapBox from '../Custom/TpsMapBox';

const UserSummary = props => {
  const { data, classes, language } = props;
  const NumberFormatCustom = props => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="Rp. "
      />
    );
  };
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={7}>
          <Paper className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="name"
                  label={language.customer.detail.summary.name}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data ? data.name : ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  label={language.customer.detail.summary.email}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data ? data.email : ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="phone"
                  label={language.customer.detail.summary.phone}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data ? data.phone : ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="category"
                  label={language.customer.detail.summary.category}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data ? data.category : ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="size"
                  label={language.customer.detail.summary.size}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data ? data.size : ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="service_fee"
                  label={language.customer.detail.summary.serviceFee}
                  InputProps={{
                    readOnly: true,
                    inputComponent: NumberFormatCustom,
                  }}
                  value={data ? data.service_fee : ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="lane"
                  label={language.customer.detail.summary.lane}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data ? data.lane : ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="provinsi"
                  label={
                    language.customer.detail.summary.address.provinsi
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data ? data.provinsi : ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="kabupaten"
                  label={
                    language.customer.detail.summary.address.kabupaten
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data ? data.kabupaten : ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="kecamatan"
                  label={
                    language.customer.detail.summary.address.kecamatan
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data ? data.kecamatan : ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="kelurahan"
                  label={
                    language.customer.detail.summary.address.kelurahan
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data ? data.kelurahan : ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="desa_adat"
                  label={
                    language.customer.detail.summary.address.desaAdat
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data ? data.desa_adat : ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={2}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="banjar"
                  label={
                    language.customer.detail.summary.address.banjar
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data ? data.banjar : ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={2}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="dusun"
                  label={
                    language.customer.detail.summary.address.dusun
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data ? data.dusun : ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="jalan"
                  label={
                    language.customer.detail.summary.address.jalan
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data ? data.jalan : ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  multiline
                  rows={5}
                  id="notes"
                  label={language.customer.detail.summary.notes}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data ? data.notes : ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="latitude"
                  label={language.customer.detail.summary.latitude}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    data && data.location
                      ? data.location.latitude
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="longitude"
                  label={language.customer.detail.summary.longitude}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    data && data.location
                      ? data.location.longitude
                      : ''
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5}>
          <TpsMapBox
            location={{
              latitude:
                data && data.location ? data.location.latitude : 0,
              longitude:
                data && data.location ? data.location.longitude : 0,
            }}
            editable={false}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default UserSummary;
