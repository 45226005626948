import React, { useEffect, useContext, useState } from 'react';
import {
  Button,
  Grid,
  Paper,
  TextField,
  Fab,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { isMobile } from '../../util';

import GlobalContext from '../../globalContext';
import { EXPENDITURES } from '../../graphql/ExpenditureQuery';
import OperationalList from './OperationalList';
import { globalStyles } from '../../styles';

const Operational = props => {
  const classes = globalStyles();
  const { query, language, history } = props;
  const [data, setData] = useState([]);
  const [fixedData, setFixedData] = useState([]);
  const { setSnack, setGlobalLoading, setTitle } = useContext(
    GlobalContext,
  );

  useEffect(() => {
    setTitle(language.expenditure.operational);
    setGlobalLoading(true);

    const getExpenditures = async () => {
      const {
        data: { expenditures },
      } = await query({
        query: EXPENDITURES,
        fetchPolicy: 'no-cache',
      });

      if (expenditures) {
        console.log('expenditures', expenditures);
        setData(expenditures);
        setFixedData(expenditures);
        setGlobalLoading(false);
      } else {
        setGlobalLoading(false);
        setSnack({
          variant: 'error',
          message: 'Gagal mengambil data pengeluaran!',
          opened: true,
        });
      }
    };

    getExpenditures();
  }, [
    setTitle,
    language.expenditure.operational,
    query,
    setGlobalLoading,
    setSnack,
  ]);

  const handleClickOpen = value => {
    setGlobalLoading(true);
    history.replace(`/expenditure/operational/${value.uuid}`);
  };

  const handleSearch = e => {
    const finds = [...fixedData].filter(x =>
      x.worker.name
        .toLowerCase()
        .includes(e.currentTarget.value.toLowerCase()),
    );
    setData(finds);
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="baseline"
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="baseline"
        >
          <TextField
            id="outlined-basic"
            label={language.action.search}
            variant="outlined"
            size="small"
            fullWidth
            onChange={e => handleSearch(e)}
          />
        </Grid>
        {!isMobile() && (
          <Grid
            container
            direction="column"
            justify="flex-end"
            alignItems="baseline"
          >
            <Button
              className={classes.addButton}
              component={Link}
              to="/expenditure/operational/new"
              variant="contained"
              color="primary"
            >
              {language.action.add}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ marginTop: 20 }}>
          <OperationalList data={data} onClick={handleClickOpen} />
        </Paper>
      </Grid>
      {isMobile() && (
        <Fab
          color="primary"
          aria-label="add"
          component={Link}
          to="/expenditure/operational/new"
          variant="round"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
          }}
        >
          <AddIcon />
        </Fab>
      )}
    </div>
  );
};

export default Operational;
