import React, { useEffect, useContext, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
  Grid,
} from '@material-ui/core/';
import moment from 'moment-timezone';
import NumberFormat from 'react-number-format';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import GlobalContext from '../../globalContext';
import {
  TOTAL_CUSTOMER,
  AVERAGE_MONITORING,
  MONTHLY_PAYMENT,
  BARCHART_RECYCLABLE,
  DETAIL_BARCHART_RECYCLABLE,
  BARCHART_SALES_OTHER,
  DETAIL_BARCHART_SALES_OTHER,
  BARCHART_BILLING,
  DETAIL_BARCHART_BILLING,
} from '../../graphql/DashboardQuery';
import { rupia } from '../../util';
import TpsDialog from '../Custom/TpsDialog';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    textAlign: 'center',
  },
  pos: {
    marginBottom: 12,
  },
  dashboardContent: {
    textAlign: 'center',
    fontSize: '24px',
  },
});

const Dashboard = props => {
  const { query, language } = props;
  const classes = useStyles();
  const { setTitle } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);

  const [totalCustomer, setTotalCustomer] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [averageMonitoring, setAverageMonitoring] = useState(0);
  const [barchartRecyclable, setBarchartRecyclable] = useState([]);
  const [detailBarchart, setDetailBarchart] = useState([]);
  const [barchartSalesOther, setBarchartSalesOther] = useState([]);
  const [barchartBilling, setBarchartBilling] = useState([]);

  const dataTotalCustomer = useQuery(TOTAL_CUSTOMER, {
    variables: {
      role_id: 2,
    },
  });

  const dataAverageMonitoring = useQuery(AVERAGE_MONITORING, {
    variables: {
      month: moment().format('MM'),
      year: moment().format('YYYY'),
    },
  });

  const dataMonthlyPayment = useQuery(MONTHLY_PAYMENT, {
    variables: {
      month: moment().format('MM'),
      year: moment().format('YYYY'),
    },
  });

  const dataChartRecyclable = useQuery(BARCHART_RECYCLABLE, {
    variables: {
      year: new Date().getFullYear().toString(),
    },
  });

  const dataChartSalesOther = useQuery(BARCHART_SALES_OTHER, {
    variables: {
      // year: new Date().getFullYear().toString(),
      year: '2019',
    },
  });

  const dataChartBilling = useQuery(BARCHART_BILLING, {
    variables: {
      year: '2019',
    },
  });

  useEffect(() => {
    setTitle(language.sidebar.dashboard);
    if (dataTotalCustomer.data) {
      setTotalCustomer(dataTotalCustomer.data.totalCustomer.count);
    }
  }, [setTitle, language.sidebar.dashboard, dataTotalCustomer]);

  useEffect(() => {
    setTitle(language.sidebar.dashboard);
    if (dataAverageMonitoring.data) {
      setAverageMonitoring(
        dataAverageMonitoring.data.averageMonitoring.count,
      );
    }
  }, [setTitle, language.sidebar.dashboard, dataAverageMonitoring]);

  useEffect(() => {
    setTitle(language.sidebar.dashboard);
    if (dataMonthlyPayment.data) {
      setMonthlyPayment(dataMonthlyPayment.data.totalBilling.count);
    }
  }, [setTitle, language.sidebar.dashboard, dataMonthlyPayment]);

  useEffect(() => {
    if (dataChartRecyclable.data) {
      const data = dataChartRecyclable.data.barChartRecyclable.map(
        x => ({
          name: moment(`${x.y}-${x.m}-01`).format('MMM, YYYY'),
          total: x.sum,
        }),
      );
      setBarchartRecyclable(data);
    }
  }, [dataChartRecyclable]);

  useEffect(() => {
    if (dataChartSalesOther.data) {
      const data = dataChartSalesOther.data.barChartSalesOther.map(
        x => ({
          name: moment(`${x.y}-${x.m}-01`).format('MMM, YYYY'),
          total: x.sum,
        }),
      );
      setBarchartSalesOther(data);
    }
  }, [dataChartSalesOther]);

  useEffect(() => {
    if (dataChartBilling.data) {
      const data = dataChartBilling.data.barChartBilling.map(
        x => ({
          name: moment(`${x.y}-${x.m}-01`).format('MMM, YYYY'),
          total: x.sum,
        }),
      );
      setBarchartBilling(data);
    }
  }, [dataChartBilling]);

  const getDetailChartRecyclable = yearMonth => {
    const getDetailChart = async () => {
      const year = moment(yearMonth).format('YYYY');
      const month = moment(yearMonth).format('MM');
      const {
        data: { detailBarChartRecyclable },
      } = await query({
        query: DETAIL_BARCHART_RECYCLABLE,
        variables: {
          year,
          month,
        },
        fetchPolicy: 'no-cache',
      });

      const data = detailBarChartRecyclable.map(x => ({
        name: moment(x.payment_at).format('DD/M'),
        total: x.sum,
      }));

      setDetailBarchart(data);
    };

    getDetailChart();
  };

  const getDetailChartSalesOther = yearMonth => {
    const getDetailChart = async () => {
      const year = moment(yearMonth).format('YYYY');
      const month = moment(yearMonth).format('MM');
      const {
        data: { detailChartSalesOther },
      } = await query({
        query: DETAIL_BARCHART_SALES_OTHER,
        variables: {
          year,
          month,
        },
        fetchPolicy: 'no-cache',
      });

      const data = detailChartSalesOther.map(x => ({
        name: moment(x.payment_at).format('DD/M'),
        total: x.sum,
      }));

      setDetailBarchart(data);
    };

    getDetailChart();
  };

  const getdetailChartBilling = yearMonth => {
    const getDetailChart = async () => {
      const year = moment(yearMonth).format('YYYY');
      const month = moment(yearMonth).format('MM');
      const {
        data: { detailChartBilling },
      } = await query({
        query: DETAIL_BARCHART_BILLING,
        variables: {
          year,
          month,
        },
        fetchPolicy: 'no-cache',
      });

      const data = detailChartBilling.map(x => ({
        name: moment(x.payment_at).format('DD/M'),
        total: x.sum,
      }));

      setDetailBarchart(data);
    };

    getDetailChart();
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h6"
                gutterBottom
              >
                {language.dashboard.totalCustomer}
              </Typography>
              <Typography
                className={classes.dashboardContent}
                variant="h3"
                component="h2"
              >
                {totalCustomer || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h6"
                gutterBottom
              >
                {language.dashboard.averageMonitoring}
              </Typography>
              <Typography
                className={classes.dashboardContent}
                variant="h3"
                component="h2"
              >
                {averageMonitoring.toFixed(2) || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h6"
                gutterBottom
              >
                {language.dashboard.monthlyPayment}
              </Typography>
              <Typography
                className={classes.dashboardContent}
                variant="h3"
                component="h2"
              >
                <NumberFormat
                  value={monthlyPayment || 0}
                  thousandSeparator
                  displayType="text"
                  prefix="Rp. "
                />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h6"
                gutterBottom
              >
                {language.dashboard.totalPayment}
              </Typography>
              <Typography
                className={classes.dashboardContent}
                variant="h3"
                component="h2"
              >
                0 {/* Not yet count from API */}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h6"
                gutterBottom
              >
                Total Penjualan Lapak
              </Typography>
              <div
                style={{
                  paddingBottom: '56.25%' /* 16:9 */,
                  position: 'relative',
                  height: 0,
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <ResponsiveContainer>
                    <BarChart
                      width={800}
                      height={300}
                      data={barchartRecyclable}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      onClick={async e => {
                        const yearMonth = moment(
                          e.activeLabel,
                        ).format('YYYY-MM');
                        await getDetailChartRecyclable(yearMonth);
                        setOpen(true);
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip
                        formatter={value =>
                          `Rp. ${new Intl.NumberFormat('en').format(
                            value,
                          )}`
                        }
                      />
                      <Legend />
                      <Bar dataKey="total" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h6"
                gutterBottom
              >
                Total Pendapatan Lainnya
              </Typography>
              <div
                style={{
                  paddingBottom: '56.25%' /* 16:9 */,
                  position: 'relative',
                  height: 0,
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <ResponsiveContainer>
                    <BarChart
                      width={800}
                      height={300}
                      data={barchartSalesOther}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      onClick={async e => {
                        const yearMonth = moment(
                          e.activeLabel,
                        ).format('YYYY-MM');
                        await getDetailChartSalesOther(yearMonth);
                        setOpen(true);
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip
                        formatter={value =>
                          `Rp. ${new Intl.NumberFormat('en').format(
                            value,
                          )}`
                        }
                      />
                      <Legend />
                      <Bar dataKey="total" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h6"
                gutterBottom
              >
                Total Pembayaran
              </Typography>
              <div
                style={{
                  paddingBottom: '56.25%' /* 16:9 */,
                  position: 'relative',
                  height: 0,
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <ResponsiveContainer>
                    <BarChart
                      width={800}
                      height={300}
                      data={barchartBilling}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      onClick={async e => {
                        const yearMonth = moment(
                          e.activeLabel,
                        ).format('YYYY-MM');
                        await getdetailChartBilling(yearMonth);
                        setOpen(true);
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip
                        formatter={value =>
                          `Rp. ${new Intl.NumberFormat('en').format(
                            value,
                          )}`
                        }
                      />
                      <Legend />
                      <Bar dataKey="total" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <TpsDialog
        data={detailBarchart}
        open={open}
        language={language}
        onClose={() => {
          setOpen(false);
        }}
        type="detailBarchartRecyclable"
      />
    </div>
  );
};

export default Dashboard;
