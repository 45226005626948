/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  Tooltip,
  Drawer,
  Box,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment-timezone';

import {
  GET_MONITORINGS_USER,
  GET_TOTAL_MONITORINGS_USER,
} from '../../graphql/MonitoringQuery';
import TpsDialog from '../Custom/TpsDialog';

const UserMonitoring = props => {
  const { language, query, data, classes } = props;
  const [monitoring, setMonitoring] = useState([]);
  const [fixedData, setFixedData] = useState([]);
  const [filterDate, setFilterDate] = useState(
    new Date().toISOString().slice(0, 10),
  );
  const [drawer, setDrawer] = useState(false);
  const [monitoringDetail, setMonitoringDetail] = useState({});
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const getUserMonitoring = async () => {
      const {
        data: { findMonitoringByUser },
      } = await query({
        query: GET_MONITORINGS_USER,
        variables: {
          user_id: data.id,
        },
        fetchPolicy: 'no-cache',
      });

      setMonitoring(findMonitoringByUser);
      setFixedData(findMonitoringByUser);
    };

    const getTotal = async () => {
      const {
        data: { totalMonitoringUser },
      } = await query({
        query: GET_TOTAL_MONITORINGS_USER,
        variables: {
          user_id: data.id,
        },
        fetchPolicy: 'no-cache',
      });

      setTotal(totalMonitoringUser.count);
    };

    if (!monitoring.length) {
      getTotal();
      getUserMonitoring();
    }
  }, [data.id, monitoring.length, query]);

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawer(open);
  };

  const handleFilter = () => {
    const filterData = fixedData
      .map(e => e)
      .filter(
        x =>
          moment(x.seperation_date).format('YYYY-MM-DD') ===
          filterDate,
      );
    setMonitoring(filterData);
    setDrawer(false);
  };

  const handleSearch = e => {
    const searchedItems = [...fixedData].filter(
      x => x.worker && x.worker.name.includes(e.currentTarget.value),
    );
    setMonitoring(searchedItems);
  };

  const handleReset = () => {
    setMonitoring([]);
    setDrawer(false);
  };

  const handleSetDate = e => {
    setFilterDate(e.target.value);
  };

  const filterDrawer = () => (
    <div
      className={classes.drawerFilter}
      role="presentation"
      // onClick={toggleDrawer(false)}
      // onKeyDown={toggleDrawer(false)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            margin="normal"
            fullWidth
            required
            id="date"
            label={language.monitoring.form.seperationDate}
            type="date"
            name="filterDate"
            autoComplete="filterDate"
            onChange={handleSetDate}
            value={filterDate}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button
            onClick={handleFilter}
            variant="outlined"
            color="primary"
          >
            {language.action.set}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button
            onClick={handleReset}
            variant="outlined"
            color="secondary"
          >
            {language.action.reset}
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  const handleOpenDialog = value => {
    setMonitoringDetail(value);
    setOpen(true);
  };

  return (
    <div>
      <Grid container spacing={0}>
        {monitoring.length ? (
          <Grid item xs={6} sm={6} md={6} lg={3}>
            <div>
              <Box display="flex" flexDirection="row">
                <Box>
                  <TextField
                    id="outlined-basic"
                    placeholder={language.action.search}
                    variant="outlined"
                    onChange={e => handleSearch(e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box p={1}>
                  <Button
                    onClick={toggleDrawer(true)}
                    variant="outlined"
                    color="primary"
                  >
                    {language.action.filter}
                  </Button>
                </Box>
              </Box>
            </div>
          </Grid>
        ) : (
          <h2>{language.data.notFound}</h2>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="caption" gutterBottom>
            {total} {language.data.found}
          </Typography>
        </Grid>
        {monitoring.length ? (
          monitoring.map(x => (
            <Grid item key={x.id} xs={12} sm={12} md={12} lg={12}>
              <Paper
                className={classes.root}
                square
                elevation={0}
                style={{ borderBottom: '1px solid #e8e8e8' }}
              >
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    container
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      container
                      direction="column"
                      spacing={2}
                    >
                      <Grid item xs>
                        <Typography gutterBottom variant="subtitle1">
                          {x.worker ? x.worker.name : null}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >
                          {language.customer.detail.monitoring.worker}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={1}
                      lg={1}
                      container
                      direction="column"
                      spacing={2}
                    >
                      <Grid item xs>
                        <Tooltip
                          title={
                            x.quality === 0
                              ? 'Tidak ada sampah'
                              : x.quality === 1
                              ? 'Sampah tidak terpilah'
                              : x.quality === 2
                              ? 'Mencoba memilah'
                              : x.quality === 3
                              ? 'Sampah sudah terpilah sesuai ekspektasi'
                              : null
                          }
                        >
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                          >
                            {x.quality}
                          </Typography>
                        </Tooltip>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >
                          {
                            language.customer.detail.monitoring
                              .quality
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={1}
                      lg={1}
                      container
                      direction="column"
                      spacing={2}
                    >
                      <Grid item xs>
                        <Tooltip
                          title={
                            x.organic === 0
                              ? 'None'
                              : x.organic === 1
                              ? 'Mix'
                              : x.organic === 2
                              ? 'Try'
                              : x.organic === 3
                              ? 'Succeed'
                              : null
                          }
                        >
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                          >
                            {x.organic}
                          </Typography>
                        </Tooltip>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >
                          {
                            language.customer.detail.monitoring
                              .organic
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={1}
                      lg={1}
                      container
                      direction="column"
                      spacing={2}
                    >
                      <Grid item xs>
                        <Tooltip
                          title={
                            x.recycle === 0
                              ? 'None'
                              : x.recycle === 1
                              ? 'Mix'
                              : x.recycle === 2
                              ? 'Try'
                              : x.recycle === 3
                              ? 'Succeed'
                              : null
                          }
                        >
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                          >
                            {x.recycle}
                          </Typography>
                        </Tooltip>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >
                          {
                            language.customer.detail.monitoring
                              .recycle
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={1}
                      lg={1}
                      container
                      direction="column"
                      spacing={2}
                    >
                      <Grid item xs>
                        <Tooltip
                          title={
                            x.residu === 0
                              ? 'None'
                              : x.residu === 1
                              ? 'Mix'
                              : x.residu === 2
                              ? 'Try'
                              : x.residu === 3
                              ? 'Succeed'
                              : null
                          }
                        >
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                          >
                            {x.residu}
                          </Typography>
                        </Tooltip>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >
                          {
                            language.customer.detail.monitoring
                              .residue
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      container
                      direction="column"
                      spacing={2}
                    >
                      <Grid item xs>
                        <Typography gutterBottom variant="subtitle1">
                          {x.intervention}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >
                          {
                            language.customer.detail.monitoring
                              .intervention
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      container
                      direction="column"
                      spacing={2}
                    >
                      <Grid item xs>
                        <Typography gutterBottom variant="subtitle1">
                          {moment(x.seperation_date).format(
                            'dddd, DD-MM-YYYY',
                          )}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >
                          {
                            language.customer.detail.monitoring
                              .seperationDate
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      container
                      direction="column"
                      spacing={2}
                    >
                      <Grid item xs>
                        <Typography gutterBottom variant="subtitle1">
                          {moment(x.created_at).format(
                            'dddd, DD-MM-YYYY',
                          )}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >
                          {
                            language.customer.detail.monitoring
                              .createdAt
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={1} lg={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenDialog(x)}
                      >
                        {language.action.detail}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            container
            direction="column"
            spacing={2}
          >
            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                Data {language.alert.notFound}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Drawer
        anchor="right"
        open={drawer}
        onClose={toggleDrawer(false)}
      >
        {filterDrawer()}
      </Drawer>
      <TpsDialog
        data={monitoringDetail}
        open={open}
        language={language}
        onClose={() => {
          setOpen(false);
        }}
        type="customerMonitoring"
      />
    </div>
  );
};

export default UserMonitoring;
