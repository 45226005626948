import React, { useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import randomstring from 'randomstring';
import * as Yup from 'yup';
import faker from 'faker';
import { Formik } from 'formik';
import { setError } from '../util';
import { createTps } from '../gql';
import GlobalContext from '../globalContext';
import { loginStyles } from '../styles';
import ErrorText from './ErrorText';

export default function SignUp(props) {
  const classes = loginStyles();
  const { mutate, history } = props;
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>

        <Formik
          initialValues={{
            name: faker.company.companyName(),
            provinsi: faker.address.streetAddress(),
            kabupaten: faker.address.streetAddress(),
            kecamatan: faker.address.streetAddress(),
            kelurahan: faker.address.streetAddress(),
            desaAdat: faker.address.streetAddress(),
            banjar: faker.address.streetAddress(),
            dusun: faker.address.streetAddress(),
            jalan: faker.address.streetAddress(),
            adminName: faker.name.findName(),
            phonenumber: faker.phone.phoneNumber(),
            email: faker.internet.email(),
            password: randomstring.generate({
              length: 8,
              charset: 'alphanumeric',
            }),
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
            provinsi: Yup.string().required('Required'),
            kabupaten: Yup.string().required('Required'),
            kecamatan: Yup.string().required('Required'),
            kelurahan: Yup.string().required('Required'),
            desaAdat: Yup.string().required('Required'),
            banjar: Yup.string().required('Required'),
            dusun: Yup.string().required('Required'),
            jalan: Yup.string().required('Required'),
            adminName: Yup.string().required('Required'),
            phonenumber: Yup.string().required('Required'),
            email: Yup.string()
              .required('Required')
              .email('Invalid email'),
            password: Yup.string().required('Required'),
          })}
          onSubmit={async ({
            name,
            provinsi,
            kabupaten,
            kecamatan,
            kelurahan,
            desaAdat,
            banjar,
            dusun,
            jalan,
            adminName,
            phonenumber,
            email,
            password,
          }) => {
            try {
              setGlobalLoading(true);
              await mutate({
                mutation: createTps,
                variables: {
                  data: {
                    user: {
                      name: adminName,
                      email,
                      provinsi,
                      kabupaten,
                      kecamatan,
                      kelurahan,
                      desa_adat: desaAdat,
                      banjar,
                      dusun,
                      jalan,
                      password,
                      phone: phonenumber,
                    },
                    location: {
                      longitude: 115.2126,
                      latitude: -8.6705,
                    },
                    name,
                    provinsi,
                    kabupaten,
                    kecamatan,
                    kelurahan,
                    desa_adat: desaAdat,
                    banjar,
                    dusun,
                    jalan,
                    capacity: 0,
                  },
                },
                fetchPolicy: 'no-cache',
              });
              setGlobalLoading(false);
              setSnack({
                variant: 'success',
                message:
                  'You are successfully registered. Please wait for the admin confirmation!',
                opened: true,
              });
              history.replace('/login');
            } catch (error) {
              console.log('error login: ', error);
              setGlobalLoading(false);
              setError(setSnack, error);
            }
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            values,
            handleChange,
          }) => (
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    autoComplete="name"
                    autoFocus
                    name="name"
                    variant="outlined"
                    required
                    fullWidth
                    id="name"
                    label="TPS Name"
                    onChange={handleChange}
                    onKeyPress={e => {
                      if (e.nativeEvent.keyCode === 13)
                        handleSubmit();
                    }}
                    value={values.name}
                  />
                  {errors.name && touched.name && (
                    <ErrorText text={errors.name} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    name="provinsi"
                    variant="outlined"
                    required
                    fullWidth
                    id="provinsi"
                    label="Provinsi"
                    multiline
                    onChange={handleChange}
                    onKeyPress={e => {
                      if (e.nativeEvent.keyCode === 13)
                        handleSubmit();
                    }}
                    value={values.provinsi}
                  />
                  {errors.provinsi && touched.provinsi && (
                    <ErrorText text={errors.provinsi} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    name="kabupaten"
                    variant="outlined"
                    required
                    fullWidth
                    id="kabupaten"
                    label="Kabupatem"
                    multiline
                    onChange={handleChange}
                    onKeyPress={e => {
                      if (e.nativeEvent.keyCode === 13)
                        handleSubmit();
                    }}
                    value={values.kabupaten}
                  />
                  {errors.kabupaten && touched.kabupaten && (
                    <ErrorText text={errors.kabupaten} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    name="kecamatan"
                    variant="outlined"
                    required
                    fullWidth
                    id="kecamatan"
                    label="Kecamatan"
                    multiline
                    onChange={handleChange}
                    onKeyPress={e => {
                      if (e.nativeEvent.keyCode === 13)
                        handleSubmit();
                    }}
                    value={values.kecamatan}
                  />
                  {errors.kecamatan && touched.kecamatan && (
                    <ErrorText text={errors.kecamatan} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    name="kelurahan"
                    variant="outlined"
                    required
                    fullWidth
                    id="kelurahan"
                    label="Kelurahan"
                    multiline
                    onChange={handleChange}
                    onKeyPress={e => {
                      if (e.nativeEvent.keyCode === 13)
                        handleSubmit();
                    }}
                    value={values.kelurahan}
                  />
                  {errors.kelurahan && touched.kelurahan && (
                    <ErrorText text={errors.kelurahan} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    name="desaAdat"
                    variant="outlined"
                    required
                    fullWidth
                    id="desaAdat"
                    label="Desa Adat"
                    multiline
                    onChange={handleChange}
                    onKeyPress={e => {
                      if (e.nativeEvent.keyCode === 13)
                        handleSubmit();
                    }}
                    value={values.desaAdat}
                  />
                  {errors.desaAdat && touched.desaAdat && (
                    <ErrorText text={errors.desaAdat} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    name="banjar"
                    variant="outlined"
                    required
                    fullWidth
                    id="banjar"
                    label="Banjar"
                    multiline
                    onChange={handleChange}
                    onKeyPress={e => {
                      if (e.nativeEvent.keyCode === 13)
                        handleSubmit();
                    }}
                    value={values.banjar}
                  />
                  {errors.banjar && touched.banjar && (
                    <ErrorText text={errors.banjar} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    name="dusun"
                    variant="outlined"
                    required
                    fullWidth
                    id="dusun"
                    label="Dusun"
                    multiline
                    onChange={handleChange}
                    onKeyPress={e => {
                      if (e.nativeEvent.keyCode === 13)
                        handleSubmit();
                    }}
                    value={values.dusun}
                  />
                  {errors.dusun && touched.dusun && (
                    <ErrorText text={errors.dusun} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    name="jalan"
                    variant="outlined"
                    required
                    fullWidth
                    id="jalan"
                    label="Jalan"
                    multiline
                    onChange={handleChange}
                    onKeyPress={e => {
                      if (e.nativeEvent.keyCode === 13)
                        handleSubmit();
                    }}
                    value={values.jalan}
                  />
                  {errors.jalan && touched.jalan && (
                    <ErrorText text={errors.jalan} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    autoComplete="adminName"
                    name="adminName"
                    variant="outlined"
                    required
                    fullWidth
                    id="adminName"
                    label="Admin Name"
                    onChange={handleChange}
                    onKeyPress={e => {
                      if (e.nativeEvent.keyCode === 13)
                        handleSubmit();
                    }}
                    value={values.adminName}
                  />
                  {errors.adminName && touched.adminName && (
                    <ErrorText text={errors.adminName} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    name="phonenumber"
                    variant="outlined"
                    required
                    fullWidth
                    id="phonenumber"
                    label="Phone Number"
                    onChange={handleChange}
                    onKeyPress={e => {
                      if (e.nativeEvent.keyCode === 13)
                        handleSubmit();
                    }}
                    value={values.phonenumber}
                  />
                  {errors.phonenumber && touched.phonenumber && (
                    <ErrorText text={errors.phonenumber} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    onChange={handleChange}
                    onKeyPress={e => {
                      if (e.nativeEvent.keyCode === 13)
                        handleSubmit();
                    }}
                    value={values.email}
                  />
                  {errors.email && touched.email && (
                    <ErrorText text={errors.email} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onKeyPress={e => {
                      if (e.nativeEvent.keyCode === 13)
                        handleSubmit();
                    }}
                    value={values.password}
                  />
                  {errors.password && touched.password && (
                    <ErrorText text={errors.password} />
                  )}
                </Grid>
              </Grid>
              <Button
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign Up
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link
                    variant="body2"
                    component="button"
                    onClick={() => history.replace('/login')}
                  >
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
