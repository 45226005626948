import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Grid,
  Paper,
  Fab,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

import { GET_USERS } from '../../graphql/UserQuery';
import AvatarList from '../Custom/AvatarList';
import GlobalContext from '../../globalContext';
import { isMobile } from '../../util';
import { globalStyles } from '../../styles';

const UserPage = props => {
  const classes = globalStyles();
  const { query, language, history } = props;
  const [users, setUsers] = useState([]);
  const [fixedUsers, setFixedUsers] = useState([]);
  const { setGlobalLoading, setTitle } = useContext(GlobalContext);

  useEffect(() => {
    setTitle(language.customer.title.base);
    setGlobalLoading(true);

    const getUsers = async () => {
      const {
        data: { users },
      } = await query({
        query: GET_USERS,
        variables: { position: 'CUSTOMER' },
        fetchPolicy: 'no-cache',
      });
      console.log('am I called: ', users.length);

      if (users) {
        const dataUsers = users.map((e, i) => ({
          no: `${i + 1}.`,
          name: e.name,
          email: e.email,
          size: e.size,
          lane: e.lane,
          fee: e.service_fee,
          status: e.status,
          detail: e.uuid,
        }));

        setUsers(dataUsers);
        setFixedUsers(dataUsers);
        setTimeout(() => setGlobalLoading(false), 1500);
      } else {
        setTimeout(() => setGlobalLoading(false), 1500);
      }
    };

    getUsers();
  }, [
    setUsers,
    setGlobalLoading,
    query,
    setTitle,
    language.customer.title.base,
  ]);

  const handleSearch = e => {
    const finds = [...fixedUsers].filter(
      x =>
        x.name
          .toLowerCase()
          .includes(e.currentTarget.value.toLowerCase()) ||
        x.email
          .toLowerCase()
          .includes(e.currentTarget.value.toLowerCase()),
    );
    setUsers(finds);
  };
  return (
    <div>
      <Grid container direction="row">
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="baseline"
        >
          <TextField
            id="outlined-basic"
            label={language.action.search}
            variant="outlined"
            size="small"
            fullWidth
            onChange={e => handleSearch(e)}
          />
        </Grid>
        {!isMobile() && (
          <Grid
            container
            direction="column"
            justify="flex-end"
            alignItems="baseline"
          >
            <Button
              className={classes.addButton}
              component={Link}
              to="/user/new"
              variant="contained"
              color="primary"
            >
              {language.action.add}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper style={{ marginTop: 20 }}>
          {users.length ? (
            <AvatarList
              data={users}
              mappingColumns={{
                primary: 'name',
                secondary: 'email',
                tertiaryOutlined: 'status',
                id: 'detail',
              }}
              detailLink="/user/detail/"
              editLink="/user/edit/"
              history={history}
            />
          ) : (
            <div />
          )}
        </Paper>
        {isMobile() && (
          <Fab
            color="primary"
            aria-label="add"
            component={Link}
            to="/user/new"
            variant="round"
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
            }}
          >
            <AddIcon />
          </Fab>
        )}
      </Grid>
    </div>
  );
};

export default UserPage;
