import React, { useEffect, useContext, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import localization from 'moment/locale/id';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import GlobalContext from '../../globalContext';
import {
  TOTAL_CUSTOMER,
  BARCHART_EXPENDITURE,
  DETAIL_BARCHART_EXPENDITURE,
  BARCHART_SALES_COMPOST,
  DETAIL_BARCHART_SALES_COMPOST,
} from '../../graphql/DashboardQuery';
import TpsDialog from '../Custom/TpsDialog';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    textAlign: 'center',
  },
  pos: {
    marginBottom: 12,
  },
  dashboardContent: {
    textAlign: 'center',
    fontSize: '24px',
  },
});

const DashboardOperasional = props => {
  const { query, language } = props;
  const classes = useStyles();
  const { setTitle } = useContext(GlobalContext);
  const now = new Date();
  const thisMonth = moment(now)
    .locale('id', localization)
    .format("MMM 'YY");
  const [open, setOpen] = useState(false);
  const [totalCustomer, setTotalCustomer] = useState(0);
  const [barchartExpenditure, setBarchartExpenditure] = useState([]);
  const [detailBarchart, setDetailBarchart] = useState([]);
  const [barchartSalesCompost, setBarchartSalesCompost] = useState(
    [],
  );

  const dataTotalCustomer = useQuery(TOTAL_CUSTOMER, {
    variables: {
      role_id: 2,
    },
  });

  const dataChartExpenditure = useQuery(BARCHART_EXPENDITURE, {
    variables: {
      year: new Date().getFullYear().toString(),
    },
  });

  const dataChartSalesCompost = useQuery(BARCHART_SALES_COMPOST, {
    variables: {
      year: '2019',
    },
  });

  useEffect(() => {
    setTitle(language.sidebar.dashops);
    if (dataTotalCustomer.data) {
      setTotalCustomer(dataTotalCustomer.data.totalCustomer.count);
    }
  }, [
    setTitle,
    language.sidebar.dashboard,
    dataTotalCustomer,
    language.sidebar.dashops,
  ]);

  useEffect(() => {
    if (dataChartExpenditure.data) {
      const data = dataChartExpenditure.data.barChartExpenditure.map(
        x => ({
          name: moment(`${x.y}-${x.m}-01`).format('MMM, YYYY'),
          total: x.sum,
        }),
      );
      setBarchartExpenditure(data);
    }
  }, [dataChartExpenditure]);

  useEffect(() => {
    if (dataChartSalesCompost.data) {
      const data = dataChartSalesCompost.data.barChartSalesCompost.map(
        x => ({
          name: moment(`${x.y}-${x.m}-01`).format('MMM, YYYY'),
          total: x.sum,
        }),
      );
      setBarchartSalesCompost(data);
    }
  }, [dataChartSalesCompost]);

  const getDetailChartExpenditure = yearMonth => {
    const getDetailChart = async () => {
      const year = moment(yearMonth).format('YYYY');
      const month = moment(yearMonth).format('MM');
      const {
        data: { detailChartExpenditure },
      } = await query({
        query: DETAIL_BARCHART_EXPENDITURE,
        variables: {
          year,
          month,
        },
        fetchPolicy: 'no-cache',
      });

      const data = detailChartExpenditure.map(x => ({
        name: moment(x.payment_at).format('DD/M'),
        total: x.sum,
      }));

      setDetailBarchart(data);
    };

    getDetailChart();
  };

  const getDetailChartSalesCompost = yearMonth => {
    const getDetailChart = async () => {
      const year = moment(yearMonth).format('YYYY');
      const month = moment(yearMonth).format('MM');
      const {
        data: { detailChartSalesCompost },
      } = await query({
        query: DETAIL_BARCHART_SALES_COMPOST,
        variables: {
          year,
          month,
        },
        fetchPolicy: 'no-cache',
      });

      const data = detailChartSalesCompost.map(x => ({
        name: moment(x.payment_at).format('DD/M'),
        total: x.sum,
      }));

      setDetailBarchart(data);
    };

    getDetailChart();
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h6"
                gutterBottom
              >
                {`${language.dashops.monthlySales} ${thisMonth}`}
              </Typography>
              <Typography
                className={classes.dashboardContent}
                variant="h3"
                component="h2"
              >
                {totalCustomer || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h6"
                gutterBottom
              >
                {`${language.dashops.monthlyCompost} ${thisMonth}`}
              </Typography>
              <Typography
                className={classes.dashboardContent}
                variant="h3"
                component="h2"
              >
                {totalCustomer || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h6"
                gutterBottom
              >
                {`${language.dashops.monthlyContribution} ${thisMonth}`}
              </Typography>
              <Typography
                className={classes.dashboardContent}
                variant="h3"
                component="h2"
              >
                {totalCustomer || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h6"
                gutterBottom
              >
                {`${language.dashops.monthlyExpense} ${thisMonth}`}
              </Typography>
              <Typography
                className={classes.dashboardContent}
                variant="h3"
                component="h2"
              >
                {totalCustomer || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h6"
                gutterBottom
              >
                Total Pengeluaran Operasional
              </Typography>
              <div
                style={{
                  paddingBottom: '56.25%' /* 16:9 */,
                  position: 'relative',
                  height: 0,
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <ResponsiveContainer>
                    <BarChart
                      width={800}
                      height={300}
                      data={barchartExpenditure}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      onClick={async e => {
                        const yearMonth = moment(
                          e.activeLabel,
                        ).format('YYYY-MM');
                        await getDetailChartExpenditure(yearMonth);
                        setOpen(true);
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip
                        formatter={value =>
                          `Rp. ${new Intl.NumberFormat('en').format(
                            value,
                          )}`
                        }
                      />
                      <Legend />
                      <Bar dataKey="total" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h6"
                gutterBottom
              >
                Total Penjualan Kompos
              </Typography>
              <div
                style={{
                  paddingBottom: '56.25%' /* 16:9 */,
                  position: 'relative',
                  height: 0,
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <ResponsiveContainer>
                    <BarChart
                      width={800}
                      height={300}
                      data={barchartSalesCompost}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      onClick={async e => {
                        const yearMonth = moment(
                          e.activeLabel,
                        ).format('YYYY-MM');
                        await getDetailChartSalesCompost(yearMonth);
                        setOpen(true);
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip
                        formatter={value =>
                          `Rp. ${new Intl.NumberFormat('en').format(
                            value,
                          )}`
                        }
                      />
                      <Legend />
                      <Bar dataKey="total" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <TpsDialog
        data={detailBarchart}
        open={open}
        language={language}
        onClose={() => {
          setOpen(false);
        }}
        type="detailBarchartRecyclable"
      />
    </div>
  );
};

export default DashboardOperasional;
