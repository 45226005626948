import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { blue, red, green } from '@material-ui/core/colors';
import GlobalContext from '../../globalContext';
import SubmitCancel from '../Custom/SubmitCancelBtn';
import { rupia } from '../../util';
import {
  GET_SALE_COMPOST,
  DELETE_SALE,
} from '../../graphql/ProcessingQuery';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  greenClass: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
  },
  redClass: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
  },
  blueClass: {
    color: theme.palette.getContrastText(blue[600]),
    backgroundColor: blue[600],
  },
}));

export default function RecycableDetail(props) {
  const classes = useStyles();
  const { query, history, language, match, mutate } = props;
  const { uuid } = match.params;
  const [saler, setSale] = useState(null);
  const { setGlobalLoading, setTitle } = useContext(GlobalContext);

  useEffect(() => {
    const getInitial = async () => {
      setGlobalLoading(true);
      setTitle(language.processing.recycableDetail);
      const {
        data: { sale },
      } = await query({
        query: GET_SALE_COMPOST,
        variables: { uuid },
        fetchPolicy: 'no-cache',
      });
      console.log('sale is: ', sale);
      setSale(sale);
      setTimeout(() => setGlobalLoading(false), 1500);
    };
    getInitial();
  }, [
    language.processing.recycableDetail,
    query,
    setGlobalLoading,
    setTitle,
    uuid,
  ]);

  const confirmDelete = async () => {
    if (
      window.confirm(
        'Are you sure want to delete this compost income?',
      )
    ) {
      setGlobalLoading(true);
      await mutate({
        mutation: DELETE_SALE,
        variables: { uuid: saler.uuid },
        fetchPolicy: 'no-cache',
      });
      setTimeout(() => setGlobalLoading(false), 1000);
      history.replace('/income/compost');
    }
  };

  return (
    <Grid container spacing={2}>
      {saler && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h5">Data Penjualan</Typography>
            <Typography variant="body1">
              Tanggal Jual: {moment(saler.payment_at).format('LL')}
            </Typography>
            <Typography variant="body1">
              Jenis Organik: {saler.material_from}
            </Typography>
            <Typography variant="body1">
              Pembeli: {saler.sell_to}
            </Typography>
            <Typography variant="body1">
              Penjual: {saler.worker.name}
            </Typography>
            <Typography variant="body1">
              Berat: {saler.sales_details[0].weight}{' '}
              {saler.sales_details[0].weight_unit}
            </Typography>
            <Typography variant="body1">
              Jumlah: {saler.sales_details[0].quantity}{' '}
              {saler.sales_details[0].quantity_unit}
            </Typography>
            <Typography variant="body1">
              Total Penjualan: {rupia(saler.total)}
            </Typography>
          </Grid>
          <SubmitCancel
            submitClass={classes.redClass}
            submitClick={confirmDelete}
            submitTxt={language.action.delete}
            cancelTxt={language.action.back}
            isCancelLink
            cancelTo="/income/compost"
          />
        </>
      )}
    </Grid>
  );
}
