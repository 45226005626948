import { makeStyles } from '@material-ui/core/styles';
import { green, blue, red } from '@material-ui/core/colors';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 16,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  palette: {
    primary: green,
  },
  greenClass: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
  },
  redClass: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
  },
  blueClass: {
    color: theme.palette.getContrastText(blue[600]),
    backgroundColor: blue[600],
  },
}));

const loginStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export { loginStyles };

const paperStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
}));
export { paperStyles };

const selectStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export { selectStyles };

const globalStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  saveButton: {
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'green',
    },
    color: 'white',
    marginTop: 20,
    marginRight: 10,
  },
  addButton: {
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'green',
    },
    color: 'white',
  },
  actionButton: {
    margin: theme.spacing(0),
  },
  chipActive: {
    color: 'white',
    backgroundColor: 'green',
  },
}));
export { globalStyles };

const userDetailStyle = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  baseTitle: {
    fontSize: '12px',
    color: 'grey',
  },
  titleCustomer: {
    fontSize: '24px',
    fontWeight: 700,
  },
  saveButton: {
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'green',
    },
    color: 'white',
    marginTop: 20,
    marginRight: 10,
  },
  tab: {
    borderBottom: '1px solid #e8e8e8',
  },
  drawerFilter: {
    width: 250,
    margin: 20,
  },
}));
export { userDetailStyle };

const monitoringPage = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  labelAsyncSelect: {
    position: 'relative',
  },
}));
export { monitoringPage };

export default useStyles;
