/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { globalStyles } from '../../styles';
import { GET_TPS, UPDATE_TPS } from '../../graphql/TpsQuery';
import TpsMapBox from '../Custom/TpsMapBox';
import SubmitCancel from '../Custom/SubmitCancelBtn';
import GlobalContext from '../../globalContext';
import ErrorText from '../ErrorText';
import { phoneRegExp } from '../../util';

const TpsDetail = props => {
  const { history, match, query, language, editable, mutate } = props;
  const { uuid } = match.params;
  const { setSnack, setGlobalLoading, setTitle } = useContext(
    GlobalContext,
  );
  const readOnly = !editable;
  const classes = globalStyles();
  const [tps, setTps] = useState({
    name: '',
    jalan: '',
    dusun: '',
    banjar: '',
    desa_adat: '',
    kelurahan: '',
    kecamatan: '',
    kabupaten: '',
    provinsi: '',
    capacity: '',
    location: {
      latitude: 0,
      longitude: 0,
    },
    user: {
      name: '',
      email: '',
      jalan: '',
      dusun: '',
      banjar: '',
      desa_adat: '',
      kelurahan: '',
      kecamatan: '',
      kabupaten: '',
      provinsi: '',
      phone: '',
      status: '',
      jabatan: '',
    },
  });
  const weightUnits = [
    { value: 'Kilogram', label: 'Kilogram' },
    { value: 'Ton', label: 'Ton' },
  ];

  useEffect(() => {
    setTitle(language.tps.title.detail);
  }, [setTitle, language.tps.title.detail]);

  useEffect(() => {
    setGlobalLoading(true);
    const getTps = async () => {
      const {
        data: { tps },
      } = await query({
        query: GET_TPS,
        variables: {
          uuid,
        },
        fetchPolicy: 'no-cache',
      });

      if (tps) {
        setTps(tps);
        setGlobalLoading(false);
      } else {
        setGlobalLoading(false);
        setSnack({
          variant: 'error',
          message: `TPS ${language.alert.notFound}`,
          opened: true,
        });
      }
    };

    getTps();
  }, [uuid, query, setSnack, setGlobalLoading, language.alert.notFound]);

  const DetailPage = () => {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <Typography variant="h5">{tps.name}</Typography>
            <Grid container spacing={2} style={{ marginTop: 20 }}>
              <Grid xs={6} sm={6} md={3} lg={3} item>
                <Grid spacing={0} direction="column" container>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="subtitle2">
                      {language.tps.form.tps.address.provinsi}
                    </Typography>
                  </Grid>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="body2">
                      {tps.provinsi}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} item>
                <Grid spacing={0} direction="column" container>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="subtitle2">
                      {language.tps.form.tps.address.kabupaten}
                    </Typography>
                  </Grid>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="body2">
                      {tps.kabupaten}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} item>
                <Grid spacing={0} direction="column" container>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="subtitle2">
                      {language.tps.form.tps.address.kecamatan}
                    </Typography>
                  </Grid>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="body2">
                      {tps.kecamatan}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} item>
                <Grid spacing={0} direction="column" container>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="subtitle2">
                      {language.tps.form.tps.address.kelurahan}
                    </Typography>
                  </Grid>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="body2">
                      {tps.kelurahan}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} item>
                <Grid spacing={0} direction="column" container>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="subtitle2">
                      {language.tps.form.tps.address.desaAdat}
                    </Typography>
                  </Grid>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="body2">
                      {tps.desa_adat}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} item>
                <Grid spacing={0} direction="column" container>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="subtitle2">
                      {language.tps.form.tps.address.banjar}
                    </Typography>
                  </Grid>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="body2">
                      {tps.banjar}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} item>
                <Grid spacing={0} direction="column" container>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="subtitle2">
                      {language.tps.form.tps.address.dusun}
                    </Typography>
                  </Grid>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="body2">
                      {tps.dusun}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} item>
                <Grid spacing={0} direction="column" container>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="subtitle2">
                      {language.tps.form.tps.address.jalan}
                    </Typography>
                  </Grid>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="body2">
                      {tps.jalan}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} item>
                <Grid spacing={0} direction="column" container>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="subtitle2">
                      {language.tps.form.tps.capacity}
                    </Typography>
                  </Grid>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="body2">
                      {tps.capacity} {tps.capacity_unit}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} item>
                <Grid spacing={0} direction="column" container>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="subtitle2">
                      {language.date.createdAt}
                    </Typography>
                  </Grid>
                  <Grid style={{ flex: 1 }} item>
                    <Typography variant="body2">
                      {moment(tps.created_at).format(
                        'dddd, DD-MM-YYYY',
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {tps.user ? (
              <div>
                <Typography variant="h6" style={{ marginTop: 20 }}>
                  {language.tps.form.user.title}
                </Typography>
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                  <Grid xs={6} sm={6} md={3} lg={3} item>
                    <Grid spacing={0} direction="column" container>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="subtitle2">
                          {language.tps.form.user.name}
                        </Typography>
                      </Grid>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="body2">
                          {tps.user.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={6} sm={6} md={3} lg={3} item>
                    <Grid spacing={0} direction="column" container>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="subtitle2">
                          {language.tps.form.user.email}
                        </Typography>
                      </Grid>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="body2">
                          {tps.user.email}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={6} sm={6} md={3} lg={3} item>
                    <Grid spacing={0} direction="column" container>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="subtitle2">
                          {language.tps.form.user.telephone}
                        </Typography>
                      </Grid>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="body2">
                          {tps.user.phone ? tps.user.phone : '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={6} sm={6} md={3} lg={3} item>
                    <Grid spacing={0} direction="column" container>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="subtitle2">
                          {language.tps.form.user.jabatan}
                        </Typography>
                      </Grid>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="body2">
                          {tps.user.jabatan ? tps.user.jabatan : '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={6} sm={6} md={3} lg={3} item>
                    <Grid spacing={0} direction="column" container>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="subtitle2">
                          {language.tps.form.tps.address.provinsi}
                        </Typography>
                      </Grid>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="body2">
                          {tps.user.provinsi}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={6} sm={6} md={3} lg={3} item>
                    <Grid spacing={0} direction="column" container>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="subtitle2">
                          {language.tps.form.tps.address.kabupaten}
                        </Typography>
                      </Grid>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="body2">
                          {tps.user.kabupaten}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={6} sm={6} md={3} lg={3} item>
                    <Grid spacing={0} direction="column" container>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="subtitle2">
                          {language.tps.form.tps.address.kecamatan}
                        </Typography>
                      </Grid>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="body2">
                          {tps.user.kecamatan}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={6} sm={6} md={3} lg={3} item>
                    <Grid spacing={0} direction="column" container>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="subtitle2">
                          {language.tps.form.tps.address.kelurahan}
                        </Typography>
                      </Grid>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="body2">
                          {tps.user.kelurahan}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={6} sm={6} md={3} lg={3} item>
                    <Grid spacing={0} direction="column" container>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="subtitle2">
                          {language.tps.form.tps.address.desaAdat}
                        </Typography>
                      </Grid>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="body2">
                          {tps.user.desa_adat}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={6} sm={6} md={3} lg={3} item>
                    <Grid spacing={0} direction="column" container>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="subtitle2">
                          {language.tps.form.tps.address.banjar}
                        </Typography>
                      </Grid>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="body2">
                          {tps.user.banjar}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={6} sm={6} md={3} lg={3} item>
                    <Grid spacing={0} direction="column" container>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="subtitle2">
                          {language.tps.form.tps.address.dusun}
                        </Typography>
                      </Grid>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="body2">
                          {tps.user.dusun}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={6} sm={6} md={3} lg={3} item>
                    <Grid spacing={0} direction="column" container>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="subtitle2">
                          {language.tps.form.tps.address.jalan}
                        </Typography>
                      </Grid>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="body2">
                          {tps.user.jalan}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={6} sm={6} md={3} lg={3} item>
                    <Grid spacing={0} direction="column" container>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="subtitle2">
                          {language.date.registrationDate}
                        </Typography>
                      </Grid>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="body2">
                          {moment(tps.user.registration_date).format(
                            'dddd, DD-MM-YYYY',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={6} sm={6} md={3} lg={3} item>
                    <Grid spacing={0} direction="column" container>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="subtitle2">
                          {language.date.createdAt}
                        </Typography>
                      </Grid>
                      <Grid style={{ flex: 1 }} item>
                        <Typography variant="body2">
                          {moment(tps.user.created_at).format(
                            'dddd, DD-MM-YYYY',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            ) : null}
            <Button
              component={Link}
              to="/tps/list"
              style={{
                marginTop: 20,
              }}
              variant="outlined"
              color="default"
            >
              {language.action.back}
            </Button>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <TpsMapBox
              location={{
                latitude: tps.location.latitude,
                longitude: tps.location.longitude,
                tps,
              }}
              overrideViewport={{
                longitude: tps.location.longitude,
                latitude: tps.location.latitude,
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const EditPage = () => {
    return (
      <div>
        <Grid container spacing={3}>
          <Formik
            enableReinitialize
            initialValues={{
              ...tps,
              latitude: tps.location.latitude,
              longitude: tps.location.longitude,
              capacityUnit: tps.capacity_unit,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required(language.validate.required),
              capacity: Yup.string().required(
                language.validate.required,
              ),
              capacityUnit: Yup.string().required(
                language.validate.required,
              ),
              latitude: Yup.number()
                .required(language.validate.required)
                .min(-90, language.validate.latitudeRange)
                .max(90, language.validate.latitudeRange)
                .typeError(language.validate.latitudeRange),
              longitude: Yup.number()
                .required(language.validate.required)
                .min(-180, language.validate.longitudeRange)
                .max(180, language.validate.longitudeRange)
                .typeError(language.validate.longitudeRange),
              user: Yup.object().shape({
                name: Yup.string().required(
                  language.validate.required,
                ),
                phone: Yup.string()
                  .required(language.validate.required)
                  .matches(phoneRegExp, language.validate.phone),
              }),
            })}
            onSubmit={async (tps, { resetForm }) => {
              try {
                const data = {
                  id: tps.id,
                  name: tps.name,
                  jalan: tps.jalan,
                  dusun: tps.dusun,
                  banjar: tps.banjar,
                  desa_adat: tps.desa_adat,
                  kelurahan: tps.kelurahan,
                  kecamatan: tps.kecamatan,
                  kabupaten: tps.kabupaten,
                  provinsi: tps.provinsi,
                  location: {
                    latitude: parseFloat(tps.latitude),
                    longitude: parseFloat(tps.longitude),
                  },
                  capacity: parseFloat(tps.capacity),
                  capacity_unit: tps.capacityUnit,
                  user: {
                    id: tps.user.id,
                    name: tps.user.name,
                    email: tps.user.email,
                    phone: tps.user.phone,
                    jabatan: tps.user.jabatan,
                    provinsi: tps.user.provinsi,
                    kabupaten: tps.user.kabupaten,
                    kecamatan: tps.user.kecamatan,
                    kelurahan: tps.user.kelurahan,
                    desa_adat: tps.user.desa_adat,
                    banjar: tps.user.banjar,
                    dusun: tps.user.dusun,
                    jalan: tps.user.jalan,
                  },
                };

                await mutate({
                  mutation: UPDATE_TPS,
                  variables: { uuid, data },
                  fetchPolicy: 'no-cache',
                  onError: error => {
                    console.log('adakah error? ', error);
                  },
                });
                setGlobalLoading(true);
                setSnack({
                  variant: 'success',
                  message: `${tps.name} berhasil diubah!`,
                  opened: true,
                });

                resetForm();
                history.replace('/tps/list');
              } catch (error) {
                console.log('Error create TPS', error);
                setSnack({
                  variant: 'error',
                  message: 'Gagal mengubah TPS!',
                  opened: true,
                });
              }
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              values,
              handleChange,
              setFieldValue,
            }) => (
              <>
                <Grid item lg={7} md={7} sm={12} xs={12}>
                  <form>
                    <Paper className={classes.root}>
                      <h2>{language.tps.form.tps.title}</h2>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            autoFocus
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label={language.tps.form.tps.name}
                            name="name"
                            onChange={handleChange}
                            value={values.name}
                          />
                          {errors.name && touched.name && (
                            <ErrorText text={errors.name} />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="capacity"
                            label={language.tps.form.tps.capacity}
                            name="capacity"
                            onChange={handleChange}
                            value={values.capacity}
                          />
                          {errors.capacity && touched.capacity && (
                            <ErrorText text={errors.capacity} />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <FormControl margin="normal" fullWidth>
                            <InputLabel id="capacityUnit">
                              Satuan
                            </InputLabel>
                            <Select
                              margin="normal"
                              fullWidth
                              required
                              labelId="capacityUnit"
                              id="capacityUnit"
                              name="capacityUnit"
                              value={values.capacityUnit}
                              onChange={handleChange}
                            >
                              {weightUnits.map((z, i) => (
                                <MenuItem key={i} value={z.value}>
                                  <Typography variant="body2">
                                    {z.label}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {errors.capacityUnit &&
                            touched.capacityUnit && (
                              <ErrorText text={errors.capacityUnit} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="latitude"
                            label={language.tps.form.tps.latitude}
                            name="latitude"
                            autoComplete="latitude"
                            onChange={handleChange}
                            value={values.latitude}
                          />
                          {errors.latitude && touched.latitude && (
                            <ErrorText text={errors.latitude} />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="longitude"
                            label={language.tps.form.tps.longitude}
                            name="longitude"
                            autoComplete="longitude"
                            onChange={handleChange}
                            value={values.longitude}
                          />
                          {errors.longitude && touched.longitude && (
                            <ErrorText text={errors.longitude} />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="provinsi"
                            label={
                              language.tps.form.tps.address.provinsi
                            }
                            name="provinsi"
                            onChange={handleChange}
                            value={values.provinsi}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="kabupaten"
                            label={
                              language.tps.form.tps.address.kabupaten
                            }
                            name="kabupaten"
                            onChange={handleChange}
                            value={values.kabupaten}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="kecamatan"
                            label={
                              language.tps.form.tps.address.kecamatan
                            }
                            name="kecamatan"
                            onChange={handleChange}
                            value={values.kecamatan}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="kelurahan"
                            label={
                              language.tps.form.tps.address.kelurahan
                            }
                            name="kelurahan"
                            onChange={handleChange}
                            value={values.kelurahan}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="desa_adat"
                            label={
                              language.tps.form.tps.address.desaAdat
                            }
                            name="desa_adat"
                            onChange={handleChange}
                            value={values.desa_adat}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={2}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="banjar"
                            label={
                              language.tps.form.tps.address.banjar
                            }
                            name="banjar"
                            onChange={handleChange}
                            value={values.banjar}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={2}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="dusun"
                            label={
                              language.tps.form.tps.address.dusun
                            }
                            name="dusun"
                            onChange={handleChange}
                            value={values.dusun}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="jalan"
                            label={
                              language.tps.form.tps.address.jalan
                            }
                            name="jalan"
                            onChange={handleChange}
                            value={values.jalan}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                    <br />
                    <Paper className={classes.root}>
                      <h2>{language.tps.form.user.title}</h2>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label={language.tps.form.user.name}
                            name="user.name"
                            onChange={handleChange}
                            value={values.user.name}
                          />
                          {errors.user &&
                            errors.user.name &&
                            touched.user &&
                            touched.user.name && (
                              <ErrorText text={errors.user.name} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={language.tps.form.user.email}
                            name="user.email"
                            onChange={handleChange}
                            value={values.user.email}
                          />
                          {errors.user &&
                            errors.user.email &&
                            touched.user &&
                            touched.user.email && (
                              <ErrorText text={errors.user.email} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="telephone"
                            label={language.tps.form.user.telephone}
                            name="user.phone"
                            onChange={handleChange}
                            value={values.user.phone}
                          />
                          {errors.user &&
                            errors.user.phone &&
                            touched.user &&
                            touched.user.phone && (
                              <ErrorText text={errors.user.phone} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="jabatan"
                            label={language.tps.form.user.jabatan}
                            name="user.jabatan"
                            onChange={handleChange}
                            value={values.user.jabatan}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="userProvinsi"
                            label={
                              language.tps.form.tps.address.provinsi
                            }
                            name="user.provinsi"
                            onChange={handleChange}
                            value={values.user.provinsi}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="userKabupaten"
                            label={
                              language.tps.form.tps.address.kabupaten
                            }
                            name="user.kabupaten"
                            onChange={handleChange}
                            value={values.user.kabupaten}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="userKecamatan"
                            label={
                              language.tps.form.tps.address.kecamatan
                            }
                            name="user.kecamatan"
                            onChange={handleChange}
                            value={values.user.kecamatan}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="userKelurahan"
                            label={
                              language.tps.form.tps.address.kelurahan
                            }
                            name="user.kelurahan"
                            onChange={handleChange}
                            value={values.user.kelurahan}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="userDesaAdat"
                            label={
                              language.tps.form.tps.address.desaAdat
                            }
                            name="user.desa_adat"
                            onChange={handleChange}
                            value={values.user.desa_adat}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={2}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="userBanjar"
                            label={
                              language.tps.form.tps.address.banjar
                            }
                            name="user.banjar"
                            onChange={handleChange}
                            value={values.user.banjar}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={2}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="userDusun"
                            label={
                              language.tps.form.tps.address.dusun
                            }
                            name="user.dusun"
                            onChange={handleChange}
                            value={values.user.dusun}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="userJalan"
                            label={
                              language.tps.form.tps.address.jalan
                            }
                            name="user.jalan"
                            onChange={handleChange}
                            value={values.user.jalan}
                          />
                        </Grid>
                        <SubmitCancel
                          submitClass={classes.saveButton}
                          submitClick={handleSubmit}
                          submitTxt={language.action.update}
                          cancelTxt={language.action.back}
                          isCancelLink
                          cancelTo="/tps/list"
                        />
                      </Grid>
                    </Paper>
                  </form>
                </Grid>
                <Grid item lg={5} md={5} sm={12} xs={12}>
                  <TpsMapBox
                    location={{
                      longitude: values.longitude,
                      latitude: values.latitude,
                    }}
                    setLongLat={({ latitude, longitude }) => {
                      setFieldValue('latitude', latitude);
                      setFieldValue('longitude', longitude);
                    }}
                    editable
                  />
                </Grid>
              </>
            )}
          </Formik>
        </Grid>
      </div>
    );
  };

  return <div>{readOnly ? <DetailPage /> : <EditPage />}</div>;
};

export default TpsDetail;
