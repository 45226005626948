/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import {
  MenuItem,
  Select,
  Tooltip,
  Collapse,
} from '@material-ui/core/';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Trash from '@material-ui/icons/Delete';
import Trash from '@material-ui/icons/RestoreFromTrash';
import UserIcon from '@material-ui/icons/SupervisedUserCircle';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Dashboard from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import Cookies from 'universal-cookie';
import {
  Route,
  Switch,
  Link,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { ExpandLess, ExpandMore, Input } from '@material-ui/icons';

import DashboardPage from './Dashboard/DashboardPage';
import TpsPage from './Tps/TpsPage';
import TpsDetail from './Tps/TpsDetail';
import TpsForm from './Tps/TpsForm';
import UserPage from './User/UserPage';
import UserDetail from './User/UserDetail';
import UserForm from './User/UserForm';
import MySnack from './MySnack';
import WorkerPage from './Worker/WorkerPage';
import MonitoringForm from './Monitoring/MonitoringForm';
import PaymentForm from './Payment/PaymentForm';
import PaymentList from './Payment/PaymentList';
import Recycable from './Processing/Recycable';
import RecycableDetail from './Processing/RecycableDetail';
import RecycableForm from './Processing/RecycableForm';
import Organic from './Processing/Organic';
import OrganicDetail from './Processing/OrganicDetail';
import OrganicForm from './Processing/OrganicForm';
import OtherIncome from './Processing/OtherIncome';
import OtherIncomeDetail from './Processing/OtherIncomeDetail';
import OtherIncomeForm from './Processing/OtherIncomeForm';
import Residual from './Processing/Residual';
import ResidualDetail from './Processing/ResidualDetail';
import ResidualForm from './Processing/ResidualForm';

import GlobalContext from '../globalContext';
import enLocaleData from '../locale/en.json';
import idLocaleData from '../locale/id.json';
import { useLocalStorage, isMobile } from '../util';
import WorkerDetail from './Worker/WorkerDetail';
import DashboardOperasional from './Dashboard/DashboardOperasional';
import Operational from './Expenditure/Operational';
import OperationalForm from './Expenditure/OperationalForm';
import OperationalDetail from './Expenditure/OperationalDetail';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#007944',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 16,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  button: {
    containedPrimary: {
      color: 'red',
    },
  },
}));

const Admin = props => {
  const {
    history,
    query,
    mutate,
    snack,
    setSnack,
    title,
    language,
    isDetailPage,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useLocalStorage('openDrawer', false);
  const [dropdownIncome, setDropdownIncome] = useState(false);
  const [dropdownExpenditure, setDropdownExpenditure] = useState(
    false,
  );
  const { setLanguage } = useContext(GlobalContext);
  const histories = useHistory();

  const logout = () => {
    const cookie = new Cookies();
    cookie.remove('id');
    cookie.remove('name');
    cookie.remove('position');
    cookie.remove('role_id');
    cookie.remove('token', { path: '/' });
    cookie.remove('uuid');
    history.replace('/login');
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChangeLanguage = e => {
    const checkLang = e.target.value;
    localStorage.setItem('locale', checkLang);

    setLanguage(
      checkLang === 'id'
        ? idLocaleData
        : checkLang === 'en'
        ? enLocaleData
        : idLocaleData,
    );
  };

  const openDropdownIncome = () => {
    setDropdownIncome(!dropdownIncome);
    setOpen(true);
  };

  const openDropdownExpenditure = () => {
    setDropdownExpenditure(!dropdownExpenditure);
    setOpen(true);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {isDetailPage && (
              <ChevronLeftIcon onClick={() => histories.goBack()}>
                Back
              </ChevronLeftIcon>
            )}
            {title}
          </Typography>
          <section style={{ marginLeft: 'auto', marginRight: -12 }}>
            <Select
              margin="none"
              value={localStorage.getItem('locale')}
              style={{
                color: 'white',
              }}
              onChange={handleChangeLanguage}
              disableUnderline
            >
              <MenuItem value="id">ID</MenuItem>
              <MenuItem value="en">EN</MenuItem>
            </Select>
          </section>
        </Toolbar>
        {/* {state.isLoading ? <LinearProgress /> : null} */}
      </AppBar>

      <Drawer
        variant={isMobile() ? 'temporary' : 'permanent'}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
        onClose={handleDrawerClose}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Tooltip title={language.sidebar.dashboard}>
            <ListItem
              component={Link}
              onClick={() =>
                isMobile() ? handleDrawerClose() : null
              }
              to="/dashboard"
              button
              key="Dashboard"
            >
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText primary={language.sidebar.dashboard} />
            </ListItem>
          </Tooltip>
          <Tooltip title={language.sidebar.dashops}>
            <ListItem
              component={Link}
              onClick={() =>
                isMobile() ? handleDrawerClose() : null
              }
              to="/dashboardops"
              button
              key="Dashboard Operasional"
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={language.sidebar.dashops} />
            </ListItem>
          </Tooltip>
          <Tooltip title={language.sidebar.tps}>
            <ListItem
              component={Link}
              onClick={() =>
                isMobile() ? handleDrawerClose() : null
              }
              to="/tps/list"
              button
              key="TPS"
            >
              <ListItemIcon>
                <Trash />
              </ListItemIcon>
              <ListItemText primary={language.sidebar.tps} />
            </ListItem>
          </Tooltip>
          <Tooltip title={language.sidebar.customer}>
            <ListItem
              component={Link}
              onClick={() =>
                isMobile() ? handleDrawerClose() : null
              }
              to="/user/list"
              button
              key="Pelanggan"
            >
              <ListItemIcon>
                <UserIcon />
              </ListItemIcon>
              <ListItemText primary={language.sidebar.customer} />
            </ListItem>
          </Tooltip>
          <Tooltip title={language.sidebar.worker}>
            <ListItem
              component={Link}
              onClick={() =>
                isMobile() ? handleDrawerClose() : null
              }
              to="/worker/list"
              button
              key="Pekerja"
            >
              <ListItemIcon>
                <UserIcon />
              </ListItemIcon>
              <ListItemText primary={language.sidebar.worker} />
            </ListItem>
          </Tooltip>
          <Tooltip title={language.sidebar.monitoring}>
            <ListItem
              component={Link}
              onClick={() =>
                isMobile() ? handleDrawerClose() : null
              }
              to="/monitoring/new"
              button
              key="Monitoring"
            >
              <ListItemIcon>
                <CheckCircleOutline />
              </ListItemIcon>
              <ListItemText primary={language.sidebar.monitoring} />
            </ListItem>
          </Tooltip>
          <Tooltip title={language.sidebar.income.base}>
            <ListItem button onClick={openDropdownIncome}>
              <ListItemIcon>
                <Input />
              </ListItemIcon>
              <ListItemText primary={language.sidebar.income.base} />
              {dropdownIncome ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </Tooltip>
          <Collapse in={dropdownIncome} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Tooltip title={language.sidebar.income.customer}>
                <ListItem
                  component={Link}
                  onClick={() =>
                    isMobile() ? handleDrawerClose() : null
                  }
                  to="/income/payment"
                  button
                >
                  <ListItemText
                    inset
                    primary={language.sidebar.income.customer}
                  />
                </ListItem>
              </Tooltip>
              <Tooltip title={language.sidebar.income.sales}>
                <ListItem
                  component={Link}
                  onClick={() =>
                    isMobile() ? handleDrawerClose() : null
                  }
                  to="/income/recyclable"
                  button
                >
                  <ListItemText
                    inset
                    primary={language.sidebar.income.sales}
                  />
                </ListItem>
              </Tooltip>
              <Tooltip title={language.sidebar.income.compost}>
                <ListItem
                  component={Link}
                  onClick={() =>
                    isMobile() ? handleDrawerClose() : null
                  }
                  to="/income/compost"
                  button
                >
                  <ListItemText
                    inset
                    primary={language.sidebar.income.compost}
                  />
                </ListItem>
              </Tooltip>
              <Tooltip title={language.sidebar.income.other}>
                <ListItem
                  component={Link}
                  onClick={() =>
                    isMobile() ? handleDrawerClose() : null
                  }
                  to="/income/other"
                  button
                >
                  <ListItemText
                    inset
                    primary={language.sidebar.income.other}
                  />
                </ListItem>
              </Tooltip>
              <Tooltip title={language.sidebar.income.residual}>
                <ListItem
                  component={Link}
                  onClick={() =>
                    isMobile() ? handleDrawerClose() : null
                  }
                  to="/income/residual"
                  button
                >
                  <ListItemText
                    inset
                    primary={language.sidebar.income.residual}
                  />
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
          <Tooltip title={language.sidebar.expenditure.base}>
            <ListItem button onClick={openDropdownExpenditure}>
              <ListItemIcon>
                <Input />
              </ListItemIcon>
              <ListItemText
                primary={language.sidebar.expenditure.base}
              />
              {dropdownExpenditure ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </Tooltip>
          <Collapse
            in={dropdownExpenditure}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <Tooltip
                title={language.sidebar.expenditure.operational}
              >
                <ListItem
                  component={Link}
                  onClick={() =>
                    isMobile() ? handleDrawerClose() : null
                  }
                  to="/expenditure/operational"
                  button
                >
                  <Typography noWrap>
                    <ListItemText
                      inset
                      primary={
                        language.sidebar.expenditure.operational
                      }
                    />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
        </List>

        <Divider />
        <List>
          <Tooltip title={language.sidebar.logout}>
            <ListItem button key="Logout" onClick={logout}>
              <ListItemIcon>
                <PowerSettingsNew />
              </ListItemIcon>
              <ListItemText primary={language.sidebar.logout} />
            </ListItem>
          </Tooltip>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route
            exact
            path="/dashboard"
            render={props => (
              <DashboardPage
                query={query}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/tps/list"
            render={props => (
              <TpsPage query={query} language={language} {...props} />
            )}
          />
          <Route
            exact
            path="/tps/detail/:uuid"
            render={props => (
              <TpsDetail
                query={query}
                mutate={mutate}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/tps/edit/:uuid"
            render={props => (
              <TpsDetail
                query={query}
                language={language}
                mutate={mutate}
                {...props}
                editable
              />
            )}
          />
          <Route
            exact
            path="/tps/new"
            render={props => (
              <TpsForm
                language={language}
                mutate={mutate}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/user/list"
            render={props => (
              <UserPage
                query={query}
                language={language}
                {...props}
              />
            )}
          />

          <Route
            exact
            path="/user/list"
            render={props => (
              <UserPage
                query={query}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/user/detail/:uuid"
            render={props => (
              <UserDetail
                query={query}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/user/edit/:uuid"
            render={props => (
              <UserDetail
                query={query}
                mutate={mutate}
                language={language}
                {...props}
                editable
              />
            )}
          />
          <Route
            exact
            path="/user/new"
            render={props => (
              <UserForm
                mutate={mutate}
                language={language}
                page="customer"
                {...props}
              />
            )}
          />

          <Route
            exact
            path="/worker/list"
            render={props => (
              <WorkerPage
                query={query}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/worker/detail/:uuid"
            render={props => (
              <WorkerDetail
                query={query}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/worker/edit/:uuid"
            render={props => (
              <WorkerDetail
                query={query}
                mutate={mutate}
                language={language}
                {...props}
                editable
              />
            )}
          />
          <Route
            exact
            path="/worker/new"
            render={props => (
              <UserForm
                mutate={mutate}
                page="worker"
                language={language}
                {...props}
              />
            )}
          />

          <Route
            exact
            path="/monitoring/new"
            render={props => (
              <MonitoringForm
                query={query}
                mutate={mutate}
                language={language}
                {...props}
              />
            )}
          />

          <Route
            exact
            path="/income/payment"
            render={props => (
              <PaymentList
                query={query}
                mutate={mutate}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/payment/new"
            render={props => (
              <PaymentForm
                query={query}
                mutate={mutate}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/dashboardops"
            render={props => (
              <DashboardOperasional
                query={query}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/income/recyclable"
            render={props => (
              <Recycable
                query={query}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/income/recyclable/:uuid"
            render={props => (
              <RecycableDetail
                query={query}
                mutate={mutate}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/recycableform"
            render={props => (
              <RecycableForm
                query={query}
                mutate={mutate}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/income/compost"
            render={props => (
              <Organic query={query} language={language} {...props} />
            )}
          />
          <Route
            exact
            path="/income/compost/:uuid"
            render={props => (
              <OrganicDetail
                query={query}
                mutate={mutate}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/compostform"
            render={props => (
              <OrganicForm
                query={query}
                mutate={mutate}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/income/other"
            render={props => (
              <OtherIncome
                query={query}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/income/other/:uuid"
            render={props => (
              <OtherIncomeDetail
                query={query}
                mutate={mutate}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/otherform"
            render={props => (
              <OtherIncomeForm
                query={query}
                mutate={mutate}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/income/residual"
            render={props => (
              <Residual
                query={query}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/income/residual/:uuid"
            render={props => (
              <ResidualDetail
                query={query}
                mutate={mutate}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/residualform"
            render={props => (
              <ResidualForm
                query={query}
                mutate={mutate}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/expenditure/operational"
            render={props => (
              <Operational
                query={query}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/expenditure/operational/new"
            render={props => (
              <OperationalForm
                mutate={mutate}
                query={query}
                language={language}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/expenditure/operational/:uuid"
            render={props => (
              <OperationalDetail
                query={query}
                language={language}
                {...props}
              />
            )}
          />
          <Redirect exact from="/" to="/dashboard" />
        </Switch>
        <MySnack {...snack} setSnack={setSnack} />
      </main>
    </div>
  );
};

export default Admin;
