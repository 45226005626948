import React, { useEffect, useContext } from 'react';
import {
  Grid,
  Paper,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Cookies from 'universal-cookie/cjs';
import moment from 'moment';
import * as Yup from 'yup';
import { Formik } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import ErrorText from '../ErrorText';
import SubmitCancel from '../Custom/SubmitCancelBtn';
import GlobalContext from '../../globalContext';
import {
  CREATE_SALES_OTHER,
  GET_SALES_OTHERS,
} from '../../graphql/ProcessingQuery';
import { globalStyles } from '../../styles';

const OrganicForm = props => {
  const classes = globalStyles();
  const { language, history, mutate } = props;
  const { setSnack, setGlobalLoading, setTitle } = useContext(
    GlobalContext,
  );

  useEffect(() => {
    setTitle(language.processing.addProcessing);
    setGlobalLoading(false);
  }, [language.processing.addProcessing, setGlobalLoading, setTitle]);

  return (
    <Grid
      container
      direction="row"
      justify="flex-end"
      alignItems="baseline"
    >
      <Grid item xs={12} sm={12} md={12} lg={7}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Formik
            initialValues={{
              payment_at: moment().format('YYYY MM DD'),
              source_fund: '',
              total: 0,
              notes: '',
            }}
            validationSchema={Yup.object().shape({
              payment_at: Yup.date().required(
                language.validate.required,
              ),
              source_fund: Yup.string().required(
                language.validate.required,
              ),
              total: Yup.number()
                .min(100, language.processing.form.minError100)
                .required(language.validate.required),
              notes: Yup.string().required(
                language.validate.required,
              ),
            })}
            onSubmit={async (
              { source_fund, total, notes, payment_at },
              { resetForm },
            ) => {
              try {
                const cookie = new Cookies();
                setGlobalLoading(true);
                const data = {
                  worker_id: cookie.get('id'),
                  source_fund,
                  total: parseFloat(total),
                  description: '',
                  payment_at: moment(payment_at).format('YYYY-MM-DD'),
                  notes,
                  pay_by: '',
                };

                await mutate({
                  mutation: CREATE_SALES_OTHER,
                  variables: { data },
                  fetchPolicy: 'no-cache',
                  onError: error => {
                    console.log('==> Error execute mutation', error);
                  },
                  update: (
                    proxy,
                    { data: { createSalesOthers } },
                  ) => {
                    const olddata = proxy.readQuery({
                      query: GET_SALES_OTHERS,
                    });
                    proxy.writeQuery({
                      query: GET_SALES_OTHERS,
                      data: {
                        ...olddata,
                        sales: [...olddata.sales, createSalesOthers],
                      },
                    });
                  },
                });
                setSnack({
                  variant: 'success',
                  message: `Penjualan kompos berhasil ditambahkan!`,
                  opened: true,
                });
                resetForm();
                history.replace('/income/other');
              } catch (error) {
                console.log('Error create User', error);
                setSnack({
                  variant: 'error',
                  message: 'Gagal menambahkan penjualan kompos!',
                  opened: true,
                });
                setGlobalLoading(false);
              }
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              values,
              // handleChange,
              setFieldValue,
            }) => (
              <form noValidate>
                <Paper className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <KeyboardDatePicker
                        margin="dense"
                        // id="date-picker-dialog"
                        label={language.processing.form.date}
                        format="MM/dd/yyyy"
                        value={values.payment_at}
                        onChange={e => {
                          const dateval = moment(e).format(
                            'YYYY MM DD',
                          );
                          setFieldValue('payment_at', dateval, false);
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        autoFocus
                        margin="dense"
                        required
                        fullWidth
                        id="source_fund"
                        label={language.processing.form.source_fund}
                        name="source_fund"
                        autoComplete="source_fund"
                        onChange={e =>
                          setFieldValue(
                            'source_fund',
                            e.target.value,
                            false,
                          )
                        }
                        value={values.source_fund}
                      />
                      {errors.source_fund && touched.source_fund && (
                        <ErrorText text={errors.source_fund} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        autoFocus
                        margin="dense"
                        required
                        fullWidth
                        id="total"
                        label={language.processing.form.totalOthers}
                        name="total"
                        autoComplete="total"
                        onChange={e =>
                          setFieldValue(
                            'total',
                            e.target.value,
                            false,
                          )
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Rp
                            </InputAdornment>
                          ),
                        }}
                        value={values.total}
                      />
                      {errors.total && touched.total && (
                        <ErrorText text={errors.total} />
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        autoFocus
                        margin="dense"
                        required
                        fullWidth
                        id="notes"
                        label={language.processing.form.notes}
                        name="notes"
                        autoComplete="notes"
                        type=""
                        onChange={e =>
                          setFieldValue(
                            'notes',
                            e.target.value,
                            false,
                          )
                        }
                        value={values.notes}
                      />
                      {errors.notes && touched.notes && (
                        <ErrorText text={errors.notes} />
                      )}
                    </Grid>

                    <SubmitCancel
                      submitClass={classes.saveButton}
                      submitClick={handleSubmit}
                      submitTxt={language.action.save}
                      cancelTxt={language.action.back}
                      isCancelLink
                      cancelTo="/income/other"
                    />
                  </Grid>
                </Paper>
              </form>
            )}
          </Formik>
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

export default OrganicForm;
