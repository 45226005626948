import React, { useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import * as Yup from 'yup';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Cookies from 'universal-cookie/cjs';
import { Formik } from 'formik';
import { auth } from '../gql';
import GlobalContext from '../globalContext';
import { loginStyles } from '../styles';
import { setError } from '../util';
import ErrorText from './ErrorText';

const SignIn = props => {
  const { query, history } = props;
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const classes = loginStyles();
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>

        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required('Required')
              .email('Invalid email'),
            password: Yup.string().required('Required'),
          })}
          onSubmit={async ({ email, password }) => {
            try {
              setGlobalLoading(true);
              const {
                data: {
                  authenticate: {
                    id,
                    name,
                    position,
                    role_id,
                    token,
                    uuid,
                    tps_id,
                  },
                },
              } = await query({
                query: auth,
                variables: { email, password },
                fetchPolicy: 'no-cache',
              });

              const cookie = new Cookies();
              cookie.set('id', id);
              cookie.set('name', name);
              cookie.set('position', position);
              cookie.set('role_id', role_id);
              cookie.set('token', token, { path: '/' });
              cookie.set('uuid', uuid);
              cookie.set('tps_id', tps_id);
              history.replace('/');
              setGlobalLoading(false);
              setSnack({
                variant: 'success',
                message: 'You are successfully logged in!',
                opened: true,
              });
            } catch (error) {
              console.log('error login: ', error);
              setGlobalLoading(false);
              setError(setSnack, error);
            }
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            values,
            handleChange,
          }) => (
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
                onKeyPress={e => {
                  if (e.nativeEvent.keyCode === 13) handleSubmit();
                }}
                value={values.email}
              />
              {errors.email && touched.email && (
                <ErrorText text={errors.email} />
              )}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onKeyPress={e => {
                  if (e.nativeEvent.keyCode === 13) handleSubmit();
                }}
                onChange={handleChange}
                value={values.password}
              />
              {errors.password && touched.password && (
                <ErrorText text={errors.password} />
              )}
              <Button
                // type="submit"
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    variant="body2"
                    component="button"
                    onClick={() => history.replace('/forgotpassword')}
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    variant="body2"
                    component="button"
                    onClick={() => history.replace('/signup')}
                  >
                    Don't have an account? Sign Up
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
export default SignIn;
