import gql from 'graphql-tag';

export const CREATE_MONITORING = gql`
  mutation createMonitoring($data: InMonitoring!) {
    createMonitoring(data: $data) {
      id
      user {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
      worker {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
      organic
      recycle
      residu
      seperation_date
      created_at
      updated_at
    }
  }
`;

export const GET_MONITORINGS_USER = gql`
  query findMonitoringByUser($user_id: ID!) {
    findMonitoringByUser(user_id: $user_id) {
      id
      user {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
      worker {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
      quality
      organic
      recycle
      residu
      intervention
      notes
      seperation_date
      created_at
      updated_at
    }
  }
`;

export const GET_TOTAL_MONITORINGS_USER = gql`
  query totalMonitoringUser($user_id: ID!) {
    totalMonitoringUser(user_id: $user_id) {
      count
    }
  }
`;
