import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Formik } from 'formik';
import SubmitCancel from '../Custom/SubmitCancelBtn';
import GlobalContext from '../../globalContext';
import ErrorText from '../ErrorText';
import { GET_USER, UPDATE_WORKER } from '../../graphql/UserQuery';
import { globalStyles } from '../../styles';

const WorkerDetail = props => {
  const { language, match, query, editable, history, mutate } = props;
  const { uuid } = match.params;
  const readOnly = !editable;
  const classes = globalStyles();
  const { setSnack, setGlobalLoading, setTitle } = useContext(
    GlobalContext,
  );
  const [worker, setWorker] = useState({});

  useEffect(() => {
    setGlobalLoading(true);
    setTitle(
      editable
        ? language.worker.title.edit
        : language.worker.title.detail,
    );

    const getWorker = async () => {
      const {
        data: { findUserByUuid },
      } = await query({
        query: GET_USER,
        variables: {
          uuid,
        },
        fetchPolicy: 'no-cache',
      });
      setGlobalLoading(false);

      setWorker(findUserByUuid);
    };

    getWorker();
  }, [
    setTitle,
    language.worker.title.detail,
    query,
    setGlobalLoading,
    uuid,
    editable,
    language.worker.title.edit,
  ]);

  const DetailPage = () => {
    return (
      <div>
        <Grid container spacing={0} justify="center">
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper className={classes.root}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="name"
                    label={language.worker.form.name}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={worker ? worker.name : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="email"
                    label={language.worker.form.email}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={worker ? worker.email : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="phone"
                    label={language.worker.form.phone}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={worker ? worker.phone : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="position"
                    label={language.worker.form.position}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={worker ? worker.position : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="provinsi"
                    label={language.worker.form.provinsi}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={worker ? worker.provinsi : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="kabupaten"
                    label={language.worker.form.kabupaten}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={worker ? worker.kabupaten : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="kecamatan"
                    label={language.worker.form.kecamatan}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={worker ? worker.kecamatan : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="kelurahan"
                    label={language.worker.form.kelurahan}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={worker ? worker.kelurahan : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="desaAdat"
                    label={language.worker.form.desaAdat}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={worker ? worker.desa_adat : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={2}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="banjar"
                    label={language.worker.form.banjar}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={worker ? worker.banjar : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={2}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="dusun"
                    label={language.worker.form.dusun}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={worker ? worker.dusun : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="jalan"
                    label={language.worker.form.jalan}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={worker ? worker.jalan : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    margin="normal"
                    fullWidth
                    multiline
                    rows={5}
                    id="notes"
                    label={language.worker.form.notes}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={worker ? worker.notes : ''}
                  />
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="baseline"
                >
                  <Button
                    component={Link}
                    to="/worker/list"
                    style={{
                      marginTop: 20,
                    }}
                    variant="outlined"
                    color="default"
                  >
                    {language.action.back}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  };

  const EditPage = (
    <div>
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Formik
            enableReinitialize
            initialValues={worker}
            onSubmit={async (worker, { resetForm }) => {
              try {
                const data = {
                  name: worker.name,
                  email: worker.email,
                  provinsi: worker.provinsi,
                  kabupaten: worker.kabupaten,
                  kecamatan: worker.kecamatan,
                  kelurahan: worker.kelurahan,
                  desa_adat: worker.desa_adat,
                  banjar: worker.banjar,
                  dusun: worker.dusun,
                  jalan: worker.jalan,
                  position: worker.position,
                  phone: worker.phone,
                  registration_date: worker.registration_date,
                  notes: worker.notes,
                };

                await mutate({
                  mutation: UPDATE_WORKER,
                  variables: { id: worker.id, data },
                  fetchPolicy: 'no-cache',
                  onError: error => {
                    console.log('adakah error? ', error);
                  },
                });
                setGlobalLoading(true);
                setSnack({
                  variant: 'success',
                  message: `${worker.name} berhasil diubah!`,
                  opened: true,
                });

                resetForm();
                history.replace('/worker/list');
              } catch (error) {
                console.log('error', error);
                setSnack({
                  variant: 'error',
                  message: 'Error Update Worker',
                  opened: true,
                });
              }
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              values,
              handleChange,
              setFieldValue,
            }) => (
              <form noValidate>
                <Paper className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        autoFocus
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label={language.worker.form.name}
                        name="name"
                        autoComplete="name"
                        onChange={handleChange}
                        value={values.name ? values.name : ''}
                      />
                      {errors.name && touched.name && (
                        <ErrorText text={errors.name} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={language.worker.form.email}
                        type="email"
                        name="email"
                        autoComplete="email"
                        onChange={handleChange}
                        value={values.email ? values.email : ''}
                      />
                      {errors.email && touched.email && (
                        <ErrorText text={errors.email} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="phone"
                        label={language.worker.form.phone}
                        type="phone"
                        name="phone"
                        autoComplete="phone"
                        onChange={handleChange}
                        value={values.phone ? values.phone : ''}
                      />
                      {errors.phone && touched.phone && (
                        <ErrorText text={errors.phone} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel id="position">Posisi</InputLabel>
                        <Select
                          margin="dense"
                          fullWidth
                          required
                          labelId={language.worker.form.position}
                          id="position"
                          name="position"
                          value={
                            values.position ? values.position : ''
                          }
                          // onChange={handleChange}
                          onChange={e => {
                            setFieldValue('position', e.target.value);
                          }}
                        >
                          <MenuItem value="Default" disabled>
                            --- Pilih ---
                          </MenuItem>
                          <MenuItem value="ADMIN">Admin</MenuItem>
                          <MenuItem value="COLLECTOR">
                            Collector
                          </MenuItem>
                          <MenuItem value="COMPOSTER">
                            Composter
                          </MenuItem>
                          <MenuItem value="COORDINATOR">
                            Coordinator
                          </MenuItem>
                          <MenuItem value="PEMONITOR">
                            Pemonitor
                          </MenuItem>
                          <MenuItem value="SEPARATOR">
                            Separator
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="provinsi"
                        label={language.worker.form.provinsi}
                        name="provinsi"
                        onChange={handleChange}
                        value={values.provinsi ? values.provinsi : ''}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="kabupaten"
                        label={language.worker.form.kabupaten}
                        name="kabupaten"
                        onChange={handleChange}
                        value={
                          values.kabupaten ? values.kabupaten : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="kecamatan"
                        label={language.worker.form.kecamatan}
                        name="kecamatan"
                        onChange={handleChange}
                        value={
                          values.kecamatan ? values.kecamatan : ''
                        }
                      />
                      {errors.kecamatan && touched.kecamatan && (
                        <ErrorText text={errors.kecamatan} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="kelurahan"
                        label={language.worker.form.kelurahan}
                        name="kelurahan"
                        onChange={handleChange}
                        value={
                          values.kelurahan ? values.kelurahan : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="desa_adat"
                        label={language.worker.form.desaAdat}
                        name="desa_adat"
                        onChange={handleChange}
                        value={
                          values.desa_adat ? values.desa_adat : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="banjar"
                        label={language.worker.form.banjar}
                        name="banjar"
                        onChange={handleChange}
                        value={values.banjar ? values.banjar : ''}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="dusun"
                        label={language.worker.form.dusun}
                        name="dusun"
                        onChange={handleChange}
                        value={values.dusun ? values.dusun : ''}
                      />
                      {errors.dusun && touched.dusun && (
                        <ErrorText text={errors.dusun} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="jalan"
                        label={language.worker.form.jalan}
                        name="jalan"
                        onChange={handleChange}
                        value={values.jalan ? values.jalan : ''}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        margin="normal"
                        fullWidth
                        multiline
                        rows={5}
                        id="notes"
                        label={language.worker.form.notes}
                        type="notes"
                        name="notes"
                        autoComplete="notes"
                        onChange={handleChange}
                        value={values.notes ? values.notes : ''}
                      />
                    </Grid>
                    <SubmitCancel
                      submitClass={classes.saveButton}
                      submitClick={handleSubmit}
                      submitTxt={language.action.update}
                      cancelTxt={language.action.back}
                      isCancelLink
                      cancelTo="/worker/list"
                    />
                  </Grid>
                </Paper>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );

  return <div>{readOnly ? <DetailPage /> : EditPage}</div>;
};

export default WorkerDetail;
