import gql from 'graphql-tag';

export const GET_USERS = gql`
  query users($position: String!) {
    users(position: $position) {
      id
      uuid
      user_id
      role_id
      tps_id
      position
      name
      jalan
      dusun
      banjar
      desa_adat
      kelurahan
      kecamatan
      kabupaten
      provinsi
      email
      password
      phone
      location {
        latitude
        longitude
      }
      photo
      category
      service_fee
      size
      lane
      status
      password_token
      registration_date
      created_at
      updated_at
      role {
        id
        name
        description
        active
        created_at
        updated_at
      }
    }
  }
`;

export const GET_USER = gql`
  query findUserByUuid($uuid: String!) {
    findUserByUuid(uuid: $uuid) {
      id
      uuid
      user_id
      role_id
      tps_id
      position
      name
      jalan
      dusun
      banjar
      desa_adat
      kelurahan
      kecamatan
      kabupaten
      provinsi
      email
      password
      phone
      notes
      location {
        latitude
        longitude
      }
      photo
      category
      service_fee
      status
      size
      lane
      password_token
      registration_date
      created_at
      updated_at
      role {
        id
        name
        description
        active
        created_at
        updated_at
      }
      monitoring {
        id
        worker {
          id
          uuid
          user_id
          role_id
          position
          name
          email
          jalan
          dusun
          banjar
          desa_adat
          kelurahan
          kecamatan
          kabupaten
          provinsi
        }
        organic
        recycle
        residu
        seperation_date
        created_at
        updated_at
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createCustomer($data: InCustomer!) {
    createCustomer(data: $data) {
      id
      uuid
      user_id
      role_id
      tps_id
      position
      name
      jalan
      dusun
      banjar
      desa_adat
      kelurahan
      kecamatan
      kabupaten
      provinsi
      email
      password
      phone
      location {
        latitude
        longitude
      }
      photo
      category
      service_fee
      status
      password_token
      registration_date
      created_at
      updated_at
    }
  }
`;

export const CREATE_WORKER = gql`
  mutation createWorker($data: InWorker!) {
    createWorker(data: $data) {
      id
      uuid
      user_id
      role_id
      tps_id
      position
      name
      jalan
      dusun
      banjar
      desa_adat
      kelurahan
      kecamatan
      kabupaten
      provinsi
      email
      password
      phone
      location {
        latitude
        longitude
      }
      photo
      category
      service_fee
      status
      password_token
      registration_date
      created_at
      updated_at
    }
  }
`;

export const SEARCH_USERS = gql`
  query searchUsers($name: String!, $position: String!, $lane: Int) {
    searchUsers(name: $name, position: $position, lane: $lane) {
      id
      name
      position
    }
  }
`;

export const GET_USER_DETAIL = gql`
  query findUserById($id: ID!) {
    findUserById(id: $id) {
      id
      uuid
      user_id
      role_id
      tps_id
      position
      name
      jalan
      dusun
      banjar
      desa_adat
      kelurahan
      kecamatan
      kabupaten
      provinsi
      email
      password
      phone
      location {
        latitude
        longitude
      }
      photo
      category
      service_fee
      status
      size
      lane
      password_token
      registration_date
      created_at
      updated_at
      billing {
        id
        uuid
        worker {
          id
          uuid
          user_id
          role_id
          position
          name
          email
          role {
            id
            name
            description
            active
            created_at
            updated_at
          }
        }
        periode
        payment_total
        notes
        payment_at
        created_at
        updated_at
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($id: ID!, $data: InCustomer!) {
    updateCustomer(id: $id, data: $data) {
      id
      uuid
      user_id
      role_id
      position
      name
      email
    }
  }
`;

export const UPDATE_WORKER = gql`
  mutation updateWorker($id: ID!, $data: InWorker!) {
    updateWorker(id: $id, data: $data) {
      id
      uuid
      user_id
      role_id
      position
      name
      email
    }
  }
`;
