import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import GlobalContext from '../../globalContext';
import { rupia } from '../../util';
import { EXPENDITURE } from '../../graphql/ExpenditureQuery';

export default function RecycableDetail(props) {
  const { query, language, match } = props;
  const { uuid } = match.params;
  const [operationalExp, setOperationalExp] = useState(null);
  const { setGlobalLoading, setTitle } = useContext(GlobalContext);

  useEffect(() => {
    setTitle(language.expenditure.operationalDetail);
  }, [setTitle, language.expenditure.operationalDetail]);

  useEffect(() => {
    const getInitial = async () => {
      setGlobalLoading(true);
      const {
        data: { expenditure },
      } = await query({
        query: EXPENDITURE,
        variables: { uuid },
        fetchPolicy: 'no-cache',
      });
      setOperationalExp(expenditure);
      setTimeout(() => setGlobalLoading(false), 1500);
    };
    getInitial();
  }, [query, setGlobalLoading, uuid]);

  return (
    <Grid container spacing={2}>
      {operationalExp && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h5">Data Pengeluaran</Typography>
            <Typography variant="body1">
              Pembelian: {operationalExp.item}
            </Typography>
            <Typography variant="body1">
              Kategori Pembelian: {operationalExp.item_type}
            </Typography>
            <Typography variant="body1">
              Jumlah: {operationalExp.quantity}
            </Typography>
            <Typography variant="body1">
              Pelapor: {operationalExp.worker.name}
            </Typography>
            <Typography variant="body1">
              Harga per Unit: {rupia(operationalExp.unit_price)}
            </Typography>
            <Typography variant="body1">
              Tanggal:{' '}
              {moment(operationalExp.payment_at).format('LL')}
            </Typography>
          </Grid>
          <Button
            component={Link}
            to="/expenditure/operational"
            style={{
              marginTop: 20,
            }}
            variant="outlined"
            color="default"
          >
            {language.action.back}
          </Button>
        </>
      )}
    </Grid>
  );
}
