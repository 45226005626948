import gql from 'graphql-tag';

export const CREATE_SALES_RECYCABLE = gql`
  mutation createSale($data: InSales!) {
    createSales(data: $data) {
      ... on Recyclabe {
        id
        uuid
        user_id
        worker_id
        type
        total
        payment_at
        created_at
        updated_at
        user {
          id
          uuid
          user_id
          role_id
          position
          name
          email
          jalan
          dusun
          banjar
          desa_adat
          kelurahan
          kecamatan
          kabupaten
          provinsi
          role {
            id
            name
            description
            active
            created_at
            updated_at
          }
        }
        worker {
          id
          uuid
          user_id
          role_id
          position
          name
          email
          jalan
          dusun
          banjar
          desa_adat
          kelurahan
          kecamatan
          kabupaten
          provinsi
          role {
            id
            name
            description
            active
            created_at
            updated_at
          }
        }
        sales_details {
          id
          uuid
          sales_id
          catalogue_id
          weight
          quantity
          price
          sub_total
          created_at
          updated_at
          catalogue {
            id
            uuid
            material_id
            name
            created_at
            updated_at
            material {
              id
              uuid
              name
              created_at
              updated_at
            }
          }
        }
      }
    }
  }
`;

export const CREATE_SALES_COMPOST = gql`
  mutation createSale($data: InSales!) {
    createSales(data: $data) {
      ... on Compost {
        id
        uuid
        worker_id
        sell_to
        type
        total
        payment_at
        created_at
        updated_at
        worker {
          id
          uuid
          user_id
          role_id
          position
          name
          email
          jalan
          dusun
          banjar
          desa_adat
          kelurahan
          kecamatan
          kabupaten
          provinsi
          role {
            id
            name
            description
            active
            created_at
            updated_at
          }
        }
        sales_details {
          id
          uuid
          sales_id
          catalogue_id
          weight
          quantity
          price
          sub_total
          created_at
          updated_at
          catalogue {
            id
            uuid
            material_id
            name
            created_at
            updated_at
            material {
              id
              uuid
              name
              created_at
              updated_at
            }
          }
        }
      }
    }
  }
`;

export const CREATE_SALES_OTHER = gql`
  mutation createSalesOthers($data: InSalesOthers!) {
    createSalesOthers(data: $data) {
      id
      uuid
      worker_id
      source_fund
      total
      description
      notes
      pay_by
      payment_at
      created_at
      updated_at
      worker {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
    }
  }
`;

export const CREATE_SALES_RESIDU = gql`
  mutation createSalesResidue($data: InResidue!) {
    createSalesResidue(data: $data) {
      id
      uuid
      worker_id
      tpa
      total_truck
      estimate_weight
      weight_unit
      payment_at
      created_at
      updated_at
      worker {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
    }
  }
`;

export const DELETE_SALE = gql`
  mutation deleteSales($uuid: String!) {
    deleteSales(uuid: $uuid) {
      status
    }
  }
`;
export const DELETE_SALE_OTHER = gql`
  mutation deleteSalesOthers($uuid: String!) {
    deleteSalesOthers(uuid: $uuid) {
      status
    }
  }
`;

export const DELETE_SALES_RESIDU = gql`
  mutation deleteSalesResidue($uuid: String!) {
    deleteSalesResidue(uuid: $uuid) {
      status
    }
  }
`;

export const GET_SALES_RECYCABLE = gql`
  query sales($options: Options, $type: SalesType) {
    sales(options: $options, type: $type) {
      __typename
      ... on Recyclabe {
        id
        uuid
        user_id
        worker_id
        type
        total
        payment_at
        created_at
        updated_at
        user {
          id
          uuid
          user_id
          role_id
          position
          name
          email
          jalan
          dusun
          banjar
          desa_adat
          kelurahan
          kecamatan
          kabupaten
          provinsi
          role {
            id
            name
            description
            active
            created_at
            updated_at
          }
        }
        worker {
          id
          uuid
          user_id
          role_id
          position
          name
          email
          jalan
          dusun
          banjar
          desa_adat
          kelurahan
          kecamatan
          kabupaten
          provinsi
          role {
            id
            name
            description
            active
            created_at
            updated_at
          }
        }
        sales_details {
          id
          uuid
          sales_id
          catalogue_id
          weight
          quantity
          price
          sub_total
          created_at
          updated_at
          catalogue {
            id
            uuid
            material_id
            name
            created_at
            updated_at
            material {
              id
              uuid
              name
              created_at
              updated_at
            }
          }
        }
      }
    }
  }
`;
export const GET_SALES_COMPOST = gql`
  query sales($options: Options, $type: SalesType) {
    sales(options: $options, type: $type) {
      __typename
      ... on Compost {
        id
        uuid
        worker_id
        sell_to
        type
        total
        payment_at
        created_at
        updated_at
        worker {
          id
          uuid
          user_id
          role_id
          position
          name
          email
          jalan
          dusun
          banjar
          desa_adat
          kelurahan
          kecamatan
          kabupaten
          provinsi
          role {
            id
            name
            description
            active
            created_at
            updated_at
          }
        }
        sales_details {
          id
          uuid
          sales_id
          catalogue_id
          weight
          quantity
          price
          sub_total
          created_at
          updated_at
          catalogue {
            id
            uuid
            material_id
            name
            created_at
            updated_at
            material {
              id
              uuid
              name
              created_at
              updated_at
            }
          }
        }
      }
    }
  }
`;

export const GET_SALES_OTHERS = gql`
  query salesOthers($options: Options) {
    salesOthers(options: $options) {
      id
      uuid
      worker_id
      source_fund
      total
      description
      notes
      pay_by
      payment_at
      created_at
      updated_at
      worker {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
    }
  }
`;

export const GET_SALES_RESIDUAL = gql`
  query salesResidues($options: Options) {
    salesResidues(options: $options) {
      id
      uuid
      worker_id
      tpa
      total_truck
      estimate_weight
      weight_unit
      payment_at
      created_at
      updated_at
      worker {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
    }
  }
`;
export const GET_SALE_RESIDUAL = gql`
  query salesResidue($uuid: String!) {
    salesResidue(uuid: $uuid) {
      id
      uuid
      worker_id
      tpa
      total_truck
      estimate_weight
      weight_unit
      payment_at
      created_at
      updated_at
      worker {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
    }
  }
`;

export const FIND_TPA = gql`
  query findTpa($name: String) {
    findTpa(name: $name) {
      String
    }
  }
`;

export const GET_CATALOGUES = gql`
  query catalogues($options: Options) {
    catalogues(options: $options) {
      id
      uuid
      material_id
      name
      created_at
      updated_at
      material {
        id
        uuid
        name
        created_at
        updated_at
      }
    }
  }
`;

export const GET_SALE_RECYCABLE = gql`
  query sale($uuid: String!) {
    sale(uuid: $uuid) {
      ... on Recyclabe {
        id
        uuid
        user_id
        worker_id
        type
        total
        payment_at
        created_at
        updated_at
        user {
          id
          uuid
          user_id
          role_id
          position
          name
          email
          jalan
          dusun
          banjar
          desa_adat
          kelurahan
          kecamatan
          kabupaten
          provinsi
          role {
            id
            name
            description
            active
            created_at
            updated_at
          }
        }
        worker {
          id
          uuid
          user_id
          role_id
          position
          name
          email
          jalan
          dusun
          banjar
          desa_adat
          kelurahan
          kecamatan
          kabupaten
          provinsi
          role {
            id
            name
            description
            active
            created_at
            updated_at
          }
        }
        sales_details {
          id
          uuid
          sales_id
          catalogue_id
          weight
          weight_unit
          quantity
          quantity_unit
          price
          sub_total
          created_at
          updated_at
          catalogue {
            id
            uuid
            material_id
            name
            created_at
            updated_at
            material {
              id
              uuid
              name
              created_at
              updated_at
            }
          }
        }
      }
    }
  }
`;
export const GET_SALE_COMPOST = gql`
  query sale($uuid: String!) {
    sale(uuid: $uuid) {
      ... on Compost {
        id
        uuid
        worker_id
        material_from
        sell_to
        type
        total
        payment_at
        created_at
        updated_at
        worker {
          id
          uuid
          user_id
          role_id
          position
          name
          email
          jalan
          dusun
          banjar
          desa_adat
          kelurahan
          kecamatan
          kabupaten
          provinsi
          role {
            id
            name
            description
            active
            created_at
            updated_at
          }
        }
        sales_details {
          id
          uuid
          sales_id
          catalogue_id
          weight
          weight_unit
          quantity
          quantity_unit
          price
          sub_total
          created_at
          updated_at
          catalogue {
            id
            uuid
            material_id
            name
            created_at
            updated_at
            material {
              id
              uuid
              name
              created_at
              updated_at
            }
          }
        }
      }
    }
  }
`;
export const GET_SALES_OTHER = gql`
  query salesOther($uuid: String!) {
    salesOther(uuid: $uuid) {
      id
      uuid
      worker_id
      source_fund
      total
      description
      notes
      pay_by
      payment_at
      created_at
      updated_at
      worker {
        id
        uuid
        user_id
        role_id
        position
        name
        email
        jalan
        dusun
        banjar
        desa_adat
        kelurahan
        kecamatan
        kabupaten
        provinsi
        role {
          id
          name
          description
          active
          created_at
          updated_at
        }
      }
    }
  }
`;
