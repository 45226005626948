import React, { useEffect, useContext, useState } from 'react';
import {
  Button,
  Grid,
  Paper,
  TextField,
  Fab,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import localization from 'moment/locale/id';
import AddIcon from '@material-ui/icons/Add';
import { isMobile } from '../../util';
import GlobalContext from '../../globalContext';
import { GET_BILLINGS } from '../../graphql/BillingQuery';
import PaymentInfoDialog from './PaymentInfoDialog';
import Payment from '../Custom/Payment';
import { globalStyles } from '../../styles';

const PaymentList = props => {
  const classes = globalStyles();
  const { query, language, mutate } = props;
  const [open, setOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [data, setData] = useState([]);
  const [fixedData, setFixedData] = useState([]);
  const { setSnack, setGlobalLoading, setTitle } = useContext(
    GlobalContext,
  );

  useEffect(() => {
    setTitle(language.payment.form.title);
    setGlobalLoading(true);

    const getBillings = async () => {
      const {
        data: { billings },
      } = await query({
        query: GET_BILLINGS,
        fetchPolicy: 'no-cache',
      });

      if (billings) {
        console.log('billings[0]', billings[0].periode);
        const dataBillings = billings.map((e, i) => ({
          no: `${i + 1}.`,
          customer: e.user.name,
          // worker: `${language.payment.to} ${e.worker.name}`,
          worker: e.worker.name,
          periode: `${language.payment.period} ${moment(
            `01-${e.periode}`,
            'DD-MM-YYYY',
          ).format('MMM YYYY')}`,
          payment_at: moment(e.payment_at)
            .locale('id', localization)
            .format('dddd, DD-MM-YYYY'),
          payment_total: e.payment_total,
          notes: e.notes,
          detail: e,
        }));

        // setPayments(billings);
        setData(dataBillings);
        setFixedData(dataBillings);
        setGlobalLoading(false);
      } else {
        setGlobalLoading(false);
        setSnack({
          variant: 'error',
          message: 'Gagal mengambil data pembayaran!',
          opened: true,
        });
      }
    };

    getBillings();
  }, [setTitle, setGlobalLoading, setSnack, query, language]);

  const handleClickOpen = value => {
    console.log('value isss: ', value);
    setSelectedPayment(value);
    setOpen(true);
  };

  // const columns = [
  //   {
  //     name: 'no',
  //     label: 'No.',
  //     options: {
  //       filter: false,
  //       sort: true,
  //     },
  //   },
  //   {
  //     name: 'customer',
  //     label: 'Nama Pelanggan',
  //     options: {
  //       filter: false,
  //       sort: true,
  //     },
  //   },
  //   {
  //     name: 'worker',
  //     label: 'Nama Admin',
  //     options: {
  //       filter: false,
  //       sort: true,
  //     },
  //   },
  //   {
  //     name: 'periode',
  //     label: 'Periode',
  //     options: {
  //       filter: false,
  //       sort: true,
  //     },
  //   },
  //   {
  //     name: 'payment_at',
  //     label: 'Tanggal Pembayaran',
  //     options: {
  //       filter: false,
  //       sort: true,
  //     },
  //   },
  //   {
  //     name: 'notes',
  //     label: 'Catatan',
  //     options: {
  //       filter: false,
  //       sort: true,
  //     },
  //   },
  //   {
  //     name: 'detail',
  //     label: 'Aksi',
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: value => (
  //         <Button
  //           variant="outlined"
  //           color="default"
  //           onClick={() => handleClickOpen(value)}
  //         >
  //           Lihat
  //         </Button>
  //       ),
  //     },
  //   },
  // ];

  const handleSearch = e => {
    const finds = [...fixedData].filter(
      x =>
        x.customer
          .toLowerCase()
          .includes(e.currentTarget.value.toLowerCase()) ||
        x.worker
          .toLowerCase()
          .includes(e.currentTarget.value.toLowerCase()) ||
        x.periode
          .toLowerCase()
          .includes(e.currentTarget.value.toLowerCase()),
    );
    setData(finds);
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="baseline"
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="baseline"
        >
          <TextField
            id="outlined-basic"
            label={language.action.search}
            variant="outlined"
            size="small"
            fullWidth
            onChange={e => handleSearch(e)}
            // onKeyPress={e => e.nativeEvent.keyCode === 13 handleSearch}
          />
        </Grid>
        {!isMobile() && (
          <Grid
            container
            direction="column"
            justify="flex-end"
            alignItems="baseline"
          >
            <Button
              className={classes.addButton}
              component={Link}
              to="/payment/new"
              variant="contained"
              color="primary"
            >
              {language.action.add}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ marginTop: 20 }}>
          <Payment
            data={data}
            mappingColumns={{
              firstPrimary: 'customer',
              firstSecondary: 'worker',
              secondPrimary: 'payment_total',
              secondSecondary: 'periode',
              id: 'detail',
            }}
            onClick={handleClickOpen}
          />
          {/* <Payment /> */}
          {/* <TpsDatatable
            title="Data Pembayaran"
            data={data}
            columns={columns}
            language={language}
          /> */}
        </Paper>
      </Grid>
      <PaymentInfoDialog
        classes={classes}
        data={selectedPayment}
        language={language}
        mutate={mutate}
        open={open}
        setGlobalLoading={setGlobalLoading}
        setSnack={setSnack}
        onClose={() => {
          setOpen(false);
        }}
      />
      {isMobile() && (
        <Fab
          color="primary"
          aria-label="add"
          component={Link}
          to="/payment/new"
          variant="round"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
          }}
        >
          <AddIcon />
        </Fab>
      )}
    </div>
  );
};

export default PaymentList;
