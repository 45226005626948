import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import InfiniteScroll from 'react-infinite-scroll-component';

import Typography from '@material-ui/core/Typography';
import { blue, red, green } from '@material-ui/core/colors';
// import { default as mainStyles } from '../../styles';
import { getPaginatedItems, rupia } from '../../util';
// import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  greenClass: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
  },
  redClass: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
  },
  blueClass: {
    color: theme.palette.getContrastText(blue[600]),
    backgroundColor: blue[600],
  },
}));

export default function OrganicList(props) {
  const classes = useStyles();
  const { data, limit, fetch, moreData, onClick } = props;
  const lim = limit || 20;
  const page = Math.ceil(data.length / lim);
  const [curPage, setCurrentPage] = useState(1);
  const [maxReached, setMaxReached] = useState(false);
  const [displayed, setDisplayed] = useState([]);

  const fetchMore = async () => {
    if (fetch) {
      await fetch();
    } else if (curPage <= page) {
      const newDisplayed = getPaginatedItems(data, curPage + 1, 20);
      setDisplayed([...displayed, ...newDisplayed]);
      setCurrentPage(curPage + 1);
    }
  };
  useEffect(() => {
    if (moreData) setDisplayed(d => [...d, ...moreData]);
  }, [moreData]);

  useEffect(() => {
    if (curPage && curPage > page) setMaxReached(true);
  }, [curPage, page]);

  useEffect(() => {
    const getInitial = () => {
      if (fetch) setDisplayed(data);
      else {
        const firstDisplayed = getPaginatedItems(data, 1, 20);
        setCurrentPage(1);
        setMaxReached(false);
        setDisplayed(firstDisplayed);
      }
    };
    getInitial();
    setCurrentPage(1);
  }, [data, fetch]);

  return (
    <List className={classes.root}>
      <InfiniteScroll
        dataLength={displayed.length}
        next={fetchMore}
        hasMore={!maxReached}
        loader={
          !maxReached && (
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ paddingLeft: '20px' }}
            >
              {data.length > 0
                ? 'Loading ...'
                : 'No sales yet, create one?'}
            </Typography>
          )
        }
      >
        {displayed.map((item, idx) => (
          <React.Fragment key={`${idx}asdasd`}>
            <ListItem
              style={{
                borderBottomWidth: '1px',
                borderBottomColor: 'black',
              }}
              dense
              onClick={() => onClick(item)}
            >
              <ListItemText
                style={{ flex: 9 }}
                primary={item.sell_to}
                secondary={`dijual oleh ${item.worker.name}`}
              />
              <ListItemText
                style={{ flex: 6 }}
                primary={rupia(item.total)}
                primaryTypographyProps={{
                  style: { fontWeight: 'bold' },
                }}
                secondary={moment(item.payment_at).format('LL')}
                secondaryTypographyProps={{
                  style: { color: '#4a4a4a' },
                }}
              />
            </ListItem>
            <Divider variant="fullWidth" component="li" />
          </React.Fragment>
        ))}
      </InfiniteScroll>
    </List>
  );
}
