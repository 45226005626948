import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  // Chip,
  Grid,
  Paper,
  Fab,
  TextField,
  // Tooltip,
} from '@material-ui/core';
// import ListIcon from '@material-ui/icons/ListOutlined';
// import EditIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import AvatarList from '../Custom/AvatarList';

import { GET_USERS } from '../../graphql/UserQuery';
// import TpsDatatable from '../Custom/TpsDatatable';
import { isMobile } from '../../util';
import GlobalContext from '../../globalContext';

import { globalStyles } from '../../styles';

const WorkerPage = props => {
  const classes = globalStyles();
  const { query, language, history } = props;
  const { setGlobalLoading, setTitle } = useContext(GlobalContext);
  const [workers, setWorkers] = useState([]);
  const [fixedworkers, setFixedWorkers] = useState([]);

  useEffect(() => {
    setTitle(language.worker.title.base);
    setGlobalLoading(true);

    const getWorkers = async () => {
      const {
        data: { users },
      } = await query({
        query: GET_USERS,
        variables: { position: 'WORKER' },
        fetchPolicy: 'no-cache',
      });

      if (users) {
        const dataUsers = users.map((e, i) => ({
          no: `${i + 1}.`,
          name: e.name,
          email: e.email,
          position: e.position,
          status: e.status,
          detail: e.uuid,
        }));

        setWorkers(dataUsers);
        setFixedWorkers(dataUsers);
        setGlobalLoading(false);
      } else {
        setGlobalLoading(false);
      }
    };

    getWorkers();
  }, [
    setWorkers,
    setGlobalLoading,
    query,
    setTitle,
    language.worker.title.base,
  ]);

  // const columns = [
  //   {
  //     name: 'no',
  //     label: 'No.',
  //     options: {
  //       filter: false,
  //       sort: true,
  //     },
  //   },
  //   {
  //     name: 'name',
  //     label: language.worker.table.name,
  //     options: {
  //       filter: false,
  //       sort: true,
  //     },
  //   },
  //   {
  //     name: 'email',
  //     label: language.worker.table.email,
  //     options: {
  //       filter: false,
  //       sort: true,
  //     },
  //   },
  //   {
  //     name: 'position',
  //     label: language.worker.table.position,
  //     options: {
  //       filter: true,
  //       sort: true,
  //       customBodyRender: value => (
  //         <Chip color="default" label={value} size="small" />
  //       ),
  //     },
  //   },
  //   {
  //     name: 'status',
  //     label: language.worker.table.status,
  //     options: {
  //       filter: true,
  //       sort: true,
  //       customBodyRender: value => (
  //         <Chip
  //           variant="outlined"
  //           color="primary"
  //           label={value}
  //           size="small"
  //         />
  //       ),
  //     },
  //   },
  //   {
  //     name: 'detail',
  //     label: language.worker.table.action,
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: value => (
  //         <div>
  //           <Tooltip title={language.action.detail}>
  //             <Button
  //               className={classes.actionButton}
  //               component={Link}
  //               to={`/worker/detail/${value}`}
  //               variant="outlined"
  //               color="default"
  //               size="small"
  //             >
  //               <ListIcon />
  //             </Button>
  //           </Tooltip>
  //           <Tooltip title={language.action.update}>
  //             <Button
  //               className={classes.actionButton}
  //               component={Link}
  //               to={`/worker/update/${value}`}
  //               variant="outlined"
  //               color="default"
  //               size="small"
  //             >
  //               <EditIcon />
  //             </Button>
  //           </Tooltip>
  //         </div>
  //       ),
  //     },
  //   },
  // ];
  const handleSearch = e => {
    const finds = [...fixedworkers].filter(
      x =>
        x.name
          .toLowerCase()
          .includes(e.currentTarget.value.toLowerCase()) ||
        x.email
          .toLowerCase()
          .includes(e.currentTarget.value.toLowerCase()),
    );
    setWorkers(finds);
  };
  return (
    <div>
      <Grid container direction="row">
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="baseline"
        >
          <TextField
            id="outlined-basic"
            label={language.action.search}
            variant="outlined"
            size="small"
            fullWidth
            onChange={e => handleSearch(e)}
            // onKeyPress={e => e.nativeEvent.keyCode === 13 handleSearch}
          />
        </Grid>
        {!isMobile() && (
          <Grid
            container
            direction="column"
            justify="flex-end"
            alignItems="baseline"
          >
            <Button
              className={classes.addButton}
              component={Link}
              to="/worker/new"
              variant="contained"
              color="primary"
            >
              {language.action.add}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper style={{ marginTop: 20 }}>
          {workers.length ? (
            <AvatarList
              data={workers}
              mappingColumns={{
                primary: 'name',
                secondary: 'email',
                tertiaryFilled: 'position',
                tertiaryOutlined: 'status',
                id: 'detail',
              }}
              detailLink="/worker/detail/"
              editLink="/worker/edit/"
              history={history}
            />
          ) : (
            <div />
          )}
          {/* <TpsDatatable
            title={language.worker.table.title}
            data={workers}
            columns={columns}
            language={language}
          /> */}
        </Paper>
      </Grid>
      {isMobile() && (
        <Fab
          color="primary"
          aria-label="add"
          component={Link}
          to="/worker/new"
          variant="round"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
          }}
        >
          <AddIcon />
        </Fab>
      )}
    </div>
  );
};

export default WorkerPage;
