import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const SubmitCancel = props => {
  const {
    reverse,
    submitClass,
    submitClick,
    submitTxt,
    cancelTxt,
    isCancelLink,
    cancelTo,
    cancelClick,
  } = props;
  return (
    <Grid
      container
      direction="row"
      justify="flex-end"
      alignItems="baseline"
    >
      {' '}
      {reverse && (
        <>
          <Button
            component={isCancelLink ? Link : 'button'}
            to={cancelTo || null}
            onClick={cancelClick || null}
            style={{
              marginTop: 20,
              marginRight: 10,
            }}
            variant="outlined"
            color="default"
          >
            {cancelTxt}
          </Button>
          <Button
            className={submitClass}
            onClick={submitClick}
            style={{
              marginTop: 20,
            }}
            variant="contained"
          >
            {submitTxt}
          </Button>
        </>
      )}{' '}
      {!reverse && (
        <>
          <Button
            className={submitClass}
            onClick={submitClick}
            style={{
              marginTop: 20,
              marginRight: 10,
            }}
            variant="contained"
          >
            {submitTxt}
          </Button>
          <Button
            component={isCancelLink ? Link : 'button'}
            to={cancelTo || null}
            onClick={cancelClick || null}
            style={{
              marginTop: 20,
            }}
            variant="outlined"
            color="default"
          >
            {cancelTxt}
          </Button>
        </>
      )}
    </Grid>
  );
};
export default SubmitCancel;
