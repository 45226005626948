import gql from 'graphql-tag';

export const CREATE_COMPLAINT = gql`
  mutation createComplaint($data: InComplaint!) {
    createComplaint(data: $data) {
      id
      user_id
      name
      description
      phone
      is_customer
      created_at
      updated_at
    }
  }
`;
