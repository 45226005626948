import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Grid,
  Paper,
  Fab,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

import { GET_TPSES } from '../../graphql/TpsQuery';
import AvatarList from '../Custom/AvatarList';
import GlobalContext from '../../globalContext';
import { isMobile } from '../../util';
import { globalStyles } from '../../styles';

const TpsPage = props => {
  const classes = globalStyles();
  const { query, language, history } = props;
  const [fixedTps, setFixedTps] = useState([]);
  const [tps, setTps] = useState([]);
  const { setGlobalLoading, setTitle, setIsDetailPage } = useContext(
    GlobalContext,
  );

  useEffect(() => {
    setTitle(language.tps.title.base);
  }, [setTitle, language.tps.title.base]);

  useEffect(() => {
    setIsDetailPage(false);
  }, [setIsDetailPage]);

  useEffect(() => {
    setGlobalLoading(true);
    const getTpses = async () => {
      const {
        data: { tpses },
      } = await query({
        query: GET_TPSES,
        fetchPolicy: 'no-cache',
      });

      setFixedTps(tpses);
      setTps(tpses);
    };

    getTpses();
    setGlobalLoading(false);
  }, [language.tps.title.base, setGlobalLoading, setTitle, query]);

  const handleSearch = e => {
    const finds = [...fixedTps].filter(x =>
      x.name
        .toLowerCase()
        .includes(e.currentTarget.value.toLowerCase()),
    );
    setTps(finds);
  };
  return (
    <div>
      <Grid container direction="row">
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="baseline"
        >
          <TextField
            id="outlined-basic"
            label={language.action.search}
            variant="outlined"
            size="small"
            fullWidth
            onChange={e => handleSearch(e)}
          />
        </Grid>
        {!isMobile() && (
          <Grid
            container
            direction="column"
            justify="flex-end"
            alignItems="baseline"
          >
            <Button
              className={classes.addButton}
              component={Link}
              to="/tps/new"
              variant="contained"
              color="primary"
            >
              {language.action.add}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper style={{ marginTop: 20 }}>
          {tps.length ? (
            <AvatarList
              data={tps}
              mappingColumns={{
                primary: 'name',
                secondary: 'jalan',
                tertiaryOutlined: 'status',
                id: 'uuid',
              }}
              detailLink="/tps/detail/"
              editLink="/tps/edit/"
              history={history}
            />
          ) : (
            <div />
          )}
        </Paper>
      </Grid>
      {isMobile() && (
        <Fab
          color="primary"
          aria-label="add"
          component={Link}
          to="/tps/new"
          variant="round"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
          }}
        >
          <AddIcon />
        </Fab>
      )}
    </div>
  );
};

export default TpsPage;
