import React, { useContext } from 'react';
import {
  Container,
  Grid,
  TextField,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Avatar,
  Typography,
} from '@material-ui/core';
import CommentIcon from '@material-ui/icons/InsertComment';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ErrorText from '../ErrorText';
import { phoneRegExp, setError } from '../../util';
import { CREATE_COMPLAINT } from '../../graphql/ComplaintQuery';
import GlobalContext from '../../globalContext';

const ComplaintForm = props => {
  const { mutate, history } = props;
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);

  return (
    <Container component="main" maxWidth="sm">
      <div
        style={{
          margin: '15vh auto 0 auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar>
          <CommentIcon />
        </Avatar>
        <Typography component="h1" variant="h5" gutterBottom>
          Complaint Form
        </Typography>
        <Formik
          initialValues={{
            userId: '',
            name: '',
            description: '',
            phone: '',
            isCustomer: '',
          }}
          validationSchema={Yup.object().shape({
            isCustomer: Yup.string().required(),
            userId: Yup.string().when(
              'isCustomer',
              (isCustomer, schema) => {
                if (parseInt(isCustomer, 10) === 1) {
                  return schema.required();
                }
                return schema;
              },
            ),
            name: Yup.string().required(),
            description: Yup.string().required(),
            phone: Yup.string()
              .required()
              .matches(phoneRegExp, 'Phone number not valid'),
          })}
          onSubmit={async (
            { userId, name, description, phone, isCustomer },
            { resetForm },
          ) => {
            try {
              const data = {
                user_id: userId,
                name,
                description,
                phone,
                is_customer: parseInt(isCustomer, 10) === 1,
              };
              setGlobalLoading(true);
              await mutate({
                mutation: CREATE_COMPLAINT,
                variables: { data },
                fetchPolicy: 'no-cache',
                onError: error => {
                  console.log('==> Error execute mutation', error);
                  setSnack({
                    variant: 'error',
                    message:
                      'Error execute Mutation CREATE_COMPLAINT',
                    opened: true,
                  });
                },
              });

              setSnack({
                variant: 'success',
                message:
                  'Thank You for your feedback, we appreciate it',
                opened: true,
              });
              resetForm();
              setGlobalLoading(false);
              history.replace('/complaint/thankyou');
            } catch (error) {
              setGlobalLoading(false);
              setError(setSnack, error);
            }
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            values,
            handleChange,
          }) => (
            <form noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    autoFocus
                    name="userId"
                    variant="outlined"
                    fullWidth
                    id="userId"
                    label="User ID"
                    onChange={handleChange}
                    value={values.userId}
                  />
                  {errors.userId && touched.userId && (
                    <ErrorText text={errors.userId} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    required
                    name="name"
                    variant="outlined"
                    fullWidth
                    id="name"
                    label="Name"
                    onChange={handleChange}
                    value={values.name}
                  />
                  {errors.name && touched.name && (
                    <ErrorText text={errors.name} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    required
                    multiline
                    rows={7}
                    name="description"
                    variant="outlined"
                    fullWidth
                    id="description"
                    label="Description"
                    onChange={handleChange}
                    value={values.description}
                  />
                  {errors.description && touched.description && (
                    <ErrorText text={errors.description} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    required
                    name="phone"
                    variant="outlined"
                    fullWidth
                    id="phone"
                    label="Phone Number"
                    onChange={handleChange}
                    value={values.phone}
                  />
                  {errors.phone && touched.phone && (
                    <ErrorText text={errors.phone} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Have TPS3R Account?
                    </InputLabel>
                    <Select
                      name="isCustomer"
                      id="isCustomer"
                      fullWidth
                      value={values.isCustomer}
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  </FormControl>
                  {errors.isCustomer && touched.isCustomer && (
                    <ErrorText text={errors.isCustomer} />
                  )}
                </Grid>
                <Button
                  onClick={handleSubmit}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Sign Up
                </Button>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </Container>
  );
};

export default ComplaintForm;
