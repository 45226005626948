import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import InfiniteScroll from 'react-infinite-scroll-component';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { blue, red, green } from '@material-ui/core/colors';
import { default as mainStyles } from '../../styles';
import { color, isMobile, getPaginatedItems } from '../../util';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  greenClass: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
  },
  redClass: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
  },
  blueClass: {
    color: theme.palette.getContrastText(blue[600]),
    backgroundColor: blue[600],
  },
}));

export default function AvatarList(props) {
  const classes = useStyles();
  const globalClasses = mainStyles();
  const {
    data,
    mappingColumns,
    detailLink,
    editLink,
    deleteLink,
    history,
    limit,
    fetch,
    moreData,
  } = props;
  const lim = limit || 20;
  const page = Math.ceil(data.length / lim);
  const [curPage, setCurrentPage] = useState(1);
  const [maxReached, setMaxReached] = useState(false);
  const [displayed, setDisplayed] = useState([]);

  const fetchMore = async () => {
    if (fetch) {
      await fetch();
    } else if (curPage <= page) {
      const newDisplayed = getPaginatedItems(data, curPage + 1, 20);
      setDisplayed([...displayed, ...newDisplayed]);
      setCurrentPage(curPage + 1);
    }
  };
  useEffect(() => {
    if (moreData) setDisplayed(d => [...d, ...moreData]);
  }, [moreData]);

  useEffect(() => {
    if (curPage && curPage > page) setMaxReached(true);
  }, [curPage, page]);

  useEffect(() => {
    const getInitial = () => {
      if (fetch) setDisplayed(data);
      else {
        const firstDisplayed = getPaginatedItems(data, 1, 20);
        setCurrentPage(1);
        setMaxReached(false);
        setDisplayed(firstDisplayed);
      }
    };
    getInitial();
    setCurrentPage(1);
  }, [data, fetch]);

  return (
    <List className={classes.root}>
      <InfiniteScroll
        dataLength={displayed.length}
        next={fetchMore}
        hasMore={!maxReached}
        loader={
          !maxReached && (
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ paddingLeft: '20px' }}
            >
              Loading ...
            </Typography>
          )
        }
      >
        {displayed.map((item, idx) => (
          <React.Fragment key={`${idx}asdasd`}>
            <ListItem
              alignItems="flex-start"
              onClick={() =>
                history
                  ? history.replace(
                      detailLink + item[mappingColumns.id],
                    )
                  : null
              }
            >
              {/* component={Link} to={detailLink + item[mappingColumns.id]}> */}
              <ListItemAvatar style={{ marginTop: '0px' }}>
                <IconButton
                  component={Link}
                  to={detailLink + item[mappingColumns.id]}
                >
                  <Avatar
                    alt={item[mappingColumns.primary]}
                    src="/static/images/avatar/1.jpg"
                    className={color(
                      item[mappingColumns.primary]
                        .charAt(0)
                        .toUpperCase(),
                      globalClasses,
                    )}
                  >
                    {item[mappingColumns.primary]
                      .charAt(0)
                      .toUpperCase()}
                  </Avatar>
                </IconButton>
              </ListItemAvatar>
              <ListItemText
                primary={item[mappingColumns.primary]}
                primaryTypographyProps={{
                  style: {
                    textOverflow: 'ellipsis',
                  },
                }}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                      style={{ textOverflow: 'ellipsis' }}
                    >
                      {`${item[mappingColumns.secondary]} `}
                    </Typography>
                    {item[mappingColumns.tertiaryFilled] && (
                      <Chip
                        size="small"
                        style={{
                          fontSize: '9px',
                          backgroundColor:
                            item[mappingColumns.tertiaryFilledBgd] ||
                            '#bbb',
                          color:
                            item[mappingColumns.tertiaryFilledClr] ||
                            'white',
                        }}
                        label={item[mappingColumns.tertiaryFilled]}
                      />
                    )}{' '}
                    &nbsp;
                    {item[mappingColumns.tertiaryOutlined] && (
                      <Chip
                        size="small"
                        style={{ fontSize: '9px' }}
                        label={item[mappingColumns.tertiaryOutlined]}
                        variant="outlined"
                      />
                    )}
                  </>
                }
              />
              <ListItemSecondaryAction
                style={{ right: isMobile() ? '5px' : '16px' }}
              >
                {detailLink && !isMobile() && (
                  <IconButton
                    edge="start"
                    aria-label="see detail"
                    component={Link}
                    to={detailLink + item[mappingColumns.id]}
                  >
                    <VisibilityIcon />
                  </IconButton>
                )}
                {editLink && (
                  <IconButton
                    edge="start"
                    aria-label="edit"
                    component={Link}
                    to={editLink + item[mappingColumns.id]}
                  >
                    <CreateIcon />
                  </IconButton>
                )}
                {deleteLink && (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() =>
                      deleteLink(item[mappingColumns.id])
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ))}
      </InfiniteScroll>
    </List>
  );
}
