/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-lonely-if */
import React, { useState, useContext, useEffect } from 'react';
import {
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import ImageUploader from 'react-images-upload';
import Cookies from 'universal-cookie';

import ErrorText from '../ErrorText';
import TpsMapBox from '../Custom/TpsMapBox';
import SubmitCancel from '../Custom/SubmitCancelBtn';
import GlobalContext from '../../globalContext';
import { CREATE_USER, CREATE_WORKER } from '../../graphql/UserQuery';
import { globalStyles } from '../../styles';
import { phoneRegExp } from '../../util';

const cookies = new Cookies();
// import { prepUploads } from '../../util';

const UserForm = props => {
  const classes = globalStyles();
  const { history, mutate, page, language } = props;
  const { setSnack, setGlobalLoading, setTitle } = useContext(
    GlobalContext,
  );
  const [imagesKtp, setImagesKtp] = useState([]);
  const [imagesRumah, setImagesRumah] = useState([]);
  const [buttonUploadKtp, setButtonUploadKtp] = useState(true);
  const [buttonUploadRumah, setButtonUploadRumah] = useState(true);
  const [errorTextIdCard, setErrorTextIdCard] = useState(false);
  const [errorTextHouse, setErrorTextHouse] = useState(false);

  const onUploadKTP = async pictureFiles => {
    try {
      // const options = {
      //   maxSizeMB: 2,
      //   maxWidthOrHeight: 1920,
      //   useWebWorker: true,
      // };
      // const fileComp = await imageCompression(
      //   pictureFiles[0],
      //   options,
      // );

      // debugger;
      // const prep = prepUploads(pictureFiles);
      // const getBuffered = await Promise.all(prep);
      // setImagesKtp(getBuffered);
      setImagesKtp(pictureFiles);
      if (pictureFiles.length === 0) {
        setErrorTextIdCard(true);
      } else {
        setErrorTextIdCard(false);
      }

      if (imagesKtp.length === 1) {
        setButtonUploadKtp(true);
        setErrorTextIdCard(false);
      } else {
        setButtonUploadKtp(false);
      }
    } catch (error) {}
  };

  const onUploadRumah = async pictureFiles => {
    // setImagesRumah(pictureFiles);
    // const options = {
    //   maxSizeMB: 2,
    //   maxWidthOrHeight: 1920,
    //   useWebWorker: true,
    // };
    // const fileComp = await imageCompression(pictureFiles[0], options);

    // // debugger;
    // setImagesKtp([fileComp]);
    // const prep = prepUploads(pictureFiles);
    // const getBuffered = await Promise.all(prep);
    // setImagesRumah(getBuffered);
    setImagesRumah(pictureFiles);
    if (pictureFiles.length === 0) {
      setErrorTextHouse(true);
    } else {
      setErrorTextHouse(false);
    }

    if (imagesRumah.length === 1) {
      setButtonUploadRumah(true);
    } else {
      setButtonUploadRumah(false);
    }
  };

  useEffect(() => {
    const title =
      page === 'customer'
        ? language.customer.title.add
        : page === 'worker'
        ? language.worker.title.add
        : 'User';
    setTitle(title);
  }, [
    setTitle,
    page,
    language.customer.title.add,
    language.worker.title.add,
  ]);

  const CustomerForm = () => {
    return (
      <div>
        <Grid container spacing={3}>
          <Formik
            initialValues={{
              name: '',
              email: '',
              phone: '',
              category: '',
              otherCategory: '',
              size: '',
              otherSize: '',
              serviceFee: '',
              otherServiceFee: '',
              lane: '',
              otherLane: '',
              provinsi: '',
              kabupaten: '',
              kecamatan: '',
              kelurahan: '',
              desaAdat: '',
              banjar: '',
              dusun: '',
              jalan: '',
              latitude: 0,
              longitude: 0,
              notes: '',
              registrationDate: new Date().toISOString().slice(0, 10),
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required(language.validate.required),
              phone: Yup.string()
                .required(language.validate.required)
                .matches(phoneRegExp, language.validate.phone),
              category: Yup.string().required(
                language.validate.required,
              ),
              otherCategory: Yup.string().when('category', {
                is: cat => cat === 'Lainnya',
                then: Yup.string().required(
                  language.validate.required,
                ),
              }),
              size: Yup.string().required(language.validate.required),
              otherSize: Yup.string().when('size', {
                is: size => size === 'Lainnya',
                then: Yup.string().required(
                  language.validate.required,
                ),
              }),
              serviceFee: Yup.string().required(
                language.validate.required,
              ),
              otherServiceFee: Yup.string().when('serviceFee', {
                is: fee => fee === 'Lainnya',
                then: Yup.string().required(
                  language.validate.required,
                ),
              }),
              lane: Yup.string().required(language.validate.required),
              otherLane: Yup.string().when('lane', {
                is: lane => lane === 'Lainnya',
                then: Yup.string().required(
                  language.validate.required,
                ),
              }),
              latitude: Yup.number()
                .required(language.validate.required)
                .min(-90, language.validate.latitudeRange)
                .max(90, language.validate.latitudeRange)
                .typeError(language.validate.latitudeRange),
              longitude: Yup.number()
                .required(language.validate.required)
                .min(-180, language.validate.longitudeRange)
                .max(180, language.validate.longitudeRange)
                .typeError(language.validate.longitudeRange),
              registrationDate: Yup.date().required(
                language.validate.required,
              ),
            })}
            onSubmit={async (
              {
                name,
                email,
                phone,
                category,
                otherCategory,
                size,
                otherSize,
                serviceFee,
                otherServiceFee,
                lane,
                otherLane,
                provinsi,
                kabupaten,
                kecamatan,
                kelurahan,
                desaAdat,
                banjar,
                dusun,
                jalan,
                registrationDate,
                latitude,
                longitude,
                notes,
              },
              { resetForm },
            ) => {
              try {
                setGlobalLoading(true);
                const data = {
                  tps_id: cookies.get('tps_id')
                    ? parseInt(cookies.get('tps_id'), 10)
                    : null,
                  name,
                  provinsi,
                  kabupaten,
                  kecamatan,
                  kelurahan,
                  desa_adat: desaAdat,
                  banjar,
                  dusun,
                  jalan,
                  email,
                  location: {
                    latitude: parseFloat(latitude),
                    longitude: parseFloat(longitude),
                  },
                  registration_date: registrationDate,
                  photo: {
                    identity: imagesKtp,
                    house: imagesRumah,
                  },
                  phone,
                  category:
                    category === 'Lainnya' ? otherCategory : category,
                  size: size === 'Lainnya' ? otherSize : size,
                  service_fee:
                    serviceFee === 'Lainnya'
                      ? parseInt(otherServiceFee, 10)
                      : parseInt(serviceFee, 10),
                  lane:
                    lane === 'Lainnya'
                      ? parseInt(otherLane, 10)
                      : parseInt(lane, 10),
                  notes,
                };

                await mutate({
                  mutation: CREATE_USER,
                  variables: { data },
                  fetchPolicy: 'no-cache',
                  onError: error => {
                    console.log('==> Error execute mutation', error);
                  },
                });
                setSnack({
                  variant: 'success',
                  message: `${name} berhasil ditambahkan!`,
                  opened: true,
                });
                resetForm();
                history.replace('/user/list');
              } catch (error) {
                console.log('Error create User', error);
                setSnack({
                  variant: 'error',
                  message: 'Gagal menambahkan Pelanggan!',
                  opened: true,
                });
                setGlobalLoading(false);
              }
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              values,
              handleChange,
              setFieldValue,
            }) => (
              <>
                <Grid item xs={12} sm={12} md={12} lg={7}>
                  <form noValidate>
                    <Paper className={classes.root}>
                      <h2>{language.customer.form.title}</h2>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            autoFocus
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label={language.customer.form.name}
                            name="name"
                            autoComplete="name"
                            onChange={handleChange}
                            value={values.name}
                          />
                          {errors.name && touched.name && (
                            <ErrorText text={errors.name} />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="email"
                            label={language.customer.form.email}
                            type="email"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="phone"
                            label={language.customer.form.phone}
                            type="phone"
                            name="phone"
                            onChange={handleChange}
                            value={values.phone}
                          />
                          {errors.phone && touched.phone && (
                            <ErrorText text={errors.phone} />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <FormControl margin="normal" fullWidth>
                            <InputLabel id="category">
                              {language.customer.form.category.base}
                            </InputLabel>
                            <Select
                              margin="dense"
                              fullWidth
                              required
                              labelId="category"
                              id="category"
                              name="category"
                              value={values.category}
                              onChange={handleChange}
                            >
                              <MenuItem value="Rumah">Rumah</MenuItem>
                              <MenuItem value="Villa">Villa</MenuItem>
                              <MenuItem value="Kos">Kos</MenuItem>
                              <MenuItem value="Banjar">
                                Banjar
                              </MenuItem>
                              <MenuItem value="Usaha">Usaha</MenuItem>
                              <MenuItem value="Hotel">Hotel</MenuItem>
                              <MenuItem value="Lainnya">
                                Lainnya
                              </MenuItem>
                            </Select>
                          </FormControl>
                          {errors.category && touched.category && (
                            <ErrorText text={errors.category} />
                          )}
                        </Grid>
                        {values.category === 'Lainnya' ? (
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            <TextField
                              margin="normal"
                              fullWidth
                              autoFocus
                              id="otherCategory"
                              name="otherCategory"
                              label={
                                language.customer.form.category.other
                              }
                              onChange={handleChange}
                              value={values.otherCategory}
                            />
                            {errors.otherCategory &&
                              touched.otherCategory && (
                                <ErrorText
                                  text={errors.otherCategory}
                                />
                              )}
                          </Grid>
                        ) : null}
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <FormControl margin="normal" fullWidth>
                            <InputLabel id="size">
                              {language.customer.form.size.base}
                            </InputLabel>
                            <Select
                              margin="dense"
                              fullWidth
                              required
                              labelId="size"
                              id="size"
                              name="size"
                              value={values.size}
                              onChange={handleChange}
                            >
                              <MenuItem value="S">S</MenuItem>
                              <MenuItem value="M">M</MenuItem>
                              <MenuItem value="L">L</MenuItem>
                              <MenuItem value="XL">XL</MenuItem>
                              <MenuItem value="Lainnya">
                                Lainnya
                              </MenuItem>
                            </Select>
                          </FormControl>
                          {errors.size && touched.size && (
                            <ErrorText text={errors.size} />
                          )}
                        </Grid>
                        {values.size === 'Lainnya' ? (
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            <TextField
                              margin="normal"
                              fullWidth
                              autoFocus
                              id="otherSize"
                              name="otherSize"
                              label={
                                language.customer.form.size.other
                              }
                              onChange={handleChange}
                              value={values.otherSize}
                            />
                            {errors.otherSize &&
                              touched.otherSize && (
                                <ErrorText text={errors.otherSize} />
                              )}
                          </Grid>
                        ) : null}
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <FormControl margin="normal" fullWidth>
                            <InputLabel id="serviceFee">
                              {language.customer.form.fee.base}
                            </InputLabel>
                            <Select
                              margin="dense"
                              fullWidth
                              required
                              labelId="serviceFee"
                              id="serviceFee"
                              name="serviceFee"
                              value={values.serviceFee}
                              onChange={handleChange}
                            >
                              <MenuItem value={50000}>
                                Rp. 50.000
                              </MenuItem>
                              <MenuItem value={100000}>
                                Rp. 100.000
                              </MenuItem>
                              <MenuItem value={150000}>
                                Rp. 150.000
                              </MenuItem>
                              <MenuItem value="Lainnya">
                                Lainnya
                              </MenuItem>
                            </Select>
                          </FormControl>
                          {errors.serviceFee &&
                            touched.serviceFee && (
                              <ErrorText text={errors.serviceFee} />
                            )}
                        </Grid>
                        {values.serviceFee === 'Lainnya' ? (
                          <Grid item xs={12} sm={6} md={3} lg={3}>
                            <TextField
                              margin="normal"
                              fullWidth
                              id="otherServiceFee"
                              name="otherServiceFee"
                              label={language.customer.form.fee.other}
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={values.otherServiceFee}
                              onChange={handleChange}
                            />
                            {errors.otherServiceFee &&
                              touched.otherServiceFee && (
                                <ErrorText
                                  text={errors.otherServiceFee}
                                />
                              )}
                          </Grid>
                        ) : null}
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <FormControl margin="normal" fullWidth>
                            <InputLabel id="lane">
                              {language.customer.form.lane.base}
                            </InputLabel>
                            <Select
                              margin="dense"
                              fullWidth
                              required
                              labelId="lane"
                              id="lane"
                              name="lane"
                              value={values.lane}
                              onChange={handleChange}
                            >
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={4}>4</MenuItem>
                              <MenuItem value={5}>5</MenuItem>
                              <MenuItem value={6}>6</MenuItem>
                              <MenuItem value={7}>7</MenuItem>
                              <MenuItem value={8}>8</MenuItem>
                              <MenuItem value={9}>9</MenuItem>
                              <MenuItem value={10}>10</MenuItem>
                              <MenuItem value="Lainnya">
                                Lainnya
                              </MenuItem>
                            </Select>
                          </FormControl>
                          {errors.lane && touched.lane && (
                            <ErrorText text={errors.lane} />
                          )}
                        </Grid>
                        {values.lane === 'Lainnya' ? (
                          <Grid item xs={12} sm={6} md={3} lg={3}>
                            <TextField
                              margin="normal"
                              fullWidth
                              autoFocus
                              id="otherLane"
                              name="otherLane"
                              label={
                                language.customer.form.lane.other
                              }
                              value={values.otherLane}
                              onChange={handleChange}
                            />
                            {errors.otherLane &&
                              touched.otherLane && (
                                <ErrorText text={errors.otherLane} />
                              )}
                          </Grid>
                        ) : null}
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="provinsi"
                            label={
                              language.tps.form.tps.address.provinsi
                            }
                            name="provinsi"
                            onChange={handleChange}
                            value={values.provinsi}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="kabupaten"
                            label={
                              language.tps.form.tps.address.kabupaten
                            }
                            name="kabupaten"
                            onChange={handleChange}
                            value={values.kabupaten}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="kecamatan"
                            label={
                              language.tps.form.tps.address.kecamatan
                            }
                            name="kecamatan"
                            onChange={handleChange}
                            value={values.kecamatan}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="kelurahan"
                            label={
                              language.tps.form.tps.address.kelurahan
                            }
                            name="kelurahan"
                            onChange={handleChange}
                            value={values.kelurahan}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={4}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="desaAdat"
                            label={
                              language.tps.form.tps.address.desaAdat
                            }
                            name="desaAdat"
                            onChange={handleChange}
                            value={values.desaAdat}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={2}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="banjar"
                            label={
                              language.tps.form.tps.address.banjar
                            }
                            name="banjar"
                            onChange={handleChange}
                            value={values.banjar}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={2}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="dusun"
                            label={
                              language.tps.form.tps.address.dusun
                            }
                            name="dusun"
                            onChange={handleChange}
                            value={values.dusun}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="jalan"
                            label={
                              language.tps.form.tps.address.jalan
                            }
                            name="jalan"
                            onChange={handleChange}
                            value={values.jalan}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            multiline
                            rows={5}
                            id="notes"
                            label={language.customer.form.notes}
                            type="notes"
                            name="notes"
                            onChange={handleChange}
                            value={values.notes}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="latitude"
                            label="Latitude"
                            name="latitude"
                            autoComplete="latitude"
                            onChange={handleChange}
                            value={values.latitude}
                          />
                          {errors.latitude && touched.latitude && (
                            <ErrorText text={errors.latitude} />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="longitude"
                            label="Longitude"
                            name="longitude"
                            autoComplete="longitude"
                            onChange={handleChange}
                            value={values.longitude}
                          />
                          {errors.longitude && touched.longitude && (
                            <ErrorText text={errors.longitude} />
                          )}
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <ImageUploader
                            withIcon
                            buttonText={
                              language.customer.form.photo.idCard
                                .buttonText
                            }
                            onChange={onUploadKTP}
                            imgExtension={[
                              '.jpg',
                              '.gif',
                              '.png',
                              '.gif',
                            ]}
                            maxFileSize={5000000}
                            withPreview
                            label={
                              language.customer.form.photo.idCard
                                .label
                            }
                            singleImage
                            disabled
                            saveButtons={{
                              display: buttonUploadKtp
                                ? 'block'
                                : 'none',
                            }}
                            errorStyle={{
                              display: errorTextIdCard
                                ? 'block'
                                : 'none',
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <ImageUploader
                            withIcon
                            buttonText={
                              language.customer.form.photo.house
                                .buttonText
                            }
                            onChange={onUploadRumah}
                            imgExtension={[
                              '.jpg',
                              '.gif',
                              '.png',
                              '.gif',
                            ]}
                            maxFileSize={2000000}
                            withPreview
                            label={
                              language.customer.form.photo.house.label
                            }
                            singleImage
                            disabled
                            saveButtons={{
                              display: buttonUploadRumah
                                ? 'block'
                                : 'none',
                            }}
                            errorStyle={{
                              display: errorTextHouse
                                ? 'block'
                                : 'none',
                            }}
                          />
                        </Grid>

                        <SubmitCancel
                          submitClass={classes.saveButton}
                          submitClick={handleSubmit}
                          submitTxt={language.action.save}
                          cancelTxt={language.action.back}
                          isCancelLink
                          cancelTo="/user/list"
                        />
                      </Grid>
                    </Paper>
                  </form>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  <TpsMapBox
                    location={{
                      longitude: values.longitude,
                      latitude: values.latitude,
                    }}
                    setLongLat={({ latitude, longitude }) => {
                      setFieldValue('latitude', latitude);
                      setFieldValue('longitude', longitude);
                    }}
                    editable
                  />
                </Grid>
              </>
            )}
          </Formik>
        </Grid>
      </div>
    );
  };

  const WorkerForm = () => {
    return (
      <div>
        <Grid container spacing={0} justify="center">
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Formik
              initialValues={{
                name: '',
                email: '',
                provinsi: '',
                kabupaten: '',
                kecamatan: '',
                kelurahan: '',
                desaAdat: '',
                banjar: '',
                dusun: '',
                jalan: '',
                position: '',
                phone: '',
                notes: '',
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required(
                  language.validate.required,
                ),
                phone: Yup.string()
                  .required(language.validate.required)
                  .matches(phoneRegExp, language.validate.phone),
              })}
              onSubmit={async (
                {
                  name,
                  email,
                  provinsi,
                  kabupaten,
                  kecamatan,
                  kelurahan,
                  desaAdat,
                  banjar,
                  dusun,
                  jalan,
                  position,
                  phone,
                  notes,
                },
                { resetForm },
              ) => {
                try {
                  setGlobalLoading(true);
                  const data = {
                    name,
                    email,
                    provinsi,
                    kabupaten,
                    kecamatan,
                    kelurahan,
                    desa_adat: desaAdat,
                    banjar,
                    dusun,
                    jalan,
                    position,
                    phone,
                    registration_date: new Date(),
                    notes,
                  };

                  await mutate({
                    mutation: CREATE_WORKER,
                    variables: { data },
                    fetchPolicy: 'no-cache',
                    onError: error => {
                      console.log(
                        '==> Error execute mutation',
                        error,
                      );
                    },
                  });
                  setSnack({
                    variant: 'success',
                    message: `${name} ${language.alert.successSave}`,
                    opened: true,
                  });
                  resetForm();
                  history.replace('/worker/list');
                } catch (error) {
                  console.log('Error create worker', error);
                  setSnack({
                    variant: 'error',
                    message: `${language.alert.errorSave} ${error}`,
                    opened: true,
                  });
                  setGlobalLoading(false);
                }
              }}
            >
              {({
                errors,
                touched,
                handleSubmit,
                values,
                handleChange,
              }) => (
                <form noValidate>
                  <Paper className={classes.root}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          autoFocus
                          margin="normal"
                          required
                          fullWidth
                          id="name"
                          label={language.worker.form.name}
                          name="name"
                          autoComplete="name"
                          onChange={handleChange}
                          value={values.name}
                        />
                        {errors.name && touched.name && (
                          <ErrorText text={errors.name} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="email"
                          label={language.worker.form.email}
                          type="email"
                          name="email"
                          autoComplete="email"
                          onChange={handleChange}
                          value={values.email}
                        />
                        {errors.email && touched.email && (
                          <ErrorText text={errors.email} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="phone"
                          label={language.worker.form.phone}
                          type="phone"
                          name="phone"
                          autoComplete="phone"
                          onChange={handleChange}
                          value={values.phone}
                        />
                        {errors.phone && touched.phone && (
                          <ErrorText text={errors.phone} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormControl margin="normal" fullWidth>
                          <InputLabel id="position">
                            Posisi
                          </InputLabel>
                          <Select
                            margin="normal"
                            fullWidth
                            required
                            labelId={language.worker.form.position}
                            id="position"
                            name="position"
                            value={values.position}
                            onChange={handleChange}
                          >
                            <MenuItem value="" disabled>
                              --- Pilih ---
                            </MenuItem>
                            <MenuItem value="ADMIN">
                              {language.role.admin}
                            </MenuItem>
                            <MenuItem value="COLLECTOR">
                              {language.role.collector}
                            </MenuItem>
                            <MenuItem value="COMPOSTER">
                              {language.role.composter}
                            </MenuItem>
                            <MenuItem value="COORDINATOR">
                              {language.role.coordinator}
                            </MenuItem>
                            <MenuItem value="MONITOR">
                              {language.role.monitor}
                            </MenuItem>
                            <MenuItem value="SEPARATOR">
                              {language.role.separator}
                            </MenuItem>
                            <MenuItem value="AGGREGATOR">
                              {language.role.aggregator}
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {errors.position && touched.position && (
                          <ErrorText text={errors.position} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="provinsi"
                          label={language.worker.form.provinsi}
                          name="provinsi"
                          onChange={handleChange}
                          value={values.provinsi}
                        />
                        {errors.provinsi && touched.provinsi && (
                          <ErrorText text={errors.provinsi} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="kabupaten"
                          label={language.worker.form.kabupaten}
                          name="kabupaten"
                          onChange={handleChange}
                          value={values.kabupaten}
                        />
                        {errors.kabupaten && touched.kabupaten && (
                          <ErrorText text={errors.kabupaten} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="kecamatan"
                          label={language.worker.form.kecamatan}
                          name="kecamatan"
                          onChange={handleChange}
                          value={values.kecamatan}
                        />
                        {errors.kecamatan && touched.kecamatan && (
                          <ErrorText text={errors.kecamatan} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="kelurahan"
                          label={language.worker.form.kelurahan}
                          name="kelurahan"
                          onChange={handleChange}
                          value={values.kelurahan}
                        />
                        {errors.kelurahan && touched.kelurahan && (
                          <ErrorText text={errors.kelurahan} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="desaAdat"
                          label={language.worker.form.desaAdat}
                          name="desaAdat"
                          onChange={handleChange}
                          value={values.desaAdat}
                        />
                        {errors.desaAdat && touched.desaAdat && (
                          <ErrorText text={errors.desaAdat} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="banjar"
                          label={language.worker.form.banjar}
                          name="banjar"
                          onChange={handleChange}
                          value={values.banjar}
                        />
                        {errors.banjar && touched.banjar && (
                          <ErrorText text={errors.banjar} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="dusun"
                          label={language.worker.form.dusun}
                          name="dusun"
                          onChange={handleChange}
                          value={values.dusun}
                        />
                        {errors.dusun && touched.dusun && (
                          <ErrorText text={errors.dusun} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="jalan"
                          label={language.worker.form.jalan}
                          name="jalan"
                          onChange={handleChange}
                          value={values.jalan}
                        />
                        {errors.jalan && touched.jalan && (
                          <ErrorText text={errors.jalan} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          margin="normal"
                          fullWidth
                          multiline
                          rows={5}
                          id="notes"
                          label={language.worker.form.notes}
                          type="notes"
                          name="notes"
                          autoComplete="notes"
                          onChange={handleChange}
                          value={values.notes}
                        />
                      </Grid>
                      <SubmitCancel
                        submitClass={classes.saveButton}
                        submitClick={handleSubmit}
                        submitTxt={language.action.save}
                        cancelTxt={language.action.back}
                        isCancelLink
                        cancelTo="/worker/list"
                      />
                    </Grid>
                  </Paper>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div>
      {page === 'customer' ? (
        <CustomerForm />
      ) : page === 'worker' ? (
        <WorkerForm />
      ) : null}
    </div>
  );
};

export default UserForm;
