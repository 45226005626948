import React, { useEffect, useContext } from 'react';
import {
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import Cookies from 'universal-cookie/cjs';
import moment from 'moment';
import randomstring from 'randomstring';
import * as Yup from 'yup';
import { Formik } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import ErrorText from '../ErrorText';
import SubmitCancel from '../Custom/SubmitCancelBtn';
import GlobalContext from '../../globalContext';
import {
  CREATE_SALES_RESIDU,
  GET_SALES_RESIDUAL,
} from '../../graphql/ProcessingQuery';
import { globalStyles } from '../../styles';

const OrganicForm = props => {
  const classes = globalStyles();
  const { language, history, mutate } = props;
  const { setSnack, setGlobalLoading, setTitle } = useContext(
    GlobalContext,
  );

  const weightUnits = [
    { value: 'Ton', label: 'Ton' },
    { value: 'Kwintal', label: 'Kwintal' },
    { value: 'Kilogram', label: 'Kilogram' },
    { value: 'Gram', label: 'Gram' },
  ];

  useEffect(() => {
    setTitle(language.processing.addProcessing);
    setGlobalLoading(false);
  }, [language.processing.addProcessing, setGlobalLoading, setTitle]);

  return (
    <Grid
      container
      direction="row"
      justify="flex-end"
      alignItems="baseline"
    >
      <Grid item xs={12} sm={12} md={12} lg={7}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Formik
            initialValues={{
              payment_at: moment().format('YYYY MM DD'),
              total_truck: 0,
              tpa: '',
              estimate_weight: 0,
              weight_unit: 'Kilogram',
            }}
            validationSchema={Yup.object().shape({
              payment_at: Yup.date().required(
                language.validate.required,
              ),
              total_truck: Yup.number()
                .min(1, language.processing.form.minError1)
                .required(language.validate.required),
              tpa: Yup.string().required(language.validate.required),
              estimate_weight: Yup.number()
                .min(1, language.processing.form.minError1)
                .required(language.validate.required),
              weight_unit: Yup.string().required(
                language.validate.required,
              ),
            })}
            onSubmit={async (
              {
                payment_at,
                total_truck,
                tpa,
                estimate_weight,
                weight_unit,
              },
              { resetForm },
            ) => {
              try {
                const cookie = new Cookies();
                setGlobalLoading(true);
                const data = {
                  payment_at: moment(payment_at).format('YYYY-MM-DD'),
                  worker_id: cookie.get('id'),
                  total_truck: parseFloat(total_truck),
                  tpa,
                  estimate_weight: parseFloat(estimate_weight),
                  weight_unit,
                };

                await mutate({
                  mutation: CREATE_SALES_RESIDU,
                  variables: { data },
                  fetchPolicy: 'no-cache',
                  onError: error => {
                    console.log('==> Error execute mutation', error);
                  },
                  update: (
                    proxy,
                    { data: { createSalesResidue } },
                  ) => {
                    const olddata = proxy.readQuery({
                      query: GET_SALES_RESIDUAL,
                    });
                    proxy.writeQuery({
                      query: GET_SALES_RESIDUAL,
                      data: {
                        ...olddata,
                        salesResidues: [
                          ...olddata.salesResidues,
                          createSalesResidue,
                        ],
                      },
                    });
                  },
                });
                setSnack({
                  variant: 'success',
                  message: `Pengolahan residu berhasil ditambahkan!`,
                  opened: true,
                });
                resetForm();
                history.replace('/income/residual');
              } catch (error) {
                console.log('Error create salesResidu', error);
                setSnack({
                  variant: 'error',
                  message: 'Gagal menambahkan pengolahan residu!',
                  opened: true,
                });
                setGlobalLoading(false);
              }
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              values,
              // handleChange,
              setFieldValue,
            }) => (
              <form noValidate>
                <Paper className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <KeyboardDatePicker
                        margin="dense"
                        // id="date-picker-dialog"
                        label={language.processing.form.date}
                        format="MM/dd/yyyy"
                        value={values.payment_at}
                        onChange={e => {
                          const dateval = moment(e).format(
                            'YYYY MM DD',
                          );
                          setFieldValue('payment_at', dateval, false);
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        autoFocus
                        margin="dense"
                        required
                        fullWidth
                        id="total_truck"
                        label={language.processing.form.total_truck}
                        name="total_truck"
                        autoComplete="total_truck"
                        onChange={e =>
                          setFieldValue(
                            'total_truck',
                            e.target.value,
                            false,
                          )
                        }
                        value={values.total_truck}
                      />
                      {errors.total_truck && touched.total_truck && (
                        <ErrorText text={errors.total_truck} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        autoFocus
                        margin="dense"
                        required
                        fullWidth
                        id="tpa"
                        label={language.processing.form.tpa}
                        name="tpa"
                        autoComplete="tpa"
                        onChange={e =>
                          setFieldValue('tpa', e.target.value, false)
                        }
                        value={values.tpa}
                      />
                      {errors.tpa && touched.tpa && (
                        <ErrorText text={errors.tpa} />
                      )}
                    </Grid>

                    <Grid item xs={6} sm={6} md={3} lg={3}>
                      <TextField
                        autoFocus
                        margin="dense"
                        required
                        fullWidth
                        id="estimate_weight"
                        label={language.processing.form.weight}
                        name="estimate_weight"
                        autoComplete="estimate_weight"
                        onChange={e =>
                          setFieldValue(
                            'estimate_weight',
                            e.target.value,
                            false,
                          )
                        }
                        value={values.estimate_weight}
                      />
                      {errors.estimate_weight &&
                        touched.estimate_weight && (
                          <ErrorText text={errors.estimate_weight} />
                        )}
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel id="weight_unit">
                          {language.processing.form.unit}
                        </InputLabel>
                        <Select
                          margin="dense"
                          fullWidth
                          required
                          labelId="weight_unit"
                          id="weight_unit"
                          name="weight_unit"
                          value={values.weight_unit}
                          onChange={e => {
                            setFieldValue(
                              'weight_unit',
                              e.target.value,
                              false,
                            );
                          }}
                        >
                          <MenuItem value="Default" disabled>
                            --- Pilih ---
                          </MenuItem>
                          {weightUnits.map(z => (
                            <MenuItem
                              key={randomstring.generate(3)}
                              value={z.value}
                            >
                              <Typography variant="body2">
                                {z.label}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.weight_unit &&
                          touched.weight_unit && (
                            <ErrorText text={errors.weight_unit} />
                          )}
                      </FormControl>
                    </Grid>
                    <SubmitCancel
                      submitClass={classes.saveButton}
                      submitClick={handleSubmit}
                      submitTxt={language.action.save}
                      cancelTxt={language.action.back}
                      isCancelLink
                      cancelTo="/income/residual"
                    />
                  </Grid>
                </Paper>
              </form>
            )}
          </Formik>
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

export default OrganicForm;
