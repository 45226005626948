import React, { useEffect, useContext } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment-timezone';
import NumberFormat from 'react-number-format';
import AsyncSelect from 'react-select/async';
import GlobalContext from '../../globalContext';
import { globalStyles } from '../../styles';
import ErrorText from '../ErrorText';
import { rupia } from '../../util';
import { SEARCH_USERS } from '../../graphql/UserQuery';
import { CREATE_EXPENDITURE } from '../../graphql/ExpenditureQuery';

const OperationalForm = props => {
  const classes = globalStyles();
  const { language, history, mutate, query } = props;
  const { setSnack, setGlobalLoading, setTitle } = useContext(
    GlobalContext,
  );

  useEffect(() => {
    setTitle(language.expenditure.addOperational);
  }, [setTitle, language.expenditure.addOperational]);

  const expenditures = [
    { value: 'Sarung Tangan', label: 'Sarung Tangan' },
    { value: 'Bensin', label: 'Bensin' },
    { value: 'Masker', label: 'Masker' },
    { value: 'Voucher Listrik', label: 'Voucher Listrik' },
    { value: 'Service', label: 'Service' },
    { value: 'Lainnya', label: 'Lainnya' },
  ];

  const expenditureTypes = [
    { value: 'Maintenance', label: 'Maintenance' },
    { value: 'Supply', label: 'Supply' },
    {
      value: 'Kesejahteraan Pekerja',
      label: 'Kesejahteraan Pekerja',
    },
    {
      value: 'Perlengkapan Operasional / Perlengkapan Diri',
      label: 'Perlengkapan Operasional / Perlengkapan Diri',
    },
    { value: 'Lainnya', label: 'Lainnya' },
  ];

  const NumberFormatCustom = props => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="Rp. "
      />
    );
  };

  const getReported = async ({ name }) => {
    const {
      data: { searchUsers },
    } = await query({
      query: SEARCH_USERS,
      variables: { name, position: 'MONITOR' },
      fetchPolicy: 'no-cache',
    });

    const options = searchUsers.map(l => ({
      value: l.id,
      label: `${l.name} - ${l.position}`,
    }));

    return options;
  };

  const loadOptions = inputValue => {
    return getReported({
      name: inputValue,
    });
  };

  const handleInputChange = newValue => {
    const inputValue = newValue.replace(/\W/g, '');
    return inputValue;
  };

  return (
    <div>
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Formik
              initialValues={{
                paymentAt: new Date().toISOString().slice(0, 10),
                item: '',
                otherItem: '',
                itemType: '',
                otherItemType: '',
                quantity: 0,
                unitPrice: 0,
                total: 0,
                worker: '',
              }}
              validationSchema={Yup.object().shape({
                item: Yup.string().required(
                  language.validate.required,
                ),
                otherItem: Yup.string().when('item', {
                  is: item => item === 'Lainnya',
                  then: Yup.string().required(
                    language.validate.required,
                  ),
                }),
                itemType: Yup.string().required(
                  language.validate.required,
                ),
                otherItemType: Yup.string().when('itemType', {
                  is: itemType => itemType === 'Lainnya',
                  then: Yup.string().required(
                    language.validate.required,
                  ),
                }),
                quantity: Yup.number()
                  .min(1, language.processing.form.minError1)
                  .required(language.validate.required),
                unitPrice: Yup.number()
                  .min(1, language.processing.form.minError1)
                  .required(language.validate.required),
                worker: Yup.string().required(
                  language.validate.required,
                ),
              })}
              onSubmit={async (
                {
                  paymentAt,
                  item,
                  itemType,
                  quantity,
                  unitPrice,
                  worker,
                },
                { resetForm },
              ) => {
                try {
                  setGlobalLoading(true);
                  const data = {
                    worker_id: worker.value,
                    item,
                    item_type: itemType,
                    quantity: parseFloat(quantity),
                    unit_price: parseFloat(unitPrice),
                    total:
                      parseFloat(quantity) * parseFloat(unitPrice),
                    payment_at: moment(paymentAt).format(
                      'YYYY-MM-DD',
                    ),
                  };

                  await mutate({
                    mutation: CREATE_EXPENDITURE,
                    variables: { data },
                    fetchPolicy: 'no-cache',
                    onError: error => {
                      console.log(
                        '==> Error execute mutation',
                        error,
                      );
                    },
                  });
                  setSnack({
                    variant: 'success',
                    message: `Pengeluaran Operasional berhasil ditambahkan!`,
                    opened: true,
                  });
                  resetForm();
                  history.replace('/expenditure/operational');
                } catch (error) {
                  console.log(
                    'Error create Operational Expenditure',
                    error,
                  );
                  setSnack({
                    variant: 'error',
                    message: 'Gagal menambahkan penjualan lapak!',
                    opened: true,
                  });
                  setGlobalLoading(false);
                }
              }}
            >
              {({
                errors,
                touched,
                handleSubmit,
                values,
                handleChange,
                setFieldValue,
              }) => (
                <form noValidate>
                  <Paper className={classes.root}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <KeyboardDatePicker
                          autoFocus
                          margin="none"
                          id="paymentAt"
                          name="paymentAt"
                          label={language.expenditure.form.date}
                          format="MM/dd/yyyy"
                          value={values.paymentAt}
                          onChange={e => {
                            const dateval = moment(e).format(
                              'YYYY MM DD',
                            );
                            setFieldValue('paymentAt', dateval);
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                        {errors.paymentAt && touched.paymentAt && (
                          <ErrorText text={errors.paymentAt} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={8} lg={8}>
                        <InputLabel
                          shrink
                          className={classes.labelAsyncSelect}
                        >
                          {language.expenditure.form.reportedBy} *
                        </InputLabel>

                        <AsyncSelect
                          id="worker"
                          margin="normal"
                          name="worker"
                          placeholder={
                            language.expenditure.form.reportedBy
                          }
                          value={values.worker}
                          loadOptions={e => loadOptions(e)}
                          defaultOptions
                          onInputChange={e => handleInputChange(e)}
                          onChange={o => {
                            setFieldValue('worker', o);
                          }}
                        />
                        {errors.worker && touched.worker && (
                          <ErrorText text={errors.worker} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <FormControl margin="none" fullWidth>
                          <InputLabel id="item">
                            {language.expenditure.form.item}
                          </InputLabel>
                          <Select
                            margin="none"
                            fullWidth
                            required
                            labelId="item"
                            id="item"
                            name="item"
                            value={values.item}
                            onChange={e => {
                              setFieldValue('item', e.target.value);
                            }}
                          >
                            <MenuItem value="Default" disabled>
                              --- Pilih ---
                            </MenuItem>
                            {expenditures.map((z, i) => (
                              <MenuItem key={i} value={z.value}>
                                <Typography variant="body2">
                                  {z.label}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {errors.item && touched.item && (
                          <ErrorText text={errors.item} />
                        )}
                      </Grid>
                      {values.item === 'Lainnya' && (
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            margin="none"
                            fullWidth
                            required
                            id="otherItem"
                            label={
                              language.expenditure.form.otherItem
                            }
                            type="text"
                            name="otherItem"
                            autoComplete="otherItem"
                            onChange={handleChange}
                            value={values.otherItem}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {errors.otherItem && touched.otherItem && (
                            <ErrorText text={errors.otherItem} />
                          )}
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <FormControl margin="none" fullWidth>
                          <InputLabel id="itemType">
                            {language.expenditure.form.itemType}
                          </InputLabel>
                          <Select
                            margin="none"
                            fullWidth
                            required
                            labelId="itemType"
                            id="itemType"
                            name="itemType"
                            value={values.itemType}
                            onChange={e => {
                              setFieldValue(
                                'itemType',
                                e.target.value,
                              );
                            }}
                          >
                            <MenuItem value="Default" disabled>
                              --- Pilih ---
                            </MenuItem>
                            {expenditureTypes.map((z, i) => (
                              <MenuItem key={i} value={z.value}>
                                <Typography variant="body2">
                                  {z.label}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {errors.itemType && touched.itemType && (
                          <ErrorText text={errors.itemType} />
                        )}
                      </Grid>
                      {values.itemType === 'Lainnya' && (
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            margin="none"
                            fullWidth
                            required
                            id="otherItemType"
                            label={
                              language.expenditure.form.otherItemType
                            }
                            type="text"
                            name="otherItemType"
                            autoComplete="otherItemType"
                            onChange={handleChange}
                            value={values.otherItemType}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {errors.otherItemType &&
                            touched.otherItemType && (
                              <ErrorText
                                text={errors.otherItemType}
                              />
                            )}
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="quantity"
                          name="quantity"
                          label={language.expenditure.form.quantity}
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={e => {
                            setFieldValue('quantity', e.target.value);
                          }}
                          value={values.quantity}
                        />
                        {errors.quantity && touched.quantity && (
                          <ErrorText text={errors.quantity} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="unitPrice"
                          name="unitPrice"
                          label={language.expenditure.form.unitPrice}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          onChange={e => {
                            setFieldValue(
                              'unitPrice',
                              e.target.value,
                            );
                          }}
                          value={values.unitPrice}
                        />
                        {errors.unitPrice && touched.unitPrice && (
                          <ErrorText text={errors.unitPrice} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                          disabled
                          margin="normal"
                          fullWidth
                          id="total"
                          name="total"
                          label={language.expenditure.form.total}
                          value={rupia(
                            values.quantity * values.unitPrice,
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="baseline"
                    >
                      <Button
                        className={classes.saveButton}
                        onClick={handleSubmit}
                        variant="contained"
                      >
                        {language.action.save}
                      </Button>
                    </Grid>
                  </Paper>
                </form>
              )}
            </Formik>
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </div>
  );
};

export default OperationalForm;
