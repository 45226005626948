import gql from 'graphql-tag';

export const TOTAL_CUSTOMER = gql`
  query totalCustomer($role_id: ID!) {
    totalCustomer(role_id: $role_id) {
      count
    }
  }
`;

export const AVERAGE_MONITORING = gql`
  query averageMonitoring($month: String!, $year: String!) {
    averageMonitoring(month: $month, year: $year) {
      count
    }
  }
`;

export const MONTHLY_PAYMENT = gql`
  query totalBilling($month: String!, $year: String!) {
    totalBilling(month: $month, year: $year) {
      count
    }
  }
`;

export const BARCHART_RECYCLABLE = gql`
  query barChartRecyclable($year: String!) {
    barChartRecyclable(year: $year)
  }
`;

export const DETAIL_BARCHART_RECYCLABLE = gql`
  query detailBarChartRecyclable($year: String!, $month: String!) {
    detailBarChartRecyclable(year: $year, month: $month)
  }
`;

export const BARCHART_SALES_OTHER = gql`
  query barChartSalesOther($year: String!) {
    barChartSalesOther(year: $year)
  }
`;

export const DETAIL_BARCHART_SALES_OTHER = gql`
  query detailChartSalesOther($year: String!, $month: String!) {
    detailChartSalesOther(year: $year, month: $month)
  }
`;

export const BARCHART_EXPENDITURE = gql`
  query barChartExpenditure($year: String!) {
    barChartExpenditure(year: $year)
  }
`;

export const DETAIL_BARCHART_EXPENDITURE = gql`
  query detailChartExpenditure($year: String!, $month: String!) {
    detailChartExpenditure(year: $year, month: $month)
  }
`;

export const BARCHART_SALES_COMPOST = gql`
  query barChartSalesCompost($year: String!) {
    barChartSalesCompost(year: $year)
  }
`;

export const DETAIL_BARCHART_SALES_COMPOST = gql`
  query detailChartSalesCompost($year: String!, $month: String!) {
    detailChartSalesCompost(year: $year, month: $month)
  }
`;

export const BARCHART_BILLING = gql`
  query barChartBilling($year: String!) {
    barChartBilling(year: $year)
  }
`;

export const DETAIL_BARCHART_BILLING = gql`
  query detailChartBilling($year: String!, $month: String!) {
    detailChartBilling(year: $year, month: $month)
  }
`;