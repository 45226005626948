import React from 'react';
import domtoimage from 'dom-to-image';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Chip,
  Table,
  TableCell,
  TableBody,
  TableRow,
} from '@material-ui/core';
import randomstring from 'randomstring';
import { ADD_IMAGE_TO_BILLING } from '../../graphql/BillingQuery';
import SubmitCancel from '../Custom/SubmitCancelBtn';
import NumberFormat from 'react-number-format';

const PaymentInfoDialog = props => {
  const {
    open,
    onClose,
    data,
    language,
    classes,
    mutate,
    setGlobalLoading,
    setSnack,
  } = props;
  let refDetail = React.createRef();

  const shareToWA = (url, customer, periode) =>
    navigator.share({
      title: language.payment.form.title,
      text: `Hai ${customer}, berikut adalah bukti pembayaran bulanan Anda untuk ${periode}. Silakan periksa bukti pembayaran melalui gambar berikut ini https://tpsgql.geeq.tech/public${url}`,
      url: window.location.href,
    });

  const prepareShare = () => {
    try {
      if (!data.detail.image) {
        setGlobalLoading(true);
        const copRef = { ...refDetail };
        domtoimage
          .toBlob(copRef.current, { bgcolor: 'white', quality: 0.9 })
          .then(async blo => {
            const filename = `${randomstring.generate({
              length: 4,
              charshet: 'alphanumeric',
            })}.png`;
            let copBlob = blo;
            copBlob.name = filename;
            const {
              data: {
                addImageToBilling: { image },
              },
            } = await mutate({
              mutation: ADD_IMAGE_TO_BILLING,
              variables: {
                id: data.detail.id,
                image: copBlob,
                file_name: filename,
              },
              fetchPolicy: 'no-cache',
            });
            setGlobalLoading(false);
            shareToWA(image, data.customer, data.periode);
          })
          .catch(err =>
            console.log('failed getting image from dom: ', err),
          );
      } else
        shareToWA(data.detail.image, data.customer, data.periode);
    } catch (error) {
      console.log('error getting image: ', error);
      setSnack({
        variant: 'error',
        message: 'Gagal mendapatkan gambar!',
        opened: true,
      });
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div ref={refDetail}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ maxWidth: 500 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <div style={{ fontWeight: 'bold' }}>
                  {data.user ? data.user.name : null}
                </div>
                <div style={{ fontSize: 13 }}>{data.payment_at}</div>
              </Grid>
              {/* <Grid item xs={4} id="detailchip">
                <Chip
                  color="primary"
                  label="Detail Pembayaran"
                  // style={{ float: 'right' }}
                />
              </Grid> */}
            </Grid>
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description"> */}
            <Table
              // className={classes.table}
              aria-label="simple table"
            >
              <TableBody>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Periode
                  </TableCell>
                  <TableCell>
                    {data.periode &&
                      data.periode.replace(
                        language.payment.period,
                        '',
                      )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Total Pembayaran
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={data.payment_total}
                      thousandSeparator
                      displayType="text"
                      prefix="Rp. "
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Dibayar oleh
                  </TableCell>
                  <TableCell>
                    {data.customer && data.customer}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Dibayar kepada
                  </TableCell>
                  <TableCell>{data.worker && data.worker}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Catatan
                  </TableCell>
                  <TableCell>{data.notes}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {/* </DialogContentText> */}
          </DialogContent>
        </div>
        <DialogActions>
          {/* <Button onClick={onClose} color="primary">
            Kembali
          </Button> */}
          <SubmitCancel
            submitClass={classes.saveButton}
            submitClick={prepareShare}
            submitTxt={language.action.share}
            cancelTxt={language.action.back}
            cancelClick={onClose}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaymentInfoDialog;
