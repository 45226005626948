import React, { useContext, useEffect } from 'react';
import {
  Grid,
  TextField,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';

import ErrorText from '../ErrorText';
import TpsMapBox from '../Custom/TpsMapBox';
import SubmitCancel from '../Custom/SubmitCancelBtn';
import GlobalContext from '../../globalContext';
import { CREATE_TPS } from '../../graphql/TpsQuery';
import { globalStyles } from '../../styles';
import { phoneRegExp } from '../../util';

const TpsForm = props => {
  const classes = globalStyles();
  const { history, mutate, language } = props;
  const {
    setSnack,
    setGlobalLoading,
    setTitle,
    setIsDetailPage,
  } = useContext(GlobalContext);

  const weightUnits = [
    { value: 'Kilogram', label: 'Kilogram' },
    { value: 'Ton', label: 'Ton' },
  ];

  useEffect(() => {
    setTitle(language.tps.title.add);
  }, [setTitle, language.tps.title.add]);

  useEffect(() => {
    setIsDetailPage(true);
  }, [setIsDetailPage]);

  return (
    <div>
      <Grid container spacing={3}>
        <Formik
          enableReinitialize
          initialValues={{
            tpsName: '',
            capacity: '',
            capacityUnit: '',
            latitude: 0,
            longitude: 0,
            tpsProvinsi: '',
            tpsKabupaten: '',
            tpsKecamatan: '',
            tpsKelurahan: '',
            tpsDesaAdat: '',
            tpsBanjar: '',
            tpsDusun: '',
            tpsJalan: '',
            userName: '',
            userPhone: '',
            userJabatan: '',
            userEmail: '',
            userProvinsi: '',
            userKabupaten: '',
            userKecamatan: '',
            userKelurahan: '',
            userDesaAdat: '',
            userBanjar: '',
            userDusun: '',
            userJalan: '',
          }}
          validationSchema={Yup.object().shape({
            tpsName: Yup.string().required(
              language.validate.required,
            ),
            capacity: Yup.number()
              .integer()
              .required(language.validate.required)
              .typeError(language.validate.capacity),
            capacityUnit: Yup.string().required(
              language.validate.required,
            ),
            userName: Yup.string().required(
              language.validate.required,
            ),
            userPhone: Yup.string()
              .required(language.validate.required)
              .matches(phoneRegExp, language.validate.phone),
            latitude: Yup.number()
              .required(language.validate.required)
              .min(-90, language.validate.latitudeRange)
              .max(90, language.validate.latitudeRange)
              .typeError(language.validate.latitudeRange),
            longitude: Yup.number()
              .required(language.validate.required)
              .min(-180, language.validate.longitudeRange)
              .max(180, language.validate.longitudeRange)
              .typeError(language.validate.longitudeRange),
          })}
          onSubmit={async (
            {
              tpsName,
              capacity,
              capacityUnit,
              tpsProvinsi,
              tpsKabupaten,
              tpsKecamatan,
              tpsKelurahan,
              tpsDesaAdat,
              tpsBanjar,
              tpsDusun,
              tpsJalan,
              userName,
              userPhone,
              userJabatan,
              userEmail,
              userProvinsi,
              userKabupaten,
              userKecamatan,
              userKelurahan,
              userDesaAdat,
              userBanjar,
              userDusun,
              userJalan,
              latitude,
              longitude,
            },
            { resetForm },
          ) => {
            try {
              const data = {
                user: {
                  name: userName,
                  email: userEmail,
                  jalan: userJalan,
                  dusun: userDusun,
                  banjar: userBanjar,
                  desa_adat: userDesaAdat,
                  kelurahan: userKelurahan,
                  kecamatan: userKecamatan,
                  kabupaten: userKabupaten,
                  provinsi: userProvinsi,
                  phone: userPhone,
                  jabatan: userJabatan,
                },
                name: tpsName,
                jalan: tpsJalan,
                dusun: tpsDusun,
                banjar: tpsBanjar,
                desa_adat: tpsDesaAdat,
                kelurahan: tpsKelurahan,
                kecamatan: tpsKecamatan,
                kabupaten: tpsKabupaten,
                provinsi: tpsProvinsi,
                location: {
                  latitude: parseFloat(latitude),
                  longitude: parseFloat(longitude),
                },
                capacity: parseFloat(capacity),
                capacity_unit: capacityUnit,
              };

              await mutate({
                mutation: CREATE_TPS,
                variables: { data },
                fetchPolicy: 'no-cache',
                onError: error => {
                  console.log('adakah error? ', error);
                },
              });
              setGlobalLoading(true);
              setSnack({
                variant: 'success',
                message: `${tpsName} ${language.alert.successSave}`,
                opened: true,
              });

              resetForm();
              history.replace('/tps/list');
            } catch (error) {
              console.log('Error create TPS', error);
              setSnack({
                variant: 'error',
                message: `${language.alert.errorSave} TPS!`,
                opened: true,
              });
            }
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            values,
            handleChange,
            setFieldValue,
          }) => (
            <>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <form noValidate>
                  <Paper className={classes.root}>
                    <h2>{language.tps.form.tps.title}</h2>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          autoFocus
                          margin="normal"
                          required
                          fullWidth
                          id="tpsName"
                          label={language.tps.form.tps.name}
                          name="tpsName"
                          autoComplete="tpsName"
                          onChange={handleChange}
                          value={values.tpsName}
                        />
                        {errors.tpsName && touched.tpsName && (
                          <ErrorText text={errors.tpsName} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="capacity"
                          label={language.tps.form.tps.capacity}
                          name="capacity"
                          autoComplete="capacity"
                          type="number"
                          onChange={handleChange}
                          value={values.capacity}
                        />
                        {errors.capacity && touched.capacity && (
                          <ErrorText text={errors.capacity} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <FormControl margin="normal" fullWidth>
                          <InputLabel id="capacityUnit">
                            Satuan
                          </InputLabel>
                          <Select
                            margin="normal"
                            fullWidth
                            required
                            labelId="capacityUnit"
                            id="capacityUnit"
                            name="capacityUnit"
                            value={values.capacityUnit}
                            onChange={handleChange}
                          >
                            {weightUnits.map((z, i) => (
                              <MenuItem key={i} value={z.value}>
                                <Typography variant="body2">
                                  {z.label}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {errors.capacityUnit &&
                          touched.capacityUnit && (
                            <ErrorText text={errors.capacityUnit} />
                          )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="latitude"
                          label={language.tps.form.tps.latitude}
                          name="latitude"
                          autoComplete="latitude"
                          onChange={handleChange}
                          value={values.latitude}
                        />
                        {errors.latitude && touched.latitude && (
                          <ErrorText text={errors.latitude} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="longitude"
                          label={language.tps.form.tps.longitude}
                          name="longitude"
                          autoComplete="longitude"
                          onChange={handleChange}
                          value={values.longitude}
                        />
                        {errors.longitude && touched.longitude && (
                          <ErrorText text={errors.longitude} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="tpsProvinsi"
                          label={
                            language.tps.form.tps.address.provinsi
                          }
                          name="tpsProvinsi"
                          onChange={handleChange}
                          value={values.tpsProvinsi}
                        />
                        {errors.tpsProvinsi &&
                          touched.tpsProvinsi && (
                            <ErrorText text={errors.tpsProvinsi} />
                          )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="tpsKabupaten"
                          label={
                            language.tps.form.tps.address.kabupaten
                          }
                          name="tpsKabupaten"
                          onChange={handleChange}
                          value={values.tpsKabupaten}
                        />
                        {errors.tpsKabupaten &&
                          touched.tpsKabupaten && (
                            <ErrorText text={errors.tpsKabupaten} />
                          )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="tpsKecamatan"
                          label={
                            language.tps.form.tps.address.kecamatan
                          }
                          name="tpsKecamatan"
                          onChange={handleChange}
                          value={values.tpsKecamatan}
                        />
                        {errors.tpsKecamatan &&
                          touched.tpsKecamatan && (
                            <ErrorText text={errors.tpsKecamatan} />
                          )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="tpsKelurahan"
                          label={
                            language.tps.form.tps.address.kelurahan
                          }
                          name="tpsKelurahan"
                          onChange={handleChange}
                          value={values.tpsKelurahan}
                        />
                        {errors.tpsKelurahan &&
                          touched.tpsKelurahan && (
                            <ErrorText text={errors.tpsKelurahan} />
                          )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="tpsDesaAdat"
                          label={
                            language.tps.form.tps.address.desaAdat
                          }
                          name="tpsDesaAdat"
                          onChange={handleChange}
                          value={values.tpsDesaAdat}
                        />
                        {errors.tpsDesaAdat &&
                          touched.tpsDesaAdat && (
                            <ErrorText text={errors.tpsDesaAdat} />
                          )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="tpsBanjar"
                          label={language.tps.form.tps.address.banjar}
                          name="tpsBanjar"
                          onChange={handleChange}
                          value={values.tpsBanjar}
                        />
                        {errors.tpsBanjar && touched.tpsBanjar && (
                          <ErrorText text={errors.tpsBanjar} />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="tpsDusun"
                          label={language.tps.form.tps.address.dusun}
                          name="tpsDusun"
                          onChange={handleChange}
                          value={values.tpsDusun}
                        />
                        {errors.tpsDusun && touched.tpsDusun && (
                          <ErrorText text={errors.tpsDusun} />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="tpsJalan"
                          label={language.tps.form.tps.address.jalan}
                          name="tpsJalan"
                          onChange={handleChange}
                          value={values.tpsJalan}
                        />
                        {errors.tpsJalan && touched.tpsJalan && (
                          <ErrorText text={errors.tpsJalan} />
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                  <br />
                  <Paper className={classes.root}>
                    <h2>{language.tps.form.user.title}</h2>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="userName"
                          label={language.tps.form.user.name}
                          name="userName"
                          onChange={handleChange}
                          value={values.userName}
                        />
                        {errors.userName && touched.userName && (
                          <ErrorText text={errors.userName} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="userEmail"
                          label={language.tps.form.user.email}
                          name="userEmail"
                          onChange={handleChange}
                          value={values.userEmail}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="userPhone"
                          label={language.tps.form.user.telephone}
                          name="userPhone"
                          onChange={handleChange}
                          value={values.userPhone}
                        />
                        {errors.userPhone && touched.userPhone && (
                          <ErrorText text={errors.userPhone} />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="userJabatan"
                          label={language.tps.form.user.jabatan}
                          name="userJabatan"
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="userProvinsi"
                          label={
                            language.tps.form.tps.address.provinsi
                          }
                          name="userProvinsi"
                          onChange={handleChange}
                          value={values.userProvinsi}
                        />
                        {errors.userProvinsi &&
                          touched.userProvinsi && (
                            <ErrorText text={errors.userProvinsi} />
                          )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="userKabupaten"
                          label={
                            language.tps.form.tps.address.kabupaten
                          }
                          name="userKabupaten"
                          onChange={handleChange}
                          value={values.userKabupaten}
                        />
                        {errors.userKabupaten &&
                          touched.userKabupaten && (
                            <ErrorText text={errors.userKabupaten} />
                          )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="userKecamatan"
                          label={
                            language.tps.form.tps.address.kecamatan
                          }
                          name="userKecamatan"
                          onChange={handleChange}
                          value={values.userKecamatan}
                        />
                        {errors.userKecamatan &&
                          touched.userKecamatan && (
                            <ErrorText text={errors.userKecamatan} />
                          )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="userKelurahan"
                          label={
                            language.tps.form.tps.address.kelurahan
                          }
                          name="userKelurahan"
                          onChange={handleChange}
                          value={values.userKelurahan}
                        />
                        {errors.userKelurahan &&
                          touched.userKelurahan && (
                            <ErrorText text={errors.userKelurahan} />
                          )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="userDesaAdat"
                          label={
                            language.tps.form.tps.address.desaAdat
                          }
                          name="userDesaAdat"
                          onChange={handleChange}
                          value={values.userDesaAdat}
                        />
                        {errors.userDesaAdat &&
                          touched.userDesaAdat && (
                            <ErrorText text={errors.userDesaAdat} />
                          )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="userBanjar"
                          label={language.tps.form.tps.address.banjar}
                          name="userBanjar"
                          onChange={handleChange}
                          value={values.userBanjar}
                        />
                        {errors.userBanjar && touched.userBanjar && (
                          <ErrorText text={errors.userBanjar} />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="userDusun"
                          label={language.tps.form.tps.address.dusun}
                          name="userDusun"
                          onChange={handleChange}
                          value={values.userDusun}
                        />
                        {errors.userDusun && touched.userDusun && (
                          <ErrorText text={errors.userDusun} />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="userJalan"
                          label={language.tps.form.tps.address.jalan}
                          name="userJalan"
                          onChange={handleChange}
                          value={values.userJalan}
                        />
                        {errors.userJalan && touched.userJalan && (
                          <ErrorText text={errors.userJalan} />
                        )}
                      </Grid>
                    </Grid>

                    <SubmitCancel
                      submitClass={classes.saveButton}
                      submitClick={handleSubmit}
                      submitTxt={language.action.save}
                      cancelTxt={language.action.back}
                      isCancelLink
                      cancelTo="/tps/list"
                    />
                  </Paper>
                </form>
              </Grid>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <TpsMapBox
                  location={{
                    longitude: values.longitude,
                    latitude: values.latitude,
                  }}
                  setLongLat={({ latitude, longitude }) => {
                    setFieldValue('latitude', latitude);
                    setFieldValue('longitude', longitude);
                  }}
                  editable
                />
              </Grid>
            </>
          )}
        </Formik>
      </Grid>
    </div>
  );
};

export default TpsForm;
