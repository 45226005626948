import React, { useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import faker from 'faker';
import * as Yup from 'yup';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Formik } from 'formik';
import { forgotPassword } from '../gql';
import GlobalContext from '../globalContext';
import { loginStyles } from '../styles';
import { setError } from '../util';
import ErrorText from './ErrorText';

const ForgotPassword = props => {
  const { mutate, history } = props;
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const classes = loginStyles();
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>

        <Formik
          initialValues={{
            email: faker.internet.email(),
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required('Required')
              .email('Invalid email'),
          })}
          onSubmit={async ({ email }, { resetForm }) => {
            try {
              setGlobalLoading(true);
              await mutate({
                mutation: forgotPassword,
                variables: { email },
                fetchPolicy: 'no-cache',
                onError: error => {
                  console.log('adakah error? ', error);
                },
              });
              resetForm();
              history.replace('/login');
              setGlobalLoading(false);
              setSnack({
                variant: 'success',
                message:
                  'Your password change request has been sent to your email!',
                opened: true,
              });
            } catch (error) {
              console.log('error forgot password: ', error);
              setGlobalLoading(false);
              setError(setSnack, error);
            }
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            values,
            handleChange,
          }) => (
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
                onKeyPress={e => {
                  if (e.nativeEvent.keyCode === 13) handleSubmit();
                }}
                value={values.email}
              />
              {errors.email && touched.email && (
                <ErrorText text={errors.email} />
              )}
              <Button
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Request a password change
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    variant="body2"
                    component="button"
                    onClick={() => history.replace('/login')}
                  >
                    Sign in instead?
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
export default ForgotPassword;
