import React, { useState } from 'react';
import { ApolloClient } from 'apollo-client';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { createUploadLink as CreateUploadLink } from 'apollo-upload-client';
import { ApolloProvider, ApolloConsumer } from 'react-apollo';
import Cookies from 'universal-cookie';
import {
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

import cache from './cache';
import { GlobalProvider } from './globalContext';
import MyRouter from './components/MyRouter';
import enLocaleData from './locale/en.json';
import idLocaleData from './locale/id.json';

const cookies = new Cookies();

const httpLink = new CreateUploadLink({
  uri:
    process.env[`REACT_APP_GRAPHQL_URL_${process.env.REACT_APP_ENV}`],
});

const authLink = setContext((_, { headers }) => {
  const token = cookies.get('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const linkError = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) console.log('graphQLErrors: ', graphQLErrors);
  if (networkError) console.log('networkError: ', networkError);
});

const authAndError = authLink.concat(linkError);
// const uploadLink = authAndError.concat(createUploadLink);
const client = new ApolloClient({
  link: authAndError.concat(httpLink),
  // link: uploadLink.concat(httpLink),
  cache,
});

const lang = 'id';

const checkLang = localStorage.getItem('locale');

if (!checkLang) {
  localStorage.setItem('locale', lang);
}

const App = () => {
  const [snack, setSnack] = useState({
    variant: 'success',
    message: null,
    opened: false,
  });

  const [globalLoading, setGlobalLoading] = useState(false);
  const [title, setTitle] = useState('Home');
  const [language, setLanguage] = useState(
    checkLang === 'id'
      ? idLocaleData
      : checkLang === 'en'
      ? enLocaleData
      : idLocaleData,
  );
  const [isDetailPage, setIsDetailPage] = useState(false);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#008000',
      },
      secondary: {
        main: '#11cb5f',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <GlobalProvider
        value={{
          setSnack,
          setGlobalLoading,
          setTitle,
          setLanguage,
          setIsDetailPage,
        }}
      >
        <ApolloProvider client={client}>
          <ApolloConsumer>
            {client => (
              <MyRouter
                client={client}
                snack={snack}
                globalLoading={globalLoading}
                title={title}
                language={language}
                isDetailPage={isDetailPage}
              />
            )}
          </ApolloConsumer>
        </ApolloProvider>
      </GlobalProvider>
    </ThemeProvider>
  );
};

export default App;
