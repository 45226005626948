/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useContext, useState } from 'react';
import {
  Grid,
  Tabs,
  Tab,
  Typography,
  Box,
  TextField,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';

import GlobalContext from '../../globalContext';
import ErrorText from '../ErrorText';
import { GET_USER, UPDATE_CUSTOMER } from '../../graphql/UserQuery';
import { userDetailStyle } from '../../styles';
import UserSummary from './UserSummary';
import TpsMapBox from '../Custom/TpsMapBox';
import SubmitCancel from '../Custom/SubmitCancelBtn';
import UserMonitoring from './UserMonitoring';
import UserPayment from './UserPayment';
import { phoneRegExp } from '../../util';

const UserDetail = props => {
  const { match, query, language, editable, mutate, history } = props;
  const { uuid } = match.params;
  const readOnly = !editable;
  const classes = userDetailStyle();
  const { setSnack, setGlobalLoading, setTitle } = useContext(
    GlobalContext,
  );
  const [user, setUser] = useState({});
  const [value, setValue] = React.useState(0);
  const categories = [
    'Rumah',
    'Villa',
    'Kos',
    'Banjar',
    'Usaha',
    'Hotel',
  ];
  const sizes = ['S', 'M', 'L', 'XL'];
  const fees = [50000, 100000, 150000];
  const lanes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setTitle(language.customer.title.detail);
  }, [setTitle, language.customer.title.detail]);

  useEffect(() => {
    setGlobalLoading(true);
    const getUser = async () => {
      const {
        data: { findUserByUuid },
      } = await query({
        query: GET_USER,
        variables: {
          uuid,
        },
        fetchPolicy: 'no-cache',
      });

      setGlobalLoading(false);
      setUser(findUserByUuid);
    };

    getUser();
  }, [query, uuid, setSnack, setGlobalLoading]);

  const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  };

  const DetailPage = () => {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={classes.baseTitle}>
              {language.customer.title.base}
            </div>
            <div className={classes.titleCustomer}>{user.name}</div>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: '25px' }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
              className={classes.tab}
            >
              <Tab label={language.customer.detail.summary.title} />
              <Tab
                label={language.customer.detail.monitoring.title}
              />
              <Tab label={language.customer.detail.payment.title} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <UserSummary
                data={user}
                classes={classes}
                language={language}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <UserMonitoring
                data={user}
                classes={classes}
                language={language}
                query={query}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <UserPayment
                data={user}
                classes={classes}
                language={language}
                query={query}
              />
            </TabPanel>
          </Grid>
        </Grid>
      </div>
    );
  };

  const EditPage = () => {
    return (
      <div>
        <Grid container spacing={2}>
          <Formik
            enableReinitialize
            initialValues={{
              ...user,
              latitude: user.location ? user.location.latitude : 0,
              longitude: user.location ? user.location.longitude : 0,
              serviceFee: user.service_fee,
              otherCategory: !categories.includes(user.category)
                ? user.category
                : '',
              otherSize: !sizes.includes(user.size) ? user.size : '',
              otherServiceFee: !fees.includes(user.service_fee)
                ? user.service_fee
                : '',
              otherLane: !lanes.includes(user.lane) ? user.lane : '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required(language.validate.required),
              phone: Yup.string()
                .required(language.validate.required)
                .matches(phoneRegExp, language.validate.phone),
              category: Yup.string().required(
                language.validate.required,
              ),
              otherCategory: Yup.string().when('category', {
                is: cat => cat === 'Lainnya',
                then: Yup.string().required(
                  language.validate.required,
                ),
              }),
              size: Yup.string().required(language.validate.required),
              otherSize: Yup.string().when('size', {
                is: size => size === 'Lainnya',
                then: Yup.string().required(
                  language.validate.required,
                ),
              }),
              serviceFee: Yup.string().required(
                language.validate.required,
              ),
              otherServiceFee: Yup.string().when('serviceFee', {
                is: fee => fee === 'Lainnya',
                then: Yup.string().required(
                  language.validate.required,
                ),
              }),
              lane: Yup.string().required(language.validate.required),
              otherLane: Yup.string().when('lane', {
                is: lane => lane === 'Lainnya',
                then: Yup.string().required(
                  language.validate.required,
                ),
              }),
              latitude: Yup.number()
                .required(language.validate.required)
                .min(-90, language.validate.latitudeRange)
                .max(90, language.validate.latitudeRange)
                .typeError(language.validate.latitudeRange),
              longitude: Yup.number()
                .required(language.validate.required)
                .min(-180, language.validate.longitudeRange)
                .max(180, language.validate.longitudeRange)
                .typeError(language.validate.longitudeRange),
            })}
            onSubmit={async (user, { resetForm }) => {
              try {
                const data = {
                  name: user.name,
                  email: user.email,
                  phone: user.phone,
                  category:
                    user.category === 'Lainnya'
                      ? user.otherCategory
                      : user.category,
                  size:
                    user.size === 'Lainnya'
                      ? user.otherSize
                      : user.size,
                  lane:
                    user.lane === 'Lainnya'
                      ? parseInt(user.otherLane, 10)
                      : parseInt(user.lane, 10),
                  service_fee:
                    user.serviceFee === 'Lainnya'
                      ? parseInt(user.otherServiceFee, 10)
                      : parseInt(user.serviceFee, 10),
                  provinsi: user.provinsi,
                  kabupaten: user.kabupaten,
                  kecamatan: user.kecamatan,
                  kelurahan: user.kelurahan,
                  desa_adat: user.desa_adat,
                  banjar: user.banjar,
                  jalan: user.jalan,
                  notes: user.notes,
                  location: {
                    latitude: parseFloat(user.latitude),
                    longitude: parseFloat(user.longitude),
                  },
                  registration_date: user.registration_date,
                };

                await mutate({
                  mutation: UPDATE_CUSTOMER,
                  variables: { id: user.id, data },
                  fetchPolicy: 'no-cache',
                  onError: error => {
                    console.log('adakah error? ', error);
                  },
                });
                setGlobalLoading(true);
                setSnack({
                  variant: 'success',
                  message: `${user.name} berhasil diubah!`,
                  opened: true,
                });

                resetForm();
                history.replace('/user/list');
              } catch (error) {
                console.log('error', error);
              }
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              values,
              handleChange,
              setFieldValue,
            }) => (
              <>
                <Grid item xs={12} sm={12} md={12} lg={7}>
                  <Paper className={classes.root}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                          autoFocus
                          margin="normal"
                          required
                          fullWidth
                          id="name"
                          label={
                            language.customer.detail.summary.name
                          }
                          name="name"
                          onChange={handleChange}
                          value={values.name ? values.name : ''}
                        />
                        {errors.name && touched.name && (
                          <ErrorText text={errors.name} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="email"
                          label={
                            language.customer.detail.summary.email
                          }
                          name="email"
                          onChange={handleChange}
                          value={values.email ? values.email : ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="phone"
                          label={
                            language.customer.detail.summary.phone
                          }
                          name="phone"
                          onChange={handleChange}
                          value={values.phone ? values.phone : ''}
                        />
                        {errors.phone && touched.phone && (
                          <ErrorText text={errors.phone} />
                        )}
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} lg={3}>
                        <FormControl margin="normal" fullWidth>
                          <InputLabel id="category">
                            {language.customer.form.category.base} *
                          </InputLabel>
                          <Select
                            margin="dense"
                            fullWidth
                            required
                            labelId="category"
                            id="category"
                            name="category"
                            value={
                              categories.includes(values.category)
                                ? values.category
                                : 'Lainnya'
                            }
                            onChange={e => {
                              setFieldValue(
                                'category',
                                e.target.value,
                              );
                            }}
                          >
                            <MenuItem value="Rumah">Rumah</MenuItem>
                            <MenuItem value="Villa">Villa</MenuItem>
                            <MenuItem value="Kos">Kos</MenuItem>
                            <MenuItem value="Banjar">Banjar</MenuItem>
                            <MenuItem value="Usaha">Usaha</MenuItem>
                            <MenuItem value="Hotel">Hotel</MenuItem>
                            <MenuItem value="Lainnya">
                              Lainnya
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {errors.category && touched.category && (
                          <ErrorText text={errors.category} />
                        )}
                      </Grid>
                      {!categories.includes(values.category) ? (
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="otherCategory"
                            label={
                              language.customer.form.category.other
                            }
                            name="otherCategory"
                            onChange={handleChange}
                            value={values.otherCategory}
                          />
                          {errors.otherCategory &&
                            touched.otherCategory && (
                              <ErrorText
                                text={errors.otherCategory}
                              />
                            )}
                        </Grid>
                      ) : null}
                      <Grid item xs={6} sm={6} md={3} lg={3}>
                        <FormControl margin="normal" fullWidth>
                          <InputLabel id="size">
                            {language.customer.form.size.base} *
                          </InputLabel>
                          <Select
                            margin="dense"
                            fullWidth
                            required
                            labelId="size"
                            id="size"
                            name="size"
                            value={
                              sizes.includes(values.size)
                                ? values.size
                                : 'Lainnya'
                            }
                            onChange={e => {
                              setFieldValue('size', e.target.value);
                            }}
                          >
                            <MenuItem value="S">S</MenuItem>
                            <MenuItem value="M">M</MenuItem>
                            <MenuItem value="L">L</MenuItem>
                            <MenuItem value="XL">XL</MenuItem>
                            <MenuItem value="Lainnya">
                              Lainnya
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {errors.size && touched.size && (
                          <ErrorText text={errors.size} />
                        )}
                      </Grid>
                      {!sizes.includes(values.size) ? (
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="otherSize"
                            label={language.customer.form.size.other}
                            name="otherSize"
                            onChange={handleChange}
                            value={values.otherSize}
                          />
                          {errors.otherSize && touched.otherSize && (
                            <ErrorText text={errors.otherSize} />
                          )}
                        </Grid>
                      ) : null}
                      <Grid item xs={6} sm={6} md={3} lg={3}>
                        <FormControl margin="normal" fullWidth>
                          <InputLabel id="serviceFee">
                            {language.customer.form.fee.base} *
                          </InputLabel>
                          <Select
                            margin="dense"
                            fullWidth
                            required
                            labelId="serviceFee"
                            id="serviceFee"
                            name="serviceFee"
                            value={
                              fees.includes(values.serviceFee)
                                ? values.serviceFee
                                : 'Lainnya'
                            }
                            onChange={e => {
                              setFieldValue(
                                'serviceFee',
                                e.target.value,
                              );
                            }}
                          >
                            <MenuItem value={50000}>
                              Rp. 50.000
                            </MenuItem>
                            <MenuItem value={100000}>
                              Rp. 100.000
                            </MenuItem>
                            <MenuItem value={150000}>
                              Rp. 150.000
                            </MenuItem>
                            <MenuItem value="Lainnya">
                              Lainnya
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {errors.serviceFee && touched.serviceFee && (
                          <ErrorText text={errors.serviceFee} />
                        )}
                      </Grid>
                      {!fees.includes(values.serviceFee) ? (
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="otherServiceFee"
                            label={language.customer.form.fee.other}
                            name="otherServiceFee"
                            onChange={handleChange}
                            value={values.otherServiceFee}
                          />
                          {errors.otherServiceFee &&
                            touched.otherServiceFee && (
                              <ErrorText
                                text={errors.otherServiceFee}
                              />
                            )}
                        </Grid>
                      ) : null}
                      <Grid item xs={6} sm={6} md={3} lg={3}>
                        <FormControl margin="normal" fullWidth>
                          <InputLabel id="lane">
                            {language.customer.form.lane.base} *
                          </InputLabel>
                          <Select
                            margin="dense"
                            fullWidth
                            required
                            labelId="lane"
                            id="lane"
                            name="lane"
                            value={
                              lanes.includes(values.lane)
                                ? values.lane
                                : 'Lainnya'
                            }
                            onChange={e => {
                              setFieldValue('lane', e.target.value);
                            }}
                          >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value="Lainnya">
                              Lainnya
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {errors.lane && touched.lane && (
                          <ErrorText text={errors.lane} />
                        )}
                      </Grid>
                      {!lanes.includes(values.lane) ? (
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="otherLane"
                            label={language.customer.form.lane.other}
                            name="otherLane"
                            onChange={handleChange}
                            value={values.otherLane}
                          />
                          {errors.otherLane && touched.otherLane && (
                            <ErrorText text={errors.otherLane} />
                          )}
                        </Grid>
                      ) : null}
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="provinsi"
                          label={
                            language.customer.detail.summary.address
                              .provinsi
                          }
                          name="provinsi"
                          onChange={handleChange}
                          value={
                            values.provinsi ? values.provinsi : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="kabupaten"
                          label={
                            language.customer.detail.summary.address
                              .kabupaten
                          }
                          name="kabupaten"
                          onChange={handleChange}
                          value={
                            values.kabupaten ? values.kabupaten : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="kecamatan"
                          label={
                            language.customer.detail.summary.address
                              .kecamatan
                          }
                          name="kecamatan"
                          onChange={handleChange}
                          value={
                            values.kecamatan ? values.kecamatan : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="kelurahan"
                          label={
                            language.customer.detail.summary.address
                              .kelurahan
                          }
                          name="kelurahan"
                          onChange={handleChange}
                          value={
                            values.kelurahan ? values.kelurahan : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="desa_adat"
                          label={
                            language.customer.detail.summary.address
                              .desaAdat
                          }
                          name="desa_adat"
                          onChange={handleChange}
                          value={
                            values.desa_adat ? values.desa_adat : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="banjar"
                          label={
                            language.customer.detail.summary.address
                              .banjar
                          }
                          name="banjar"
                          onChange={handleChange}
                          value={values.banjar ? values.banjar : ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="dusun"
                          label={
                            language.customer.detail.summary.address
                              .dusun
                          }
                          name="dusun"
                          onChange={handleChange}
                          value={values.dusun ? values.dusun : ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="jalan"
                          label={
                            language.customer.detail.summary.address
                              .jalan
                          }
                          name="jalan"
                          onChange={handleChange}
                          value={values.jalan ? values.jalan : ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          margin="normal"
                          fullWidth
                          multiline
                          rows={5}
                          id="notes"
                          label={
                            language.customer.detail.summary.notes
                          }
                          name="notes"
                          onChange={handleChange}
                          value={values.notes ? values.notes : ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="latitude"
                          label="Latitude"
                          name="latitude"
                          autoComplete="latitude"
                          value={values.latitude}
                        />
                        {errors.latitude && touched.latitude && (
                          <ErrorText text={errors.latitude} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="longitude"
                          label="longitude"
                          name="longitude"
                          autoComplete="longitude"
                          value={values.longitude}
                        />
                        {errors.longitude && touched.longitude && (
                          <ErrorText text={errors.longitude} />
                        )}
                      </Grid>
                      <SubmitCancel
                        submitClass={classes.saveButton}
                        submitClick={handleSubmit}
                        submitTxt={language.action.update}
                        cancelTxt={language.action.back}
                        isCancelLink
                        cancelTo="/user/list"
                      />
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  <TpsMapBox
                    location={{
                      latitude: values.latitude,
                      longitude: values.longitude,
                    }}
                    setLongLat={({ latitude, longitude }) => {
                      setFieldValue('latitude', latitude);
                      setFieldValue('longitude', longitude);
                    }}
                    editable
                  />
                </Grid>
              </>
            )}
          </Formik>
        </Grid>
      </div>
    );
  };

  return <div>{readOnly ? <DetailPage /> : <EditPage />}</div>;
};

export default UserDetail;
