/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import moment from 'moment-timezone';

import {
  GET_BILLINGS_USER,
  GET_TOTAL_BILLINGS_USER,
} from '../../graphql/BillingQuery';
import TpsDialog from '../Custom/TpsDialog';

const UserPayment = props => {
  const { data, classes, language, query } = props;
  const [payment, setPayment] = useState([]);
  const [total, setTotal] = useState(0);
  const [paymentDetail, setPaymentDetail] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getUserBilling = async () => {
      const {
        data: { findBillingByUser },
      } = await query({
        query: GET_BILLINGS_USER,
        variables: {
          user_id: data.id,
        },
        fetchPolicy: 'no-cache',
      });

      setPayment(findBillingByUser);
    };

    const getTotal = async () => {
      const {
        data: { totalBillingUser },
      } = await query({
        query: GET_TOTAL_BILLINGS_USER,
        variables: {
          user_id: data.id,
        },
        fetchPolicy: 'no-cache',
      });

      setTotal(totalBillingUser.count);
    };

    getUserBilling();
    getTotal();
  }, [data.id, query]);

  const handleOpenDialog = value => {
    setPaymentDetail(value);
    setOpen(true);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          Search Box
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="caption" gutterBottom>
            {total} {language.data.found}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        {payment.length
          ? payment.map(x => (
              <Grid item key={x.id} xs={12} sm={12} md={12} lg={12}>
                <Paper
                  className={classes.root}
                  square
                  elevation={0}
                  style={{ borderBottom: '1px solid #e8e8e8' }}
                >
                  <Grid container spacing={0}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      container
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        container
                        direction="column"
                        spacing={2}
                      >
                        <Grid item xs>
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                          >
                            {x.worker ? x.worker.name : null}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            {language.customer.detail.payment.worker}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={1}
                        lg={1}
                        container
                        direction="column"
                        spacing={2}
                      >
                        <Grid item xs>
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                          >
                            {x.periode}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            {language.customer.detail.payment.periode}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        container
                        direction="column"
                        spacing={2}
                      >
                        <Grid item xs>
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                          >
                            <NumberFormat
                              value={x.payment_total}
                              thousandSeparator
                              displayType="text"
                              prefix="Rp. "
                            />
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            {
                              language.customer.detail.payment
                                .paymentTotal
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                      {/* <Grid
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        container
                        direction="column"
                        spacing={2}
                      >
                        <Grid item xs>
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                          >
                            {x.notes}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            {language.customer.detail.payment.notes}
                          </Typography>
                        </Grid>
                      </Grid> */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        container
                        direction="column"
                        spacing={2}
                      >
                        <Grid item xs>
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                          >
                            {moment(x.payment_at).format(
                              'dddd, DD-MM-YYYY',
                            )}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            {
                              language.customer.detail.payment
                                .paymentAt
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        container
                        direction="column"
                        spacing={2}
                      >
                        <Grid item xs>
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                          >
                            {moment(x.created_at).format(
                              'dddd, DD-MM-YYYY',
                            )}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            {
                              language.customer.detail.payment
                                .createdAt
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={1} lg={1}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpenDialog(x)}
                        >
                          {language.action.detail}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))
          : null}
      </Grid>
      <TpsDialog
        data={paymentDetail}
        open={open}
        language={language}
        onClose={() => {
          setOpen(false);
        }}
        type="customerPayment"
      />
    </div>
  );
};

export default UserPayment;
