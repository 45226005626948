import React, { useEffect, useContext, useState } from 'react';
import {
  Button,
  Grid,
  Paper,
  TextField,
  Fab,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import { isMobile } from '../../util';
import GlobalContext from '../../globalContext';
import { GET_SALES_OTHERS } from '../../graphql/ProcessingQuery';
import OtherIncomeList from './OtherIncomeList';
import { globalStyles } from '../../styles';

const OtherIncome = props => {
  const classes = globalStyles();
  const { query, language, history } = props;
  const [data, setData] = useState([]);
  const [fixedData, setFixedData] = useState([]);
  const { setSnack, setGlobalLoading, setTitle } = useContext(
    GlobalContext,
  );

  useEffect(() => {
    setTitle(language.processing.titleOther);
    setGlobalLoading(true);

    const getSales = async () => {
      setData([]);
      setFixedData([]);
      const {
        data: { salesOthers },
      } = await query({
        query: GET_SALES_OTHERS,
        fetchPolicy: 'no-cache',
      });

      if (salesOthers) {
        // console.log('getting salesOthers', salesOthers);
        let dataSales = salesOthers.map(z => ({
          ...z,
          periode: moment(z.payment_at).format('LL'),
        }));
        dataSales = _.orderBy(dataSales, ['payment_at'], ['desc']);
        setData(dataSales);
        setFixedData(dataSales);
        setGlobalLoading(false);
      } else {
        setGlobalLoading(false);
        setSnack({
          variant: 'error',
          message: 'Gagal mengambil data penjualan!',
          opened: true,
        });
      }
    };

    getSales();
  }, [
    setTitle,
    language.processing.titleOther,
    query,
    setGlobalLoading,
    setSnack,
  ]);

  const handleClickOpen = value => {
    setGlobalLoading(true);
    // console.log('apaan nih? ', value);
    history.replace(`/income/other/${value.uuid}`);
  };

  const handleSearch = e => {
    const finds = [...fixedData].filter(
      x =>
        x.notes
          .toLowerCase()
          .includes(e.currentTarget.value.toLowerCase()) ||
        x.source_fund
          .toLowerCase()
          .includes(e.currentTarget.value.toLowerCase()) ||
        x.worker.name
          .toLowerCase()
          .includes(e.currentTarget.value.toLowerCase()) ||
        x.periode
          .toLowerCase()
          .includes(e.currentTarget.value.toLowerCase()),
    );
    setData(finds);
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="baseline"
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="baseline"
        >
          <TextField
            id="outlined-basic"
            label={language.action.search}
            variant="outlined"
            size="small"
            fullWidth
            onChange={e => handleSearch(e)}
            // onKeyPress={e => e.nativeEvent.keyCode === 13 handleSearch}
          />
        </Grid>
        {!isMobile() && (
          <Grid
            container
            direction="column"
            justify="flex-end"
            alignItems="baseline"
          >
            <Button
              className={classes.addButton}
              component={Link}
              to="/otherform"
              variant="contained"
              color="primary"
            >
              {language.action.add}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ marginTop: 20 }}>
          <OtherIncomeList data={data} onClick={handleClickOpen} />
        </Paper>
      </Grid>
      {isMobile() && (
        <Fab
          color="primary"
          aria-label="add"
          component={Link}
          to="/otherform"
          variant="round"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
          }}
        >
          <AddIcon />
        </Fab>
      )}
    </div>
  );
};

export default OtherIncome;
