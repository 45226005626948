import React, { useEffect, useContext, useState } from 'react';
import {
  Button,
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import Cookies from 'universal-cookie/cjs';
import moment from 'moment';
import randomstring from 'randomstring';
import { rupia } from '../../util';
import * as Yup from 'yup';
import { Formik, FieldArray } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import AsyncSelect from 'react-select/async';

import ErrorText from '../ErrorText';
import SubmitCancel from '../Custom/SubmitCancelBtn';
import GlobalContext from '../../globalContext';
import {
  CREATE_SALES_RECYCABLE,
  GET_CATALOGUES,
  GET_SALES_RECYCABLE,
} from '../../graphql/ProcessingQuery';
import { SEARCH_USERS } from '../../graphql/UserQuery';
import { globalStyles } from '../../styles';

const RecycableForm = props => {
  const classes = globalStyles();
  const { language, history, mutate, query } = props;
  const { setSnack, setGlobalLoading, setTitle } = useContext(
    GlobalContext,
  );

  const weightUnits = [
    { value: 'Ton', label: 'Ton' },
    { value: 'Kwintal', label: 'Kwintal' },
    { value: 'Kilogram', label: 'Kilogram' },
    { value: 'Gram', label: 'Gram' },
  ];

  const [user, setUser] = useState({
    value: '',
    label: '',
  });

  const [fixedCatalogues, setFixedCatalogues] = useState([]);

  const getUsers = async ({ name }) => {
    const {
      data: { searchUsers },
    } = await query({
      query: SEARCH_USERS,
      variables: { name, position: 'CUSTOMER' },
      fetchPolicy: 'no-cache',
    });

    const options = searchUsers.map(l => ({
      value: l.id,
      label: l.name,
    }));

    return options;
  };

  const loadOptions = inputValue =>
    getUsers({
      name: inputValue,
    });

  useEffect(() => {
    setTitle(language.processing.addProcessing);
    // setGlobalLoading(true);
    setGlobalLoading(false);
    const getCatalogues = async () => {
      const {
        data: { catalogues },
      } = await query({
        query: GET_CATALOGUES,
        fetchPolicy: 'no-cache',
      });
      setFixedCatalogues(catalogues);
    };
    getCatalogues();
  }, [
    language.processing.addProcessing,
    setGlobalLoading,
    setTitle,
    query,
  ]);

  return (
    <Grid
      container
      direction="row"
      justify="flex-end"
      alignItems="baseline"
    >
      {fixedCatalogues.length > 0 && (
        <Grid item xs={12} sm={12} md={12} lg={7}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Formik
              initialValues={{
                payment_at: moment().format('YYYY MM DD'),
                type: 'RECYCLABLE',
                buyer: user ? user.label : '',
                sales_details: [
                  {
                    id: randomstring.generate(4),
                    catalogue_id: fixedCatalogues[0].id,
                    weight: 0,
                    weight_unit: 'Kilogram',
                    price: 0,
                  },
                ],
              }}
              validationSchema={Yup.object().shape({
                buyer: Yup.string().required(
                  language.validate.required,
                ),
                payment_at: Yup.date().required(
                  language.validate.required,
                ),
                sales_details: Yup.array().of(
                  Yup.object().shape({
                    catalogue_id: Yup.number().required(
                      language.validate.required,
                    ),
                    weight: Yup.number()
                      .min(1, language.processing.form.minError1)
                      .required(language.validate.required),
                    weight_unit: Yup.string().required(
                      language.validate.required,
                    ),
                    price: Yup.number()
                      .min(100, language.processing.form.minError100)
                      .required(language.validate.required),
                  }),
                ),
              })}
              onSubmit={async (
                { payment_at, type, sales_details },
                { resetForm },
              ) => {
                try {
                  const cookie = new Cookies();
                  setGlobalLoading(true);
                  const data = {
                    user_id: user.value,
                    worker_id: cookie.get('id'),
                    // sell_to: user.label,
                    type,
                    total: sales_details
                      .map(z => parseFloat(z.weight * z.price))
                      .reduce((a, b) => a + b, 0),
                    payment_at: moment(payment_at).format(
                      'YYYY-MM-DD',
                    ),
                    sales_details: sales_details.map(z => ({
                      catalogue_id: z.catalogue_id,
                      weight: parseFloat(z.weight),
                      weight_unit: z.weight_unit,
                      price: parseInt(z.price),
                      sub_total: parseFloat(z.weight * z.price),
                    })),
                  };

                  await mutate({
                    mutation: CREATE_SALES_RECYCABLE,
                    variables: { data },
                    fetchPolicy: 'no-cache',
                    onError: error => {
                      console.log(
                        '==> Error execute mutation',
                        error,
                      );
                    },
                    update: (proxy, { data: { createSale } }) => {
                      const olddata = proxy.readQuery({
                        query: GET_SALES_RECYCABLE,
                        variables: { type: 'RECYCLABLE' },
                      });
                      proxy.writeQuery({
                        query: GET_SALES_RECYCABLE,
                        variables: { type: 'RECYCLABLE' },
                        data: {
                          ...olddata,
                          sales: [...olddata.sales, createSale],
                        },
                      });
                    },
                  });
                  setSnack({
                    variant: 'success',
                    message: `Penjualan lapak berhasil ditambahkan!`,
                    opened: true,
                  });
                  resetForm();
                  history.replace('/income/recyclable');
                } catch (error) {
                  console.log('Error create User', error);
                  setSnack({
                    variant: 'error',
                    message: 'Gagal menambahkan penjualan lapak!',
                    opened: true,
                  });
                  setGlobalLoading(false);
                }
              }}
            >
              {({
                errors,
                touched,
                handleSubmit,
                values,
                // handleChange,
                setFieldValue,
              }) => (
                <form noValidate>
                  <Paper className={classes.root}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <KeyboardDatePicker
                          margin="dense"
                          // id="date-picker-dialog"
                          label={language.processing.form.date}
                          format="MM/dd/yyyy"
                          value={values.payment_at}
                          onChange={e => {
                            const dateval = moment(e).format(
                              'YYYY MM DD',
                            );
                            setFieldValue(
                              'payment_at',
                              dateval,
                              false,
                            );
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputLabel
                          shrink
                          className={classes.labelAsyncSelect}
                        >
                          {language.income.customer.name} *
                        </InputLabel>

                        <AsyncSelect
                          id="name"
                          margin="dense"
                          name="name"
                          placeholder={language.income.customer.name}
                          value={user}
                          loadOptions={e => loadOptions(e)}
                          defaultOptions
                          onInputChange={e => {
                            // debugger;
                            // handleInputChange(e, 'customer')
                          }}
                          onChange={o => {
                            setUser(o);
                            setFieldValue('buyer', o.label);
                          }}
                        />
                        {errors.buyer && touched.buyer && (
                          <ErrorText text={errors.buyer} />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          autoFocus
                          margin="dense"
                          required
                          fullWidth
                          id="total"
                          label={language.processing.form.total}
                          name="total"
                          disabled
                          value={rupia(
                            values.sales_details
                              .map(sd => sd.weight * sd.price)
                              .reduce((a, b) => a + b, 0),
                          )}
                        />
                      </Grid>
                      <Typography
                        variant="h6"
                        style={{ padding: '10px 0 0 8px' }}
                      >
                        {language.processing.form.sales_details}
                      </Typography>
                      <FieldArray
                        name="sales_details"
                        render={({ ...arrayHelpers }) => (
                          <Grid
                            container
                            style={{
                              paddingLeft: 8,
                              paddingRight: 8,
                            }}
                          >
                            {values.sales_details &&
                              values.sales_details.map(
                                (sales_detail, idx) => (
                                  <Grid
                                    container
                                    key={sales_detail.id}
                                    spacing={1}
                                    style={{
                                      // borderBottom:
                                      //   '1px solid #ababab',
                                      marginBottom: 10,
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      style={{
                                        padding: '16px 0 0 8px',
                                      }}
                                    >
                                      Item {idx + 1}
                                    </Typography>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                    >
                                      {/* <FormControl
                                        margin="dense"
                                        fullWidth
                                      > */}
                                      <InputLabel id="catalogue_id">
                                        {
                                          language.processing.form
                                            .stall
                                        }
                                      </InputLabel>
                                      <Select
                                        margin="dense"
                                        fullWidth
                                        required
                                        labelId="catalogue_id"
                                        id="catalogue_id"
                                        name="catalogue_id"
                                        value={
                                          sales_detail.catalogue_id
                                        }
                                        onChange={e => {
                                          setFieldValue(
                                            `sales_details[${idx}].catalogue_id`,
                                            e.target.value,
                                            false,
                                          );
                                        }}
                                      >
                                        <MenuItem
                                          value="Default"
                                          disabled
                                        >
                                          --- Pilih ---
                                        </MenuItem>
                                        {fixedCatalogues.map(z => (
                                          <MenuItem
                                            key={`${idx}asd${z.id}`}
                                            value={z.id}
                                          >
                                            <Typography variant="body2">
                                              {z.name}
                                            </Typography>
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      {errors.sales_details &&
                                        errors.sales_details[idx] &&
                                        errors.sales_details[idx]
                                          .catalogue_id &&
                                        touched.sales_details &&
                                        touched.sales_details[idx] &&
                                        touched.sales_details[idx]
                                          .catalogue_id && (
                                          <ErrorText
                                            text={
                                              errors.sales_details[
                                                idx
                                              ].catalogue_id
                                            }
                                          />
                                        )}
                                      {/* </FormControl> */}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sm={6}
                                      md={3}
                                      lg={3}
                                    >
                                      <TextField
                                        autoFocus
                                        margin="dense"
                                        required
                                        fullWidth
                                        id="weight"
                                        label={
                                          language.processing.form
                                            .weight
                                        }
                                        name="weight"
                                        autoComplete="weight"
                                        onChange={e =>
                                          setFieldValue(
                                            `sales_details[${idx}].weight`,
                                            e.target.value,
                                            false,
                                          )
                                        }
                                        value={sales_detail.weight}
                                      />
                                      {errors.sales_details &&
                                        errors.sales_details[idx] &&
                                        errors.sales_details[idx]
                                          .weight &&
                                        touched.sales_details &&
                                        touched.sales_details[idx] &&
                                        touched.sales_details[idx]
                                          .weight && (
                                          <ErrorText
                                            text={
                                              errors.sales_details[
                                                idx
                                              ].weight
                                            }
                                          />
                                        )}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sm={6}
                                      md={3}
                                      lg={3}
                                    >
                                      <FormControl
                                        margin="dense"
                                        fullWidth
                                      >
                                        <InputLabel id="weight_unit">
                                          {
                                            language.processing.form
                                              .unit
                                          }
                                        </InputLabel>
                                        <Select
                                          margin="dense"
                                          fullWidth
                                          required
                                          labelId="weight_unit"
                                          id="weight_unit"
                                          name="weight_unit"
                                          value={
                                            sales_detail.weight_unit
                                          }
                                          onChange={e => {
                                            setFieldValue(
                                              `sales_details[${idx}].weight_unit`,
                                              e.target.value,
                                              false,
                                            );
                                          }}
                                        >
                                          <MenuItem
                                            value="Default"
                                            disabled
                                          >
                                            --- Pilih ---
                                          </MenuItem>
                                          {weightUnits.map(z => (
                                            <MenuItem
                                              key={randomstring.generate(
                                                3,
                                              )}
                                              value={z.value}
                                            >
                                              <Typography variant="body2">
                                                {z.label}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        {errors.sales_details &&
                                          errors.sales_details[idx] &&
                                          errors.sales_details[idx]
                                            .weight_unit &&
                                          touched.sales_details &&
                                          touched.sales_details[
                                            idx
                                          ] &&
                                          touched.sales_details[idx]
                                            .weight_unit && (
                                            <ErrorText
                                              text={
                                                errors.sales_details[
                                                  idx
                                                ].weight_unit
                                              }
                                            />
                                          )}
                                      </FormControl>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                    >
                                      <TextField
                                        autoFocus
                                        margin="dense"
                                        required
                                        fullWidth
                                        id="price"
                                        label={
                                          language.processing.form
                                            .price
                                        }
                                        name="price"
                                        autoComplete="price"
                                        onChange={e =>
                                          setFieldValue(
                                            `sales_details[${idx}].price`,
                                            e.target.value,
                                            false,
                                          )
                                        }
                                        value={sales_detail.price}
                                      />
                                      {errors.sales_details &&
                                        errors.sales_details[idx] &&
                                        errors.sales_details[idx]
                                          .price &&
                                        touched.sales_details &&
                                        touched.sales_details[idx] &&
                                        touched.sales_details[idx]
                                          .price && (
                                          <ErrorText
                                            text={
                                              errors.sales_details[
                                                idx
                                              ].price
                                            }
                                          />
                                        )}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={idx !== 0 ? 8 : 12}
                                      sm={idx !== 0 ? 8 : 12}
                                      md={6}
                                      lg={6}
                                    >
                                      <TextField
                                        autoFocus
                                        margin="dense"
                                        required
                                        fullWidth
                                        id="sub_total"
                                        label={
                                          language.processing.form
                                            .sub_total
                                        }
                                        name="sub_total"
                                        disabled
                                        value={rupia(
                                          sales_detail.weight *
                                            sales_detail.price,
                                        )}
                                      />
                                    </Grid>
                                    {idx !== 0 && (
                                      <Grid
                                        item
                                        xs={4}
                                        sm={4}
                                        md={6}
                                        lg={6}
                                        style={{
                                          alignItems: 'center',
                                          alignContent: 'center',
                                          justifyItems: 'center',
                                        }}
                                      >
                                        <Button
                                          variant="outlined"
                                          color="default"
                                          onClick={() =>
                                            arrayHelpers.remove(idx)
                                          }
                                          style={{ marginTop: 10 }}
                                        >
                                          {language.action.delete}
                                        </Button>
                                      </Grid>
                                    )}
                                  </Grid>
                                ),
                              )}
                            <Button
                              className={classes.saveButton}
                              onClick={() =>
                                arrayHelpers.push({
                                  id: randomstring.generate(4),
                                  catalogue_id: fixedCatalogues[0].id,
                                  weight: 0,
                                  weight_unit: 'Kilogram',
                                  price: 0,
                                })
                              }
                              style={{
                                marginTop: 20,
                              }}
                              variant="contained"
                            >
                              {language.action.add}
                            </Button>
                          </Grid>
                        )}
                      />

                      <SubmitCancel
                        submitClass={classes.saveButton}
                        submitClick={handleSubmit}
                        submitTxt={language.action.save}
                        cancelTxt={language.action.back}
                        isCancelLink
                        cancelTo="/income/recyclable"
                      />
                    </Grid>
                  </Paper>
                </form>
              )}
            </Formik>
          </MuiPickersUtilsProvider>
        </Grid>
      )}
    </Grid>
  );
};

export default RecycableForm;
