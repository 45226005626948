import { useState } from 'react';
import imageCompression from 'browser-image-compression';
import _ from 'lodash';
// import Jimp from 'jimp';
const characters = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];
const phoneRegExp = /^([+][0-9]{1,3})*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]{1,16}$/;
const color = (char, classes) => {
  const idx = (characters.indexOf(char.toLowerCase()) + 1) % 3;
  switch (idx) {
    case 1:
      return classes.greenClass;
    case 2:
      return classes.redClass;
    default:
      return classes.blueClass;
  }
};
export const setError = (setSnack, error) => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0)
    setSnack({
      variant: 'error',
      message: error.graphQLErrors[0].message,
      opened: true,
    });
  if (error.networkError && error.networkError.length > 0)
    setSnack({
      variant: 'error',
      message: error.networkError[0].message,
      opened: true,
    });
  // else
  //   setSnack({
  //     variant: 'error',
  //     message: error,
  //     opened: true,
  //   });
};

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = value => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
};

const isMobile = () => {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  );
};

const prepUploads = async files =>
  files.map(
    item =>
      new Promise(async (resolve, reject) => {
        try {
          const options = {
            // maxSizeMB: 2,
            useWebWorker: true,
          };
          // const fileComp = await imageCompression(
          //   pictureFiles[0],
          //   options,
          // );
          let buffr;
          const { size } = item;
          if (size > 2048000) {
            options.maxSizeMB = 2;
          } else {
            options.maxSizeMB = (size / 2048000).toFixed(0);
          }
          buffr = await imageCompression(item, options);
          // const meta = await sharp(item).metadata();
          // let buffer;
          // if (meta.size > 2048000) {
          //   const resizedwidth = (2048000 / meta.size) * meta.width;
          //   buffer = await sharp(item)
          //     .resize(resizedwidth)
          //     .webp()
          //     .toBuffer();
          // } else {
          //   buffer = await sharp(item)
          //     .webp()
          //     .toBuffer();
          // }
          // return buffer;
          // resolve(buffer)
          resolve(buffr);
        } catch (e) {
          console.log('error on resizing image: ', e);
          reject(e);
        }
      }),
  );

const getPaginatedItems = (items, page, pageSize) => {
  const pg = page || 1;
  const pgSize = pageSize || 100;
  const offset = (pg - 1) * pgSize;
  const pagedItems = _.drop(items, offset).slice(0, pgSize);
  return pagedItems;
  // return {
  //   page: pg,
  //   pageSize: pgSize,
  //   total: items.length,
  //   total_pages: Math.ceil(items.length / pgSize),
  //   data: pagedItems
  // };
};

const rupia = angka => {
  const number_string = angka
    .toString()
    .replace(/[^,\d]/g, '')
    .toString();
  const split = number_string.split(',');
  const sisa = split[0].length % 3;
  let rupiah = split[0].substr(0, sisa);
  const ribuan = split[0].substr(sisa).match(/\d{3}/gi);
  if (ribuan) {
    const separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] !== undefined ? `${rupiah},${split[1]}` : rupiah;
  return `Rp. ${rupiah}`;
};

export {
  color,
  useLocalStorage,
  isMobile,
  prepUploads,
  getPaginatedItems,
  phoneRegExp,
  rupia,
};
