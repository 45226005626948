import gql from 'graphql-tag';

export const auth = gql`
  query authenticate($email: String!, $password: String!) {
    authenticate(email: $email, password: $password) {
      id
      uuid
      role_id
      position
      name
      token
      tps_id
    }
  }
`;

export const createUser = gql`
  mutation createCustomer($data: InCustomer!) {
    createCustomer(data: $data) {
      id
      uuid
      user_id
      role_id
      tps_id
      position
      name
      address
      email
      password
      phone
      location
      photo
      category
      type
      service_fee
      status
      password_token
      registration_date
      created_at
      updated_at
    }
  }
`;
export const forgotPassword = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      id
    }
  }
`;

export const createTps = gql`
  mutation createTps($data: InTps!) {
    createTps(data: $data) {
      id
      uuid
    }
  }
`;
